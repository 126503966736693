import { gql } from 'shared/types/layoutcreator'

export const CREATE_NEW_PROJECT = gql(/* GraphQL */ `
  mutation createNewProject(
    $templatePath: String!
    $groupId: String!
    $projectName: String!
    $coverType: String!
  ) {
    createNewProject(
      templatePath: $templatePath
      groupId: $groupId
      projectName: $projectName
      coverType: $coverType
    )
  }
`)

export const COPY_COVER_TEMPLATES = gql(/* GraphQL */ `
  mutation copyCoverTemplateToCustomerProject(
    $projectName: String!
    $groupId: String!
  ) {
    copyCoverTemplateToCustomerProject(
      projectName: $projectName
      groupId: $groupId
    )
  }
`)

export const ADD_NEW_PAGE = gql(/* GraphQL */ `
  mutation addNewPage(
    $groupId: String!
    $projectId: String!
    $type: String!
  ) {
    addNewPage(groupId: $groupId, projectId: $projectId, type: $type)
  }
`)

export const REMOVE_PAGE = gql(/* GraphQL */ `
  mutation removePage($projectId: String!, $itemId: String!) {
    removePage(projectId: $projectId, itemId: $itemId)
  }
`)

export const SAVE_DOCUMENT = gql(/* GraphQL */ `
  mutation saveDocument($documentId: String!, $xml: String!) {
    saveDocument(documentId: $documentId, xml: $xml)
  }
`)

export const CHANGE_PROJECT_CURRENT_TEMPLATE = gql(/* GraphQL */ `
  mutation changeProjectCurrentTemplate(
    $groupId: String!
    $projectId: String!
    $templatePath: String!
  ) {
    changeProjectCurrentTemplate(
      groupId: $groupId
      projectId: $projectId
      templatePath: $templatePath
    )
  }
`)

export const FIX_BROKEN_PAGES = gql(/* GraphQL */ `
  mutation fixBrokenPages($groupId: String!, $projectId: String!) {
    fixBrokenPages(groupId: $groupId, projectId: $projectId) {
      isOk
      fixedCount
    }
  }
`)

export const SET_PAGES_ORDER = gql(/* GraphQL */ `
  mutation setPagesOrder(
    $groupId: String!
    $projectId: String!
    $pages: [PageOrderInput!]!
  ) {
    setPagesOrder(
      groupId: $groupId
      projectId: $projectId
      pages: $pages
    )
  }
`)

export const CHANGE_PROJECT_NAME = gql(/* GraphQL */ `
  mutation changeProjectName($projectId: String!, $newName: String!) {
    changeProjectName(projectId: $projectId, newName: $newName)
  }
`)

export const CHANGE_PROJECT_META = gql(/* GraphQL */ `
  mutation changeProjectMeta(
    $groupId: String!
    $projectId: String!
    $input: ProjectMetaInput!
    $adjustChili: Boolean
  ) {
    changeProjectMeta(
      groupId: $groupId
      projectId: $projectId
      input: $input
      adjustChili: $adjustChili
    )
  }
`)

export const LINK_ORDER = gql(/* GraphQL */ `
  mutation linkOrder(
    $groupId: String!
    $projectId: String!
    $orderNumber: String!
    $year: Int!
    $customerNumber: String!
  ) {
    linkOrder(
      groupId: $groupId
      projectId: $projectId
      orderNumber: $orderNumber
      year: $year
      customerNumber: $customerNumber
    ) {
      successful
      errorType
    }
  }
`)

export const UNLINK_ORDER = gql(/* GraphQL */ `
  mutation unlinkOrder(
    $groupId: String!
    $projectId: String!
    $orderNumber: String!
    $year: Int!
    $customerNumber: String!
  ) {
    unlinkOrder(
      groupId: $groupId
      projectId: $projectId
      orderNumber: $orderNumber
      year: $year
      customerNumber: $customerNumber
    )
  }
`)

export const DELETE_PROJECT = gql(/* GraphQL */ `
  mutation deleteProject(
    $groupId: String!
    $projectId: String!
    $userId: String!
  ) {
    deleteProject(
      groupId: $groupId
      projectId: $projectId
      userId: $userId
    )
  }
`)

export const MIGRATE_TO_NEW_PAGE_SYSTEM = gql(/* GraphQL */ `
  mutation migrateToNewPageSystem(
    $groupId: String!
    $projectId: String!
  ) {
    migrateToNewPageSystem(groupId: $groupId, projectId: $projectId)
  }
`)

export const CHECK_IS_PAGE_COUNT_SYSTEM_OUTDATED = gql(/* GraphQL */ `
  mutation checkIsPageCountSystemOutdated(
    $groupId: String!
    $projectId: String!
  ) {
    checkIsPageCountSystemOutdated(
      groupId: $groupId
      projectId: $projectId
    )
  }
`)
