import { theme } from 'gradoo-theme'
import { useIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import Flex from '../Flex'

const Container = styled(Flex).attrs({
  alignItems: 'center'
})`
  height: 34px;
  border-radius: 30px;
  background: ${({ theme }) => theme.color.base.c2};
`

const Option = styled(NavLink)<{ disabled?: boolean }>`
  padding: 8px;
  min-width: 110px;
  text-align: center;
  border-radius: 30px;
  font-weight: 600;
  font-size: 14px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  @media screen and (max-width: 1000px) {
    min-width: unset;
  }
  &.active {
    // derives by NavLink
    box-shadow: -3px 3px 4px 0 rgba(0, 0, 0, 0.15);
    color: white;
    background: ${theme.color.brand_02};
    cursor: default;
  }
`

type SelectorProps = {
  options: string[]
  disabled: string[]
}

/*
 * The Selector will work as tabs, provided the paths have the same root, e.g.
 * /projectId/cover & /projectId/content, hence the relative to= in the NavLink
 * Don't forget to add i18n keys to en-EN.json and de-DE.json
 * */
export default function Selector({
  options,
  disabled
}: SelectorProps) {
  const { formatMessage } = useIntl()
  return (
    <Container>
      {options.map(option => (
        <Option
          key={option}
          to={'../' + option}
          disabled={disabled.includes(option)}
          onClick={e =>
            disabled.includes(option) && e.preventDefault()
          }
        >
          {formatMessage({ id: `Selector.option.${option}` })}
        </Option>
      ))}
    </Container>
  )
}
