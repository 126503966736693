import { PublisherInterface } from '@chili-publish/publisher-interface'

const modifyTextFlow = (textFlow: string) => {
  const parser = new DOMParser()

  const xmlDoc = parser.parseFromString(textFlow, 'application/xml')
  const spans = xmlDoc.querySelectorAll('span')

  let wasModified = false

  spans.forEach(span => {
    if (!span.getAttribute('fontFamily')) {
      span.setAttribute('fontFamily', 'Inter_Regular')
      wasModified = true
    }
  })

  const serializer = new XMLSerializer()

  return {
    wasModified,
    newTextFlow: serializer.serializeToString(xmlDoc)
  }
}

export const useTextMigration = ({
  publisherConnector
}: {
  publisherConnector: PublisherInterface
}) => {
  const migrate = async () => {
    const logs: Record<string, { from: string; to: string }> = {}

    const frames = (await publisherConnector.getObject(
      'document.pages[0].frames'
    )) as any[]

    const framesCount = Number(frames.length)

    let counter = 0
    for (let i = 0; i < framesCount; i++) {
      const frame = (await publisherConnector.getObject(
        `document.pages[0].frames[${i}]`
      )) as any
      const composerSelector = `document.pages[0].frames[${frame.id}].textFlow.composer`

      if (frame.type === 'text') {
        const { textFlow } = (await publisherConnector.getObject(
          composerSelector
        )) as { textFlow: string }

        const { newTextFlow, wasModified } = modifyTextFlow(textFlow)

        if (!wasModified) {
          continue
        }

        logs[frame.id] = {
          from: textFlow,
          to: newTextFlow
        }

        counter++

        await publisherConnector.executeFunction(
          frame.javaScriptDescriptor,
          'ImportTextFlow',
          newTextFlow
        )
        console.log('Text flow modified successfully')
      }
    }
    await publisherConnector?.executeFunction(
      'document.selectedFrames',
      'Clear'
    )

    console.log(`%c${counter} text frames fixed`, 'color: green')
    console.log(logs)
  }

  return {
    migrate
  }
}
