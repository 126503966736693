import { useMutation } from '@apollo/client'
import { useRef } from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import useAuth from 'data/gradoo/hooks/useAuth'
import {
  DELETE_CHILI_IMAGE,
  DELETE_IMAGE,
  REPLACE_CHILI_IMAGE
} from 'data/layoutcreator/mutations/images'
import ChiliImages from 'shared/components/Tools/services/ChiliImages'
import {
  ChangeFrameProps,
  ImageFrame
} from 'shared/components/Tools/types'
import { replaceSpaces } from 'shared/helpers/string'
import { FileTypes } from 'shared/types/global'
import Button from 'shared/ui/Button_2'
import FileUpload from 'shared/ui/FileUpload'
import Flex from 'shared/ui/Flex'

import FrameSize from '../FrameSize'
import ImageCrop from './components/ImageCrop'
import { ShapeSelector } from './components/ShapeSelector'

const ResetZoomButton = styled.div`
  margin-top: 16px;
`

type ImageControlsProps = {
  frame: ImageFrame
  changeFrameProps: ChangeFrameProps
}

const imagesApi = ChiliImages.getInstance()

const ImageControls: React.FC<ImageControlsProps> = ({
  frame,
  changeFrameProps
}) => {
  const { formatMessage } = useIntl()
  const replaceRef = useRef<HTMLInputElement>(null)
  const { pathname } = useLocation()
  const projectId = pathname.split('/')[1]
  const groupId = useAuth().authGroupId!

  const [replaceChiliImage] = useMutation(REPLACE_CHILI_IMAGE)
  const [deleteImage] = useMutation(DELETE_IMAGE)
  const [deleteChiliImage] = useMutation(DELETE_CHILI_IMAGE)

  const isChiliImage = () =>
    frame.path && !frame.path.includes('DynamicAsset')

  const handleZoomReset = () => {
    imagesApi.setIsFrameCropped(frame.id, false)
  }

  const handleReplace = async (file: File) => {
    const loadingToastId = toast.loading(
      formatMessage({ id: 'Toasts.components.uploading' })
    )

    const { data } = await replaceChiliImage({
      variables: {
        groupId,
        projectId: replaceSpaces(projectId),
        image: file,
        oldImageId: isChiliImage() ? frame.externalID : null
      }
    })

    imagesApi
      .replaceImage(data.replaceChiliImage)
      .then(() => {
        toast.remove(loadingToastId)
      })
      .catch(() => {
        toast.error(
          formatMessage({
            id: 'Toasts.components.replacing.error'
          })
        )
        toast.remove(loadingToastId)
      })
  }

  const handleDelete = async () => {
    const imageVariable = await imagesApi.getImageVariableId()

    if (imageVariable) {
      const { externalName: imageId } = frame
      await imagesApi.deleteFrame()
      await deleteImage({
        variables: {
          groupId,
          projectId,
          imageId
        }
      })
      return
    }

    const { path } = frame

    if (path && !path.includes('DynamicAsset')) {
      await deleteChiliImage({
        variables: {
          imageId: frame.externalID
        }
      })

      imagesApi.deleteFrame()
    }
  }

  return (
    <>
      <FrameSize
        frame={frame}
        frameApi={imagesApi}
        changeFrameProps={changeFrameProps}
      />

      <ImageCrop frame={frame} />
      <ResetZoomButton>
        <Button
          textId={formatMessage({ id: 'Panel.button.resetZoom' })}
          onPress={handleZoomReset}
          intent="secondary-base"
          minWidth={242}
          size="md"
        />
      </ResetZoomButton>
      <ShapeSelector />

      <Flex
        direction="column"
        style={{ marginTop: 'auto' }}
        rowGap={8}
      >
        <Button
          textId="Panel.button.replaceImage"
          onPress={() => replaceRef.current?.click()}
          intent="secondary-contrast"
          minWidth={242}
          size="md"
        />

        <Button
          textId="Panel.button.deleteImage"
          onPress={handleDelete}
          intent="destructive"
          minWidth={242}
          size="md"
        />
      </Flex>

      <FileUpload
        type={FileTypes.image}
        ref={replaceRef}
        onSelect={handleReplace}
      />
    </>
  )
}

export default ImageControls
