import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Button, { ButtonIntents } from 'shared/ui/Button'
import Flex from 'shared/ui/Flex'
import IslandModal from 'shared/components/modals/IslandModal'

const LOCALIZATION_BASE_KEY = 'Pages.modals.migration'

const Description = styled.span`
  color: ${({ theme }) => theme.color.base.c6};
`

const LoadingContainer = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  margin-top: 54px;
`

const LoadingBar = styled.div<{ percent: number }>`
  margin-top: 10px;
  width: 100%;
  height: 8px;
  background: ${({ theme }) => theme.color.base.c2};
  border-radius: 10px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: ${({ percent }) => percent}%;
    height: 100%;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.color.brand_02};
    transition: width 0.2s;
  }
`

const LoadingLabel = styled.span`
  color: ${({ theme }) => theme.color.base.c9};
  font-weight: 600;
`

type PageMigrationModalProps = {
  isOpen: boolean
  closeModal: () => void
  loadingPercent: number
  onStartUpdateClick: () => void
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
}

const PagesMigrationModal: React.FC<PageMigrationModalProps> = ({
  isOpen,
  onStartUpdateClick,
  loadingPercent,
  isLoading,
  isError,
  isSuccess,
  closeModal
}) => {
  const { formatMessage } = useIntl()

  const message = (key: string) =>
    formatMessage({ id: `${LOCALIZATION_BASE_KEY}.${key}` })

  const title = isSuccess
    ? message('title.success')
    : isError
    ? message('title.error')
    : message('title.initial')

  return (
    <IslandModal
      isOpen={isOpen}
      closeModal={closeModal}
      title={title}
      titleMargin={8}
      titleAlign="left"
      width={344}
    >
      <Flex
        direction="column"
        height={291}
        justifyContent="space-between"
      >
        <Description>
          {!isSuccess && !isError && message('description.initial')}
          {isSuccess && message('description.success')}
          {isError && message('description.error')}
        </Description>

        <LoadingContainer show={isLoading}>
          <LoadingLabel>
            {!isSuccess &&
              !isError &&
              !isLoading &&
              message('loadingBar.label.initial')}
            {isLoading && message('loadingBar.label.progress')}
          </LoadingLabel>
          <LoadingBar percent={loadingPercent} />
        </LoadingContainer>

        {!isSuccess && !isError && !isLoading && (
          <Button
            borderRadius={100}
            intent={ButtonIntents.dark}
            labelId="Editor.modals.migration.buttons.updatePage"
            onClick={onStartUpdateClick}
          />
        )}
        {isLoading && (
          <Button
            borderRadius={100}
            intent={ButtonIntents.dark}
            labelId="Editor.modals.migration.buttons.updating"
            isDisabled
          />
        )}
        {isSuccess && (
          <Button
            borderRadius={100}
            intent={ButtonIntents.dark}
            labelId="Editor.modals.migration.buttons.finish"
            onClick={closeModal}
          />
        )}
        {isError && (
          <>
            <Button
              borderRadius={100}
              intent={ButtonIntents.outlinedDark}
              labelId="Editor.modals.migration.buttons.retry"
              onClick={closeModal}
            />
            <Button
              borderRadius={100}
              intent={ButtonIntents.dark}
              labelId="Editor.modals.migration.buttons.support"
              onClick={closeModal}
            />
          </>
        )}
      </Flex>
    </IslandModal>
  )
}

export default PagesMigrationModal
