import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { ReactElement, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import useImageLoading from 'shared/hooks/useImageLoading'
import { LiveUser } from 'shared/types/global'
import { Maybe } from 'shared/types/gradoo/graphql'
import { ProjectDocument } from 'shared/types/layoutcreator/graphql'
import { Skeleton } from 'shared/ui/Skeleton'

import draggableSrc from '../../assets/draggable.svg'
import lockedSrc from '../../assets/lock.svg'
import { ViewTypes } from '../../enum'
import PageContainer, { PagePreview } from '../PageContainer'
import {
  MetaContainer,
  PageNumber,
  PageType
} from './components/PageMeta'
import PageTools from './components/PageTools'

const PageIcon = styled.img`
  margin-right: 28px;
`

interface PageProps extends ProjectDocument {
  testID?: string
  index: number
  view: string
  editingLiveUser?: LiveUser | null
  customPreviewPath: Maybe<string>
  groupId: string
  projectId: string
}

const Page = (props: PageProps): ReactElement => {
  const { formatMessage } = useIntl()
  const {
    testID,
    id,
    index,
    view,
    previewUrl,
    customPreviewPath,
    type,
    name,
    editingLiveUser
  } = props

  const isBlob = customPreviewPath?.startsWith('data:')
  const customPreviewUrl = customPreviewPath
    ? `${process.env.REACT_APP_REST_URL}${customPreviewPath}`
    : null

  const { isLoading } = useImageLoading(
    isBlob ? '' : customPreviewUrl || previewUrl
  )

  const [hasEditingLiveUser, setHasEditingLiveUser] =
    useState<boolean>(false)
  const [refreshCount, setRefreshCount] = useState<number>(0)

  // const isDisabled = index === 0
  const isDisabled = false

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id,
    disabled: isDisabled
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    zIndex: Number(isDragging),
    transition
  }

  const pageNumber = index + 3

  useEffect(() => {
    //the document is saved when user leaves the editor
    if (hasEditingLiveUser && !editingLiveUser) {
      setRefreshCount(refreshCount + 1)
    }

    setHasEditingLiveUser(!!editingLiveUser)
  }, [editingLiveUser])

  return (
    <PageContainer
      data-testid={testID}
      ref={setNodeRef}
      style={style}
      isDragging={isDragging}
      isDisabled={isDisabled}
      view={view}
      isEven={pageNumber % 2 !== 0}
      place="content"
      {...attributes}
      {...listeners}
    >
      {view === ViewTypes.grid && (
        <PagePreview
          place="content"
          previewUrl={
            isLoading || isBlob
              ? null
              : `${customPreviewUrl || previewUrl}&rc=${refreshCount}`
          }
        >
          {isLoading && <Skeleton />}
          {!isLoading && isBlob && (
            <img
              src={customPreviewPath || previewUrl}
              alt={`Page ${pageNumber}`}
            />
          )}
          {!isLoading && (
            <PageTools
              isViewLoading={isLoading}
              view={view}
              pageId={id}
              type={type}
              pageName={name}
              previewUrl={customPreviewPath || previewUrl}
              pageNumber={pageNumber}
              editingLiveUser={editingLiveUser}
              groupId={props.groupId}
              projectId={props.projectId}
            />
          )}
        </PagePreview>
      )}
      <MetaContainer>
        {view === ViewTypes.list && (
          <PageIcon src={isDisabled ? lockedSrc : draggableSrc} />
        )}
        <PageNumber view={view}>{pageNumber}</PageNumber>
        {type && (
          <PageType>
            {formatMessage({ id: `Page.type.${type}` })}
          </PageType>
        )}
      </MetaContainer>
      {view === ViewTypes.list && (
        <PageTools
          isViewLoading={isLoading}
          view={view}
          type={type}
          pageId={id}
          pageName={name}
          previewUrl={previewUrl}
          pageNumber={pageNumber}
          editingLiveUser={editingLiveUser}
          groupId={props.groupId}
          projectId={props.projectId}
        />
      )}
    </PageContainer>
  )
}

export default Page
