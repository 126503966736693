import Button from 'shared/ui/Button_2'
import Flex from 'shared/ui/Flex'
import { LiveUser } from 'shared/types/global'

import Selector from 'shared/ui/Selector'
import Logo from './components/Logo'
import LogoContainerLink from './components/LogoContainerLink'
import {
  HeaderContainer,
  IconsContainer,
  StyledHeader
} from './style'
import LiveAvatarsRow from 'shared/ui/LiveAvatarsRow'

export type HeaderProps = {
  liveUsers?: LiveUser[]
  RightComponent?: React.ReactNode
  onBack?: () => void
  tabs?: string[]
}

const Header = ({
  liveUsers,
  RightComponent,
  onBack,
  tabs
}: HeaderProps) => {
  return (
    <StyledHeader>
      <HeaderContainer>
        <Flex alignItems="center">
          {onBack && (
            <IconsContainer>
              <Button
                onPress={onBack}
                size="lg"
                intent="sub-action"
                textId={'Tools.add.page'}
                iconName="arrow_narrow_left"
                icon="only"
              />
            </IconsContainer>
          )}

          <LogoContainerLink to="/">
            <Logo />
          </LogoContainerLink>
        </Flex>
        {tabs ? (
          <Flex>
            <Selector options={tabs} disabled={[tabs[0]]} />
          </Flex>
        ) : null}
        <Flex>
          <Flex alignItems="center">
            <LiveAvatarsRow liveUsers={liveUsers || []} />
          </Flex>

          {!!RightComponent && (
            <Flex alignItems="center">{RightComponent}</Flex>
          )}
        </Flex>
      </HeaderContainer>
    </StyledHeader>
  )
}

export default Header
