import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import {
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'

import Button from 'shared/ui/Button_2'
import Flex from 'shared/ui/Flex'
import PageList from 'shared/components/PageList'
import Select from 'shared/ui/Select'
import {
  GET_PROJECT_DOCUMENTS,
} from 'data/layoutcreator/queries/documents'
import AdminLayout from 'shared/layout/Admin'
import LiveCursorWrapper from 'shared/components/PageList/components/LiveCursorWrapper'

import Header from './components/Header'
import PageMigrationsModal from './components/PageMigrationsModal'
import { useIntl } from 'react-intl'
import { useLiveUsers } from 'shared/hooks/useLiveUsers'
import withRoom from 'shared/hocs/withRoom'

const AdminProject: React.FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { groupId, projectId } = useParams()
  const { formatMessage } = useIntl() 

  useEffect(() => {
    const view = searchParams.get('view')
    if (!view) {
      navigate('?view=grid')
    }
  }, [])

  const [isPageMigrationsModalOpen, setIsPageMigrationsModalOpen] =
    useState(false)

  const [migrationSelectIndex, setMigrationSelectIndex] = useState(0)
  const MIGRATION_SELECT_ITEMS = [
    {
      name: formatMessage({ id: 'AdminProject.fields.migrations.pageMigration' }),
      action: () => {
        setIsPageMigrationsModalOpen(true)
      }
    }
  ]

  const { data } = useQuery(GET_PROJECT_DOCUMENTS, {
    variables: {
      groupId: groupId!,
      projectId: projectId!
    }
  })

  const liveUsers = useLiveUsers()

  const projectDataFields: {
    title: string
    value: string
    hide?: boolean
  }[] = [
    { title: 'ID', value: projectId! },
    { title: formatMessage({ id: 'AdminProject.fields.groupId' }), value: groupId! },
    {
      title: formatMessage({ id: 'AdminProject.fields.pagesCount' }),
      value: data?.projectDocuments.length.toString() || '',
      hide: !data?.projectDocuments
    }
  ]

  return (
    <AdminLayout
      showFullscreenButton={false}
      renderHeaderContent={() => <Header liveUsers={liveUsers} />}
    >
      <LiveCursorWrapper>
        <Flex
          direction="column"
          alignItems="flex-start"
          marginTop={30}
        >
          <Flex
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex direction="column" rowGap={3}>
              {projectDataFields.map((item, i) => (
                <span key={i}>
                  <strong>{item.title}: </strong>
                  <span>{item.value}</span>
                </span>
              ))}
            </Flex>

            <Flex columnGap={8}>
              <Select
                width={230}
                options={MIGRATION_SELECT_ITEMS.map(
                  item => item.name
                )}
                activeIndex={migrationSelectIndex}
                onChange={(_, index) =>
                  setMigrationSelectIndex(index)
                }
              />
              <Button
                textId="AdminProject.buttons.runFix"
                intent="secondary-contrast"
                size="md"
                maxHeight={34}
                onPress={() =>
                  MIGRATION_SELECT_ITEMS[
                    migrationSelectIndex
                  ].action()
                }
              />
            </Flex>
          </Flex>

          <Flex
            width="100%"
            justifyContent="center"
            marginTop={34}
            marginBottom={128}
          >
            <PageList
              projectId={projectId!}
              groupId={groupId!}
            />
          </Flex>
        </Flex>
      </LiveCursorWrapper>

      <PageMigrationsModal
        isOpen={isPageMigrationsModalOpen}
        closeModal={() => setIsPageMigrationsModalOpen(false)}
      />
    </AdminLayout>
  )
}

export default withRoom(AdminProject)
