/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as types from './graphql'

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  mutation adminLogin($login: String!, $password: String!) {\n    adminLogin(login: $login, password: $password)\n  }\n':
    types.AdminLoginDocument,
  '\n  mutation startImageMigration(\n    $groupId: String!\n    $projectId: String!\n    $author: String\n    $type: String\n  ) {\n    startImageMigration(\n      groupId: $groupId\n      projectId: $projectId\n      author: $author\n      type: $type\n    )\n  }\n':
    types.StartImageMigrationDocument,
  '\n  mutation startExportDocument(\n    $groupId: String!\n    $projectId: String!\n    $documentId: String!\n    $outputProfile: String\n    $isCoverPage: Boolean\n  ) {\n    startExportDocument(\n      groupId: $groupId\n      projectId: $projectId\n      documentId: $documentId\n      outputProfile: $outputProfile\n      isCoverPage: $isCoverPage\n    ) {\n      documentId\n      taskId\n      isStarted\n      isFinished\n      isSucceeded\n      isVariableSet\n      url\n    }\n  }\n':
    types.StartExportDocumentDocument,
  '\n  mutation fixVariables($groupId: String!, $projectId: String!) {\n    fixVariables(groupId: $groupId, projectId: $projectId)\n  }\n':
    types.FixVariablesDocument,
  '\n  mutation addNewAsset(\n    $id: String!\n    $path: String!\n    $name: String!\n    $file: Upload!\n  ) {\n    addNewAsset(id: $id, name: $name, path: $path, file: $file)\n  }\n':
    types.AddNewAssetDocument,
  '\n  mutation recoverDeletedProject(\n    $groupId: String!\n    $projectId: String!\n  ) {\n    recoverDeletedProject(groupId: $groupId, projectId: $projectId)\n  }\n':
    types.RecoverDeletedProjectDocument,
  '\n  mutation emptyExportQueue {\n    emptyExportQueue\n  }\n':
    types.EmptyExportQueueDocument,
  '\n  mutation duplicatePage(\n    $pageName: String!\n    $itemId: String!\n    $groupId: String!\n    $projectId: String!\n  ) {\n    duplicatePage(\n      pageName: $pageName\n      itemId: $itemId\n      groupId: $groupId\n      projectId: $projectId\n    )\n  }\n':
    types.DuplicatePageDocument,
  '\n  mutation savePagePreview(\n    $groupId: String!\n    $projectId: String!\n    $pageId: String!\n    $image: Upload!\n  ) {\n    savePagePreview(\n      groupId: $groupId\n      projectId: $projectId\n      pageId: $pageId\n      image: $image\n    )\n  }\n':
    types.SavePagePreviewDocument,
  '\n  mutation startExport(\n    $groupId: String!\n    $projectId: String!\n    $pageIds: [String!]\n    $outputProfile: String!\n    $userId: String!\n    $retryFailedPages: Float\n  ) {\n    startExport(\n      groupId: $groupId\n      projectId: $projectId\n      pageIds: $pageIds\n      outputProfile: $outputProfile\n      userId: $userId\n      retryFailedPages: $retryFailedPages\n    ) {\n      id\n      groupId\n      projectId\n      status\n      startedAt\n      finishedAt\n      outputProfile\n      error\n      pages {\n        pageId\n        status\n        startedAt\n        finishedAt\n        pdfUrl\n      }\n      author {\n        id\n        fullName\n      }\n    }\n  }\n':
    types.StartExportDocument,
  '\n  mutation deleteExport($id: String!) {\n    deleteExport(id: $id)\n  }\n':
    types.DeleteExportDocument,
  '\n  mutation addNewChiliImage(\n    $image: Upload!\n    $groupId: String!\n    $projectId: String!\n  ) {\n    addNewChiliImage(\n      file: $image\n      groupId: $groupId\n      projectId: $projectId\n    ) {\n      id\n      path\n      name\n      resolution\n      height\n      width\n      xml\n    }\n  }\n':
    types.AddNewChiliImageDocument,
  '\n  mutation addNewImage(\n    $image: Upload!\n    $groupId: String!\n    $projectId: String!\n    $store: Boolean\n  ) {\n    addNewImage(\n      file: $image\n      groupId: $groupId\n      projectId: $projectId\n      store: $store\n    ) {\n      id\n      resolution\n      height\n      width\n      imageQueryParams\n      size\n    }\n  }\n':
    types.AddNewImageDocument,
  '\n  mutation replaceChiliImage(\n    $image: Upload!\n    $groupId: String!\n    $projectId: String!\n    $oldImageId: String\n  ) {\n    replaceChiliImage(\n      file: $image\n      groupId: $groupId\n      projectId: $projectId\n      oldImageId: $oldImageId\n    ) {\n      id\n      path\n      name\n      resolution\n      height\n      width\n      xml\n    }\n  }\n':
    types.ReplaceChiliImageDocument,
  '\n  mutation replaceImage(\n    $image: Upload!\n    $groupId: String!\n    $projectId: String!\n    $oldImageId: String\n    $store: Boolean\n  ) {\n    replaceImage(\n      file: $image\n      groupId: $groupId\n      projectId: $projectId\n      oldImageId: $oldImageId\n      store: $store\n    ) {\n      id\n      resolution\n      height\n      width\n      imageQueryParams\n      size\n    }\n  }\n':
    types.ReplaceImageDocument,
  '\n  mutation deleteChiliImage($imageId: String!) {\n    deleteChiliImage(imageId: $imageId)\n  }\n':
    types.DeleteChiliImageDocument,
  '\n  mutation deleteImage(\n    $groupId: String!\n    $projectId: String!\n    $imageId: String!\n  ) {\n    deleteImage(\n      groupId: $groupId\n      projectId: $projectId\n      imageId: $imageId\n    )\n  }\n':
    types.DeleteImageDocument,
  '\n  mutation reuploadOldImage(\n    $groupId: String!\n    $projectId: String!\n    $DAPId: String!\n    $DAPValue: String!\n  ) {\n    reuploadOldImage(\n      groupId: $groupId\n      projectId: $projectId\n      DAPId: $DAPId\n      DAPValue: $DAPValue\n    ) {\n      source\n      isFound\n      xml\n    }\n  }\n':
    types.ReuploadOldImageDocument,
  '\n  mutation startMigrationsForRandomProjects(\n    $count: Int!\n  ) {\n    startMigrationsForRandomProjects(\n      count: $count\n    )\n  }\n':
    types.StartMigrationsForRandomProjectsDocument,
  '\n  mutation startMigration(\n    $projectId: String!\n  ) {\n    startMigration(\n      projectId: $projectId\n    )\n  }\n':
    types.StartMigrationDocument,
  '\n  mutation removeMigrationFromQueue(\n    $migrationId: String!\n  ) {\n    removeMigrationFromQueue(\n      migrationId: $migrationId\n    )\n  }\n':
    types.RemoveMigrationFromQueueDocument,
  '\n  mutation emptyMigrationsQueue {\n    emptyMigrationsQueue\n  }\n':
    types.EmptyMigrationsQueueDocument,
  '\n  mutation sendToProduction(\n    $productionData: ProductionDataInput!\n    $cover: Upload!\n  ) {\n    sendToProduction(cover: $cover, productionData: $productionData)\n  }\n':
    types.SendToProductionDocument,
  '\n  mutation updateCustomAttribute(\n    $orderNumber: String!\n    $year: Int!\n    $customerNumber: String!\n    $attributeKey: String!\n    $value: CustomAttributeValueInput\n  ) {\n    updateCustomAttribute(\n      orderNumber: $orderNumber\n      year: $year\n      customerNumber: $customerNumber\n      attributeKey: $attributeKey\n      value: $value\n    )\n  }\n':
    types.UpdateCustomAttributeDocument,
  '\n  mutation createNewProject(\n    $templatePath: String!\n    $groupId: String!\n    $projectName: String!\n    $coverType: String!\n  ) {\n    createNewProject(\n      templatePath: $templatePath\n      groupId: $groupId\n      projectName: $projectName\n      coverType: $coverType\n    )\n  }\n':
    types.CreateNewProjectDocument,
  '\n  mutation copyCoverTemplateToCustomerProject(\n    $projectName: String!\n    $groupId: String!\n  ) {\n    copyCoverTemplateToCustomerProject(\n      projectName: $projectName\n      groupId: $groupId\n    )\n  }\n':
    types.CopyCoverTemplateToCustomerProjectDocument,
  '\n  mutation addNewPage(\n    $groupId: String!\n    $projectId: String!\n    $type: String!\n  ) {\n    addNewPage(groupId: $groupId, projectId: $projectId, type: $type)\n  }\n':
    types.AddNewPageDocument,
  '\n  mutation removePage($projectId: String!, $itemId: String!) {\n    removePage(projectId: $projectId, itemId: $itemId)\n  }\n':
    types.RemovePageDocument,
  '\n  mutation saveDocument($documentId: String!, $xml: String!) {\n    saveDocument(documentId: $documentId, xml: $xml)\n  }\n':
    types.SaveDocumentDocument,
  '\n  mutation changeProjectCurrentTemplate(\n    $groupId: String!\n    $projectId: String!\n    $templatePath: String!\n  ) {\n    changeProjectCurrentTemplate(\n      groupId: $groupId\n      projectId: $projectId\n      templatePath: $templatePath\n    )\n  }\n':
    types.ChangeProjectCurrentTemplateDocument,
  '\n  mutation fixBrokenPages($groupId: String!, $projectId: String!) {\n    fixBrokenPages(groupId: $groupId, projectId: $projectId) {\n      isOk\n      fixedCount\n    }\n  }\n':
    types.FixBrokenPagesDocument,
  '\n  mutation setPagesOrder(\n    $groupId: String!\n    $projectId: String!\n    $pages: [PageOrderInput!]!\n  ) {\n    setPagesOrder(\n      groupId: $groupId\n      projectId: $projectId\n      pages: $pages\n    )\n  }\n':
    types.SetPagesOrderDocument,
  '\n  mutation changeProjectName($projectId: String!, $newName: String!) {\n    changeProjectName(projectId: $projectId, newName: $newName)\n  }\n':
    types.ChangeProjectNameDocument,
  '\n  mutation changeProjectMeta(\n    $groupId: String!\n    $projectId: String!\n    $input: ProjectMetaInput!\n    $adjustChili: Boolean\n  ) {\n    changeProjectMeta(\n      groupId: $groupId\n      projectId: $projectId\n      input: $input\n      adjustChili: $adjustChili\n    )\n  }\n':
    types.ChangeProjectMetaDocument,
  '\n  mutation linkOrder(\n    $groupId: String!\n    $projectId: String!\n    $orderNumber: String!\n    $year: Int!\n    $customerNumber: String!\n  ) {\n    linkOrder(\n      groupId: $groupId\n      projectId: $projectId\n      orderNumber: $orderNumber\n      year: $year\n      customerNumber: $customerNumber\n    ) {\n      successful\n      errorType\n    }\n  }\n':
    types.LinkOrderDocument,
  '\n  mutation unlinkOrder(\n    $groupId: String!\n    $projectId: String!\n    $orderNumber: String!\n    $year: Int!\n    $customerNumber: String!\n  ) {\n    unlinkOrder(\n      groupId: $groupId\n      projectId: $projectId\n      orderNumber: $orderNumber\n      year: $year\n      customerNumber: $customerNumber\n    )\n  }\n':
    types.UnlinkOrderDocument,
  '\n  mutation deleteProject(\n    $groupId: String!\n    $projectId: String!\n    $userId: String!\n  ) {\n    deleteProject(\n      groupId: $groupId\n      projectId: $projectId\n      userId: $userId\n    )\n  }\n':
    types.DeleteProjectDocument,
  '\n  mutation migrateToNewPageSystem(\n    $groupId: String!\n    $projectId: String!\n  ) {\n    migrateToNewPageSystem(groupId: $groupId, projectId: $projectId)\n  }\n':
    types.MigrateToNewPageSystemDocument,
  '\n  mutation checkIsPageCountSystemOutdated(\n    $groupId: String!\n    $projectId: String!\n  ) {\n    checkIsPageCountSystemOutdated(\n      groupId: $groupId\n      projectId: $projectId\n    )\n  }\n':
    types.CheckIsPageCountSystemOutdatedDocument,
  '\n  mutation setThemeColor(\n    $groupId: String!\n    $projectId: String!\n    $key: String!\n    $value: CMYKColorInput!\n    $templatePath: String!\n  ) {\n    setThemeColor(\n      groupId: $groupId\n      projectId: $projectId\n      key: $key\n      value: $value\n      templatePath: $templatePath\n    )\n  }\n':
    types.SetThemeColorDocument,
  '\n  mutation setThemePageLibraryBackground(\n    $groupId: String!\n    $projectId: String!\n    $templatePath: String!\n    $pageType: String!\n    $backgroundId: String!\n    $backgroundCategory: String!\n  ) {\n    setThemePageLibraryBackground(\n      groupId: $groupId\n      projectId: $projectId\n      templatePath: $templatePath\n      pageType: $pageType\n      backgroundId: $backgroundId\n      backgroundCategory: $backgroundCategory\n    )\n  }\n':
    types.SetThemePageLibraryBackgroundDocument,
  '\n  mutation setThemePageUploadsBackground(\n    $groupId: String!\n    $projectId: String!\n    $pageType: String!\n    $templatePath: String!\n    $backgroundId: String!\n  ) {\n    setThemePageUploadsBackground(\n      groupId: $groupId\n      projectId: $projectId\n      pageType: $pageType\n      templatePath: $templatePath\n      backgroundId: $backgroundId\n    )\n  }\n':
    types.SetThemePageUploadsBackgroundDocument,
  '\n  mutation resetThemePageBackground(\n    $groupId: String!\n    $projectId: String!\n    $pageType: String!\n    $templatePath: String!\n  ) {\n    resetThemePageBackground(\n      groupId: $groupId\n      projectId: $projectId\n      pageType: $pageType\n      templatePath: $templatePath\n    )\n  }\n':
    types.ResetThemePageBackgroundDocument,
  '\n  mutation uploadPageBackground(\n    $groupId: String!\n    $projectId: String!\n    $file: Upload!\n  ) {\n    uploadPageBackground(\n      groupId: $groupId\n      projectId: $projectId\n      file: $file\n    ) {\n      id\n      thumbPath\n    }\n  }\n':
    types.UploadPageBackgroundDocument,
  '\n  mutation removeUploadsPageBackground(\n    $groupId: String!\n    $projectId: String!\n    $backgroundId: String!\n    $type: String!\n  ) {\n    removeUploadsPageBackground(\n      groupId: $groupId\n      projectId: $projectId\n      backgroundId: $backgroundId\n      type: $type\n    )\n  }\n':
    types.RemoveUploadsPageBackgroundDocument,
  '\n  query checkAuth {\n    checkAuth\n  }\n':
    types.CheckAuthDocument,
  '\n  query getAdminProjects($groupId: String!) {\n    getAdminProjects(groupId: $groupId) {\n      id\n      name\n      pagePreviews {\n        ...PagePreviewsFields\n      }\n      isMigrationRunning\n      deleted\n    }\n  }\n':
    types.GetAdminProjectsDocument,
  '\n  query getDbSnapshot($groupId: String!) {\n    getDbSnapshot(groupId: $groupId)\n  }\n':
    types.GetDbSnapshotDocument,
  '\n  query getProjectDetails($projectId: String!) {\n    getProjectDetails(projectId: $projectId) {\n      name\n      groupId\n      pagesCount\n      migrations {\n        status\n        type\n        id\n        startedAt\n        author\n      }\n    }\n  }\n':
    types.GetProjectDetailsDocument,
  '\n  query getImagesMigrationData($id: String!) {\n    getImagesMigrationData(id: $id) {\n      type\n      status\n      id\n      startedAt\n      finishedAt\n      author\n      documents {\n        id\n        order\n        name\n        startedAt\n        finishedAt\n        status\n        error\n        variables {\n          type\n          id\n          name\n          prevValue\n          newValue\n          freshExternalUrl\n          status\n          error\n        }\n      }\n    }\n  }\n':
    types.GetImagesMigrationDataDocument,
  '\n  query getExportDocument($taskId: String!) {\n    getExportDocument(taskId: $taskId) {\n      documentId\n      taskId\n      isStarted\n      isFinished\n      isSucceeded\n      url\n    }\n  }\n':
    types.GetExportDocumentDocument,
  '\n  query getKpi($minPagesCount: Int!) {\n    getKpi(minPagesCount: $minPagesCount) {\n      pagesCreated\n      pagesExported\n      projectsCreated\n      projectsCreatedWithMinPagesCount\n      activeGroups\n    }\n  }\n':
    types.GetKpiDocument,
  '\n  query getDocuments($groupId: String!, $projectId: String!) {\n    projectDocuments(groupId: $groupId, projectId: $projectId) {\n      id\n      path\n      previewUrl\n      name\n      type\n      index\n    }\n  }\n':
    types.GetDocumentsDocument,
  '\n  query getProjectDocumentsPreviews(\n    $groupId: String!\n    $projectId: String!\n  ) {\n    getProjectDocumentsPreviews(\n      groupId: $groupId\n      projectId: $projectId\n    ) {\n      id\n      path\n    }\n  }\n':
    types.GetProjectDocumentsPreviewsDocument,
  '\n  query getProjectCoverDocuments(\n    $groupId: String!\n    $projectId: String!\n  ) {\n    findProjectCoverDocuments(\n      groupId: $groupId\n      projectId: $projectId\n    ) {\n      id\n      name\n      path\n      previewUrl\n    }\n  }\n':
    types.GetProjectCoverDocumentsDocument,
  '\n  query getDeliveryAddress(\n    $orderNumber: String!\n    $year: Int!\n    $customerNumber: String!\n  ) {\n    getDeliveryAddress(\n      orderNumber: $orderNumber\n      year: $year\n      customerNumber: $customerNumber\n    ) {\n      firstName\n      lastName\n      streetAndNumber\n      companyOrAdditionalInfo\n      zip\n      city\n      countryCode\n    }\n  }\n':
    types.GetDeliveryAddressDocument,
  '\n  query getYearbookParams(\n    $orderNumber: String!\n    $year: Int!\n    $customerNumber: String!\n  ) {\n    getYearbookParams(\n      orderNumber: $orderNumber\n      year: $year\n      customerNumber: $customerNumber\n    ) {\n      pages {\n        total\n      }\n      cover\n      format\n      grammage\n    }\n  }\n':
    types.GetYearbookParamsDocument,
  '\n  query getProductionAttrs(\n    $orderNumber: String!\n    $year: Int!\n    $customerNumber: String!\n  ) {\n    getProductionAttributes(\n      orderNumber: $orderNumber\n      year: $year\n      customerNumber: $customerNumber\n    ) {\n      orderYear\n      proofDataInProductionDate\n      approvalDate\n      latestPrintDataUploadDate\n      finalDataUploadDate\n      noApproval\n      plannedApprovalDate\n      waitingForApprovalDate\n      proofDataUploadDate\n      orderDeliveryDate\n    }\n  }\n':
    types.GetProductionAttrsDocument,
  '\n  query getProjectExport($id: String!) {\n    getProjectExport(id: $id) {\n      id\n      groupId\n      projectId\n      status\n      startedAt\n      finishedAt\n      outputProfile\n      error\n      pages {\n        pageId\n        status\n        startedAt\n        finishedAt\n        pdfUrl\n        error {\n          code\n          message\n        }\n      }\n      author {\n        id\n        fullName\n      }\n    }\n  }\n':
    types.GetProjectExportDocument,
  '\n  query getProjectExports($groupId: String!, $projectId: String!) {\n    getProjectExports(groupId: $groupId, projectId: $projectId) {\n      id\n      groupId\n      projectId\n      status\n      startedAt\n      finishedAt\n      outputProfile\n      error\n      pages {\n        pageId\n        status\n        startedAt\n        finishedAt\n        pdfUrl\n        error {\n          code\n          message\n        }\n      }\n      author {\n        id\n        fullName\n      }\n    }\n  }\n':
    types.GetProjectExportsDocument,
  '\n  query getBigFontPreviewSvg($fontId: String!) {\n    getBigFontPreviewSvg(id: $fontId)\n  }\n':
    types.GetBigFontPreviewSvgDocument,
  '\n  query getAllFonts {\n    getAllFonts {\n      id\n      name\n      family\n      style\n      svgBig\n      svgSmall\n    }\n  }\n':
    types.GetAllFontsDocument,
  '\n  query getMigrationsQueue {\n    getMigrationsQueue {\n      groupId\n      projectId\n      migrationId\n    }\n  }\n':
    types.GetMigrationsQueueDocument,
  '\n  query getMigrationData(\n    $migrationId: String!\n  ) {\n    getMigrationData(\n      migrationId: $migrationId\n    )\n  }\n':
    types.GetMigrationDataDocument,
  '\n  query getProductionExports($groupId: String!, $projectId: String!) {\n    getProductionExports(groupId: $groupId, projectId: $projectId) {\n      id\n      author {\n        fullName\n      }\n      type\n      status\n      startedAt\n      finishedAt\n      pages\n      groupId\n    }\n  }\n':
    types.GetProductionExportsDocument,
  '\n  fragment LastUpdateFields on ProjectUpdateFlagEntity {\n    avatarUrl\n    name\n    updatedAt\n  }\n':
    types.LastUpdateFieldsFragmentDoc,
  '\n  fragment PagePreviewsFields on ProjectDocument {\n    id\n    name\n    path\n    previewUrl\n  }\n':
    types.PagePreviewsFieldsFragmentDoc,
  '\n  query getProjects($groupId: String!) {\n    projects(groupId: $groupId) {\n      id\n      name\n      path\n      pagePreviews {\n        ...PagePreviewsFields\n      }\n      lastUpdate {\n        ...LastUpdateFields\n      }\n    }\n  }\n':
    types.GetProjectsDocument,
  '\n  query getPageData(\n    $groupId: String!\n    $projectId: String!\n    $documentId: String!\n  ) {\n    getPageData(\n      groupId: $groupId\n      projectId: $projectId\n      documentId: $documentId\n    ) {\n      index\n      templatePath\n      previewUrl\n    }\n  }\n':
    types.GetPageDataDocument,
  '\n  fragment TemplateDocumentFields on TemplateDocumentEntity {\n    id\n    name\n    type\n  }\n':
    types.TemplateDocumentFieldsFragmentDoc,
  '\n  query getProjectTemplateData(\n    $groupId: String!\n    $projectId: String!\n  ) {\n    getProjectTemplateData(groupId: $groupId, projectId: $projectId) {\n      currentTemplatePath\n      templateDocuments {\n        ...TemplateDocumentFields\n      }\n    }\n  }\n':
    types.GetProjectTemplateDataDocument,
  '\n  query getProjectTemplates($groupId: String!, $projectId: String!) {\n    getProjectTemplates(groupId: $groupId, projectId: $projectId) {\n      path\n      name\n      categoryId\n      previewUrl\n      isCurrent\n    }\n  }\n':
    types.GetProjectTemplatesDocument,
  '\n  query getProjectToken($groupId: String!, $projectId: String!) {\n    getProjectToken(groupId: $groupId, projectId: $projectId)\n  }\n':
    types.GetProjectTokenDocument,
  '\n  query getProjectMeta($groupId: String!, $projectId: String!) {\n    getProjectMeta(groupId: $groupId, projectId: $projectId) {\n      id\n      name\n      coverType\n      order\n    }\n  }\n':
    types.GetProjectMetaDocument,
  '\n  query getCategories {\n    categories {\n      id\n      name\n      path\n    }\n  }\n':
    types.GetCategoriesDocument,
  '\n  query getTemplates {\n    templates {\n      id\n      name\n      categoryId\n      previewUrl\n      path\n    }\n  }\n':
    types.GetTemplatesDocument,
  '\n  query getTemplateDocs($categoryId: String!, $templateId: String!) {\n    templateDocuments(\n      categoryId: $categoryId\n      templateId: $templateId\n    ) {\n      id\n      name\n      previewUrl\n      path\n      type\n    }\n  }\n':
    types.GetTemplateDocsDocument,
  '\n  query getThemeColors(\n    $projectId: String!\n    $templatePath: String!\n  ) {\n    getThemeColors(\n      projectId: $projectId\n      templatePath: $templatePath\n    ) {\n      key\n      name\n      value {\n        c\n        m\n        y\n        k\n      }\n    }\n  }\n':
    types.GetThemeColorsDocument,
  '\n  query getThemePages(\n    $projectId: String!\n    $templatePath: String!\n  ) {\n    getThemePages(\n      projectId: $projectId\n      templatePath: $templatePath\n    ) {\n      type\n      thumbUrl\n    }\n  }\n':
    types.GetThemePagesDocument,
  '\n  query getPageBackgroundsLibrary {\n    getPageBackgroundsLibrary {\n      id\n      category\n      thumbPath\n    }\n  }\n':
    types.GetPageBackgroundsLibraryDocument,
  '\n  query getLibraryPageBackgroundXML(\n    $id: String!\n    $category: String!\n  ) {\n    getLibraryPageBackgroundXML(id: $id, category: $category)\n  }\n':
    types.GetLibraryPageBackgroundXmlDocument,
  '\n  query getPageBackgroundUploads(\n    $groupId: String!\n    $projectId: String!\n  ) {\n    getPageBackgroundUploads(\n      groupId: $groupId\n      projectId: $projectId\n    ) {\n      id\n      thumbPath\n      type\n    }\n  }\n':
    types.GetPageBackgroundUploadsDocument,
  '\n  query getPageBackgroundXML($id: String!) {\n    getPageBackgroundXML(id: $id)\n  }\n':
    types.GetPageBackgroundXmlDocument,
  '\n  subscription trackExport($id: String!) {\n    trackExport(id: $id) {\n      id\n      groupId\n      projectId\n      status\n      startedAt\n      finishedAt\n      outputProfile\n      error\n      pages {\n        pageId\n        status\n        startedAt\n        finishedAt\n        pdfUrl\n        error {\n          code\n          message\n        }\n      }\n      author {\n        id\n        fullName\n      }\n    }\n  }\n':
    types.TrackExportDocument
}

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation adminLogin($login: String!, $password: String!) {\n    adminLogin(login: $login, password: $password)\n  }\n'
): typeof documents['\n  mutation adminLogin($login: String!, $password: String!) {\n    adminLogin(login: $login, password: $password)\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation startImageMigration(\n    $groupId: String!\n    $projectId: String!\n    $author: String\n    $type: String\n  ) {\n    startImageMigration(\n      groupId: $groupId\n      projectId: $projectId\n      author: $author\n      type: $type\n    )\n  }\n'
): typeof documents['\n  mutation startImageMigration(\n    $groupId: String!\n    $projectId: String!\n    $author: String\n    $type: String\n  ) {\n    startImageMigration(\n      groupId: $groupId\n      projectId: $projectId\n      author: $author\n      type: $type\n    )\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation startExportDocument(\n    $groupId: String!\n    $projectId: String!\n    $documentId: String!\n    $outputProfile: String\n    $isCoverPage: Boolean\n  ) {\n    startExportDocument(\n      groupId: $groupId\n      projectId: $projectId\n      documentId: $documentId\n      outputProfile: $outputProfile\n      isCoverPage: $isCoverPage\n    ) {\n      documentId\n      taskId\n      isStarted\n      isFinished\n      isSucceeded\n      isVariableSet\n      url\n    }\n  }\n'
): typeof documents['\n  mutation startExportDocument(\n    $groupId: String!\n    $projectId: String!\n    $documentId: String!\n    $outputProfile: String\n    $isCoverPage: Boolean\n  ) {\n    startExportDocument(\n      groupId: $groupId\n      projectId: $projectId\n      documentId: $documentId\n      outputProfile: $outputProfile\n      isCoverPage: $isCoverPage\n    ) {\n      documentId\n      taskId\n      isStarted\n      isFinished\n      isSucceeded\n      isVariableSet\n      url\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation fixVariables($groupId: String!, $projectId: String!) {\n    fixVariables(groupId: $groupId, projectId: $projectId)\n  }\n'
): typeof documents['\n  mutation fixVariables($groupId: String!, $projectId: String!) {\n    fixVariables(groupId: $groupId, projectId: $projectId)\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation addNewAsset(\n    $id: String!\n    $path: String!\n    $name: String!\n    $file: Upload!\n  ) {\n    addNewAsset(id: $id, name: $name, path: $path, file: $file)\n  }\n'
): typeof documents['\n  mutation addNewAsset(\n    $id: String!\n    $path: String!\n    $name: String!\n    $file: Upload!\n  ) {\n    addNewAsset(id: $id, name: $name, path: $path, file: $file)\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation recoverDeletedProject(\n    $groupId: String!\n    $projectId: String!\n  ) {\n    recoverDeletedProject(groupId: $groupId, projectId: $projectId)\n  }\n'
): typeof documents['\n  mutation recoverDeletedProject(\n    $groupId: String!\n    $projectId: String!\n  ) {\n    recoverDeletedProject(groupId: $groupId, projectId: $projectId)\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation emptyExportQueue {\n    emptyExportQueue\n  }\n'
): typeof documents['\n  mutation emptyExportQueue {\n    emptyExportQueue\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation duplicatePage(\n    $pageName: String!\n    $itemId: String!\n    $groupId: String!\n    $projectId: String!\n  ) {\n    duplicatePage(\n      pageName: $pageName\n      itemId: $itemId\n      groupId: $groupId\n      projectId: $projectId\n    )\n  }\n'
): typeof documents['\n  mutation duplicatePage(\n    $pageName: String!\n    $itemId: String!\n    $groupId: String!\n    $projectId: String!\n  ) {\n    duplicatePage(\n      pageName: $pageName\n      itemId: $itemId\n      groupId: $groupId\n      projectId: $projectId\n    )\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation savePagePreview(\n    $groupId: String!\n    $projectId: String!\n    $pageId: String!\n    $image: Upload!\n  ) {\n    savePagePreview(\n      groupId: $groupId\n      projectId: $projectId\n      pageId: $pageId\n      image: $image\n    )\n  }\n'
): typeof documents['\n  mutation savePagePreview(\n    $groupId: String!\n    $projectId: String!\n    $pageId: String!\n    $image: Upload!\n  ) {\n    savePagePreview(\n      groupId: $groupId\n      projectId: $projectId\n      pageId: $pageId\n      image: $image\n    )\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation startExport(\n    $groupId: String!\n    $projectId: String!\n    $pageIds: [String!]\n    $outputProfile: String!\n    $userId: String!\n    $retryFailedPages: Float\n  ) {\n    startExport(\n      groupId: $groupId\n      projectId: $projectId\n      pageIds: $pageIds\n      outputProfile: $outputProfile\n      userId: $userId\n      retryFailedPages: $retryFailedPages\n    ) {\n      id\n      groupId\n      projectId\n      status\n      startedAt\n      finishedAt\n      outputProfile\n      error\n      pages {\n        pageId\n        status\n        startedAt\n        finishedAt\n        pdfUrl\n      }\n      author {\n        id\n        fullName\n      }\n    }\n  }\n'
): typeof documents['\n  mutation startExport(\n    $groupId: String!\n    $projectId: String!\n    $pageIds: [String!]\n    $outputProfile: String!\n    $userId: String!\n    $retryFailedPages: Float\n  ) {\n    startExport(\n      groupId: $groupId\n      projectId: $projectId\n      pageIds: $pageIds\n      outputProfile: $outputProfile\n      userId: $userId\n      retryFailedPages: $retryFailedPages\n    ) {\n      id\n      groupId\n      projectId\n      status\n      startedAt\n      finishedAt\n      outputProfile\n      error\n      pages {\n        pageId\n        status\n        startedAt\n        finishedAt\n        pdfUrl\n      }\n      author {\n        id\n        fullName\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation deleteExport($id: String!) {\n    deleteExport(id: $id)\n  }\n'
): typeof documents['\n  mutation deleteExport($id: String!) {\n    deleteExport(id: $id)\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation addNewChiliImage(\n    $image: Upload!\n    $groupId: String!\n    $projectId: String!\n  ) {\n    addNewChiliImage(\n      file: $image\n      groupId: $groupId\n      projectId: $projectId\n    ) {\n      id\n      path\n      name\n      resolution\n      height\n      width\n      xml\n    }\n  }\n'
): typeof documents['\n  mutation addNewChiliImage(\n    $image: Upload!\n    $groupId: String!\n    $projectId: String!\n  ) {\n    addNewChiliImage(\n      file: $image\n      groupId: $groupId\n      projectId: $projectId\n    ) {\n      id\n      path\n      name\n      resolution\n      height\n      width\n      xml\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation addNewImage(\n    $image: Upload!\n    $groupId: String!\n    $projectId: String!\n    $store: Boolean\n  ) {\n    addNewImage(\n      file: $image\n      groupId: $groupId\n      projectId: $projectId\n      store: $store\n    ) {\n      id\n      resolution\n      height\n      width\n      imageQueryParams\n      size\n    }\n  }\n'
): typeof documents['\n  mutation addNewImage(\n    $image: Upload!\n    $groupId: String!\n    $projectId: String!\n    $store: Boolean\n  ) {\n    addNewImage(\n      file: $image\n      groupId: $groupId\n      projectId: $projectId\n      store: $store\n    ) {\n      id\n      resolution\n      height\n      width\n      imageQueryParams\n      size\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation replaceChiliImage(\n    $image: Upload!\n    $groupId: String!\n    $projectId: String!\n    $oldImageId: String\n  ) {\n    replaceChiliImage(\n      file: $image\n      groupId: $groupId\n      projectId: $projectId\n      oldImageId: $oldImageId\n    ) {\n      id\n      path\n      name\n      resolution\n      height\n      width\n      xml\n    }\n  }\n'
): typeof documents['\n  mutation replaceChiliImage(\n    $image: Upload!\n    $groupId: String!\n    $projectId: String!\n    $oldImageId: String\n  ) {\n    replaceChiliImage(\n      file: $image\n      groupId: $groupId\n      projectId: $projectId\n      oldImageId: $oldImageId\n    ) {\n      id\n      path\n      name\n      resolution\n      height\n      width\n      xml\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation replaceImage(\n    $image: Upload!\n    $groupId: String!\n    $projectId: String!\n    $oldImageId: String\n    $store: Boolean\n  ) {\n    replaceImage(\n      file: $image\n      groupId: $groupId\n      projectId: $projectId\n      oldImageId: $oldImageId\n      store: $store\n    ) {\n      id\n      resolution\n      height\n      width\n      imageQueryParams\n      size\n    }\n  }\n'
): typeof documents['\n  mutation replaceImage(\n    $image: Upload!\n    $groupId: String!\n    $projectId: String!\n    $oldImageId: String\n    $store: Boolean\n  ) {\n    replaceImage(\n      file: $image\n      groupId: $groupId\n      projectId: $projectId\n      oldImageId: $oldImageId\n      store: $store\n    ) {\n      id\n      resolution\n      height\n      width\n      imageQueryParams\n      size\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation deleteChiliImage($imageId: String!) {\n    deleteChiliImage(imageId: $imageId)\n  }\n'
): typeof documents['\n  mutation deleteChiliImage($imageId: String!) {\n    deleteChiliImage(imageId: $imageId)\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation deleteImage(\n    $groupId: String!\n    $projectId: String!\n    $imageId: String!\n  ) {\n    deleteImage(\n      groupId: $groupId\n      projectId: $projectId\n      imageId: $imageId\n    )\n  }\n'
): typeof documents['\n  mutation deleteImage(\n    $groupId: String!\n    $projectId: String!\n    $imageId: String!\n  ) {\n    deleteImage(\n      groupId: $groupId\n      projectId: $projectId\n      imageId: $imageId\n    )\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation reuploadOldImage(\n    $groupId: String!\n    $projectId: String!\n    $DAPId: String!\n    $DAPValue: String!\n  ) {\n    reuploadOldImage(\n      groupId: $groupId\n      projectId: $projectId\n      DAPId: $DAPId\n      DAPValue: $DAPValue\n    ) {\n      source\n      isFound\n      xml\n    }\n  }\n'
): typeof documents['\n  mutation reuploadOldImage(\n    $groupId: String!\n    $projectId: String!\n    $DAPId: String!\n    $DAPValue: String!\n  ) {\n    reuploadOldImage(\n      groupId: $groupId\n      projectId: $projectId\n      DAPId: $DAPId\n      DAPValue: $DAPValue\n    ) {\n      source\n      isFound\n      xml\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation startMigrationsForRandomProjects(\n    $count: Int!\n  ) {\n    startMigrationsForRandomProjects(\n      count: $count\n    )\n  }\n'
): typeof documents['\n  mutation startMigrationsForRandomProjects(\n    $count: Int!\n  ) {\n    startMigrationsForRandomProjects(\n      count: $count\n    )\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation startMigration(\n    $projectId: String!\n  ) {\n    startMigration(\n      projectId: $projectId\n    )\n  }\n'
): typeof documents['\n  mutation startMigration(\n    $projectId: String!\n  ) {\n    startMigration(\n      projectId: $projectId\n    )\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation removeMigrationFromQueue(\n    $migrationId: String!\n  ) {\n    removeMigrationFromQueue(\n      migrationId: $migrationId\n    )\n  }\n'
): typeof documents['\n  mutation removeMigrationFromQueue(\n    $migrationId: String!\n  ) {\n    removeMigrationFromQueue(\n      migrationId: $migrationId\n    )\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation emptyMigrationsQueue {\n    emptyMigrationsQueue\n  }\n'
): typeof documents['\n  mutation emptyMigrationsQueue {\n    emptyMigrationsQueue\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation sendToProduction(\n    $productionData: ProductionDataInput!\n    $cover: Upload!\n  ) {\n    sendToProduction(cover: $cover, productionData: $productionData)\n  }\n'
): typeof documents['\n  mutation sendToProduction(\n    $productionData: ProductionDataInput!\n    $cover: Upload!\n  ) {\n    sendToProduction(cover: $cover, productionData: $productionData)\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation updateCustomAttribute(\n    $orderNumber: String!\n    $year: Int!\n    $customerNumber: String!\n    $attributeKey: String!\n    $value: CustomAttributeValueInput\n  ) {\n    updateCustomAttribute(\n      orderNumber: $orderNumber\n      year: $year\n      customerNumber: $customerNumber\n      attributeKey: $attributeKey\n      value: $value\n    )\n  }\n'
): typeof documents['\n  mutation updateCustomAttribute(\n    $orderNumber: String!\n    $year: Int!\n    $customerNumber: String!\n    $attributeKey: String!\n    $value: CustomAttributeValueInput\n  ) {\n    updateCustomAttribute(\n      orderNumber: $orderNumber\n      year: $year\n      customerNumber: $customerNumber\n      attributeKey: $attributeKey\n      value: $value\n    )\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation createNewProject(\n    $templatePath: String!\n    $groupId: String!\n    $projectName: String!\n    $coverType: String!\n  ) {\n    createNewProject(\n      templatePath: $templatePath\n      groupId: $groupId\n      projectName: $projectName\n      coverType: $coverType\n    )\n  }\n'
): typeof documents['\n  mutation createNewProject(\n    $templatePath: String!\n    $groupId: String!\n    $projectName: String!\n    $coverType: String!\n  ) {\n    createNewProject(\n      templatePath: $templatePath\n      groupId: $groupId\n      projectName: $projectName\n      coverType: $coverType\n    )\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation copyCoverTemplateToCustomerProject(\n    $projectName: String!\n    $groupId: String!\n  ) {\n    copyCoverTemplateToCustomerProject(\n      projectName: $projectName\n      groupId: $groupId\n    )\n  }\n'
): typeof documents['\n  mutation copyCoverTemplateToCustomerProject(\n    $projectName: String!\n    $groupId: String!\n  ) {\n    copyCoverTemplateToCustomerProject(\n      projectName: $projectName\n      groupId: $groupId\n    )\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation addNewPage(\n    $groupId: String!\n    $projectId: String!\n    $type: String!\n  ) {\n    addNewPage(groupId: $groupId, projectId: $projectId, type: $type)\n  }\n'
): typeof documents['\n  mutation addNewPage(\n    $groupId: String!\n    $projectId: String!\n    $type: String!\n  ) {\n    addNewPage(groupId: $groupId, projectId: $projectId, type: $type)\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation removePage($projectId: String!, $itemId: String!) {\n    removePage(projectId: $projectId, itemId: $itemId)\n  }\n'
): typeof documents['\n  mutation removePage($projectId: String!, $itemId: String!) {\n    removePage(projectId: $projectId, itemId: $itemId)\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation saveDocument($documentId: String!, $xml: String!) {\n    saveDocument(documentId: $documentId, xml: $xml)\n  }\n'
): typeof documents['\n  mutation saveDocument($documentId: String!, $xml: String!) {\n    saveDocument(documentId: $documentId, xml: $xml)\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation changeProjectCurrentTemplate(\n    $groupId: String!\n    $projectId: String!\n    $templatePath: String!\n  ) {\n    changeProjectCurrentTemplate(\n      groupId: $groupId\n      projectId: $projectId\n      templatePath: $templatePath\n    )\n  }\n'
): typeof documents['\n  mutation changeProjectCurrentTemplate(\n    $groupId: String!\n    $projectId: String!\n    $templatePath: String!\n  ) {\n    changeProjectCurrentTemplate(\n      groupId: $groupId\n      projectId: $projectId\n      templatePath: $templatePath\n    )\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation fixBrokenPages($groupId: String!, $projectId: String!) {\n    fixBrokenPages(groupId: $groupId, projectId: $projectId) {\n      isOk\n      fixedCount\n    }\n  }\n'
): typeof documents['\n  mutation fixBrokenPages($groupId: String!, $projectId: String!) {\n    fixBrokenPages(groupId: $groupId, projectId: $projectId) {\n      isOk\n      fixedCount\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation setPagesOrder(\n    $groupId: String!\n    $projectId: String!\n    $pages: [PageOrderInput!]!\n  ) {\n    setPagesOrder(\n      groupId: $groupId\n      projectId: $projectId\n      pages: $pages\n    )\n  }\n'
): typeof documents['\n  mutation setPagesOrder(\n    $groupId: String!\n    $projectId: String!\n    $pages: [PageOrderInput!]!\n  ) {\n    setPagesOrder(\n      groupId: $groupId\n      projectId: $projectId\n      pages: $pages\n    )\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation changeProjectName($projectId: String!, $newName: String!) {\n    changeProjectName(projectId: $projectId, newName: $newName)\n  }\n'
): typeof documents['\n  mutation changeProjectName($projectId: String!, $newName: String!) {\n    changeProjectName(projectId: $projectId, newName: $newName)\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation changeProjectMeta(\n    $groupId: String!\n    $projectId: String!\n    $input: ProjectMetaInput!\n    $adjustChili: Boolean\n  ) {\n    changeProjectMeta(\n      groupId: $groupId\n      projectId: $projectId\n      input: $input\n      adjustChili: $adjustChili\n    )\n  }\n'
): typeof documents['\n  mutation changeProjectMeta(\n    $groupId: String!\n    $projectId: String!\n    $input: ProjectMetaInput!\n    $adjustChili: Boolean\n  ) {\n    changeProjectMeta(\n      groupId: $groupId\n      projectId: $projectId\n      input: $input\n      adjustChili: $adjustChili\n    )\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation linkOrder(\n    $groupId: String!\n    $projectId: String!\n    $orderNumber: String!\n    $year: Int!\n    $customerNumber: String!\n  ) {\n    linkOrder(\n      groupId: $groupId\n      projectId: $projectId\n      orderNumber: $orderNumber\n      year: $year\n      customerNumber: $customerNumber\n    ) {\n      successful\n      errorType\n    }\n  }\n'
): typeof documents['\n  mutation linkOrder(\n    $groupId: String!\n    $projectId: String!\n    $orderNumber: String!\n    $year: Int!\n    $customerNumber: String!\n  ) {\n    linkOrder(\n      groupId: $groupId\n      projectId: $projectId\n      orderNumber: $orderNumber\n      year: $year\n      customerNumber: $customerNumber\n    ) {\n      successful\n      errorType\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation unlinkOrder(\n    $groupId: String!\n    $projectId: String!\n    $orderNumber: String!\n    $year: Int!\n    $customerNumber: String!\n  ) {\n    unlinkOrder(\n      groupId: $groupId\n      projectId: $projectId\n      orderNumber: $orderNumber\n      year: $year\n      customerNumber: $customerNumber\n    )\n  }\n'
): typeof documents['\n  mutation unlinkOrder(\n    $groupId: String!\n    $projectId: String!\n    $orderNumber: String!\n    $year: Int!\n    $customerNumber: String!\n  ) {\n    unlinkOrder(\n      groupId: $groupId\n      projectId: $projectId\n      orderNumber: $orderNumber\n      year: $year\n      customerNumber: $customerNumber\n    )\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation deleteProject(\n    $groupId: String!\n    $projectId: String!\n    $userId: String!\n  ) {\n    deleteProject(\n      groupId: $groupId\n      projectId: $projectId\n      userId: $userId\n    )\n  }\n'
): typeof documents['\n  mutation deleteProject(\n    $groupId: String!\n    $projectId: String!\n    $userId: String!\n  ) {\n    deleteProject(\n      groupId: $groupId\n      projectId: $projectId\n      userId: $userId\n    )\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation migrateToNewPageSystem(\n    $groupId: String!\n    $projectId: String!\n  ) {\n    migrateToNewPageSystem(groupId: $groupId, projectId: $projectId)\n  }\n'
): typeof documents['\n  mutation migrateToNewPageSystem(\n    $groupId: String!\n    $projectId: String!\n  ) {\n    migrateToNewPageSystem(groupId: $groupId, projectId: $projectId)\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation checkIsPageCountSystemOutdated(\n    $groupId: String!\n    $projectId: String!\n  ) {\n    checkIsPageCountSystemOutdated(\n      groupId: $groupId\n      projectId: $projectId\n    )\n  }\n'
): typeof documents['\n  mutation checkIsPageCountSystemOutdated(\n    $groupId: String!\n    $projectId: String!\n  ) {\n    checkIsPageCountSystemOutdated(\n      groupId: $groupId\n      projectId: $projectId\n    )\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation setThemeColor(\n    $groupId: String!\n    $projectId: String!\n    $key: String!\n    $value: CMYKColorInput!\n    $templatePath: String!\n  ) {\n    setThemeColor(\n      groupId: $groupId\n      projectId: $projectId\n      key: $key\n      value: $value\n      templatePath: $templatePath\n    )\n  }\n'
): typeof documents['\n  mutation setThemeColor(\n    $groupId: String!\n    $projectId: String!\n    $key: String!\n    $value: CMYKColorInput!\n    $templatePath: String!\n  ) {\n    setThemeColor(\n      groupId: $groupId\n      projectId: $projectId\n      key: $key\n      value: $value\n      templatePath: $templatePath\n    )\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation setThemePageLibraryBackground(\n    $groupId: String!\n    $projectId: String!\n    $templatePath: String!\n    $pageType: String!\n    $backgroundId: String!\n    $backgroundCategory: String!\n  ) {\n    setThemePageLibraryBackground(\n      groupId: $groupId\n      projectId: $projectId\n      templatePath: $templatePath\n      pageType: $pageType\n      backgroundId: $backgroundId\n      backgroundCategory: $backgroundCategory\n    )\n  }\n'
): typeof documents['\n  mutation setThemePageLibraryBackground(\n    $groupId: String!\n    $projectId: String!\n    $templatePath: String!\n    $pageType: String!\n    $backgroundId: String!\n    $backgroundCategory: String!\n  ) {\n    setThemePageLibraryBackground(\n      groupId: $groupId\n      projectId: $projectId\n      templatePath: $templatePath\n      pageType: $pageType\n      backgroundId: $backgroundId\n      backgroundCategory: $backgroundCategory\n    )\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation setThemePageUploadsBackground(\n    $groupId: String!\n    $projectId: String!\n    $pageType: String!\n    $templatePath: String!\n    $backgroundId: String!\n  ) {\n    setThemePageUploadsBackground(\n      groupId: $groupId\n      projectId: $projectId\n      pageType: $pageType\n      templatePath: $templatePath\n      backgroundId: $backgroundId\n    )\n  }\n'
): typeof documents['\n  mutation setThemePageUploadsBackground(\n    $groupId: String!\n    $projectId: String!\n    $pageType: String!\n    $templatePath: String!\n    $backgroundId: String!\n  ) {\n    setThemePageUploadsBackground(\n      groupId: $groupId\n      projectId: $projectId\n      pageType: $pageType\n      templatePath: $templatePath\n      backgroundId: $backgroundId\n    )\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation resetThemePageBackground(\n    $groupId: String!\n    $projectId: String!\n    $pageType: String!\n    $templatePath: String!\n  ) {\n    resetThemePageBackground(\n      groupId: $groupId\n      projectId: $projectId\n      pageType: $pageType\n      templatePath: $templatePath\n    )\n  }\n'
): typeof documents['\n  mutation resetThemePageBackground(\n    $groupId: String!\n    $projectId: String!\n    $pageType: String!\n    $templatePath: String!\n  ) {\n    resetThemePageBackground(\n      groupId: $groupId\n      projectId: $projectId\n      pageType: $pageType\n      templatePath: $templatePath\n    )\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation uploadPageBackground(\n    $groupId: String!\n    $projectId: String!\n    $file: Upload!\n  ) {\n    uploadPageBackground(\n      groupId: $groupId\n      projectId: $projectId\n      file: $file\n    ) {\n      id\n      thumbPath\n    }\n  }\n'
): typeof documents['\n  mutation uploadPageBackground(\n    $groupId: String!\n    $projectId: String!\n    $file: Upload!\n  ) {\n    uploadPageBackground(\n      groupId: $groupId\n      projectId: $projectId\n      file: $file\n    ) {\n      id\n      thumbPath\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation removeUploadsPageBackground(\n    $groupId: String!\n    $projectId: String!\n    $backgroundId: String!\n    $type: String!\n  ) {\n    removeUploadsPageBackground(\n      groupId: $groupId\n      projectId: $projectId\n      backgroundId: $backgroundId\n      type: $type\n    )\n  }\n'
): typeof documents['\n  mutation removeUploadsPageBackground(\n    $groupId: String!\n    $projectId: String!\n    $backgroundId: String!\n    $type: String!\n  ) {\n    removeUploadsPageBackground(\n      groupId: $groupId\n      projectId: $projectId\n      backgroundId: $backgroundId\n      type: $type\n    )\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query checkAuth {\n    checkAuth\n  }\n'
): typeof documents['\n  query checkAuth {\n    checkAuth\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getAdminProjects($groupId: String!) {\n    getAdminProjects(groupId: $groupId) {\n      id\n      name\n      pagePreviews {\n        ...PagePreviewsFields\n      }\n      isMigrationRunning\n      deleted\n    }\n  }\n'
): typeof documents['\n  query getAdminProjects($groupId: String!) {\n    getAdminProjects(groupId: $groupId) {\n      id\n      name\n      pagePreviews {\n        ...PagePreviewsFields\n      }\n      isMigrationRunning\n      deleted\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getDbSnapshot($groupId: String!) {\n    getDbSnapshot(groupId: $groupId)\n  }\n'
): typeof documents['\n  query getDbSnapshot($groupId: String!) {\n    getDbSnapshot(groupId: $groupId)\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getProjectDetails($projectId: String!) {\n    getProjectDetails(projectId: $projectId) {\n      name\n      groupId\n      pagesCount\n      migrations {\n        status\n        type\n        id\n        startedAt\n        author\n      }\n    }\n  }\n'
): typeof documents['\n  query getProjectDetails($projectId: String!) {\n    getProjectDetails(projectId: $projectId) {\n      name\n      groupId\n      pagesCount\n      migrations {\n        status\n        type\n        id\n        startedAt\n        author\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getImagesMigrationData($id: String!) {\n    getImagesMigrationData(id: $id) {\n      type\n      status\n      id\n      startedAt\n      finishedAt\n      author\n      documents {\n        id\n        order\n        name\n        startedAt\n        finishedAt\n        status\n        error\n        variables {\n          type\n          id\n          name\n          prevValue\n          newValue\n          freshExternalUrl\n          status\n          error\n        }\n      }\n    }\n  }\n'
): typeof documents['\n  query getImagesMigrationData($id: String!) {\n    getImagesMigrationData(id: $id) {\n      type\n      status\n      id\n      startedAt\n      finishedAt\n      author\n      documents {\n        id\n        order\n        name\n        startedAt\n        finishedAt\n        status\n        error\n        variables {\n          type\n          id\n          name\n          prevValue\n          newValue\n          freshExternalUrl\n          status\n          error\n        }\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getExportDocument($taskId: String!) {\n    getExportDocument(taskId: $taskId) {\n      documentId\n      taskId\n      isStarted\n      isFinished\n      isSucceeded\n      url\n    }\n  }\n'
): typeof documents['\n  query getExportDocument($taskId: String!) {\n    getExportDocument(taskId: $taskId) {\n      documentId\n      taskId\n      isStarted\n      isFinished\n      isSucceeded\n      url\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getKpi($minPagesCount: Int!) {\n    getKpi(minPagesCount: $minPagesCount) {\n      pagesCreated\n      pagesExported\n      projectsCreated\n      projectsCreatedWithMinPagesCount\n      activeGroups\n    }\n  }\n'
): typeof documents['\n  query getKpi($minPagesCount: Int!) {\n    getKpi(minPagesCount: $minPagesCount) {\n      pagesCreated\n      pagesExported\n      projectsCreated\n      projectsCreatedWithMinPagesCount\n      activeGroups\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getDocuments($groupId: String!, $projectId: String!) {\n    projectDocuments(groupId: $groupId, projectId: $projectId) {\n      id\n      path\n      previewUrl\n      name\n      type\n      index\n    }\n  }\n'
): typeof documents['\n  query getDocuments($groupId: String!, $projectId: String!) {\n    projectDocuments(groupId: $groupId, projectId: $projectId) {\n      id\n      path\n      previewUrl\n      name\n      type\n      index\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getProjectDocumentsPreviews(\n    $groupId: String!\n    $projectId: String!\n  ) {\n    getProjectDocumentsPreviews(\n      groupId: $groupId\n      projectId: $projectId\n    ) {\n      id\n      path\n    }\n  }\n'
): typeof documents['\n  query getProjectDocumentsPreviews(\n    $groupId: String!\n    $projectId: String!\n  ) {\n    getProjectDocumentsPreviews(\n      groupId: $groupId\n      projectId: $projectId\n    ) {\n      id\n      path\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getProjectCoverDocuments(\n    $groupId: String!\n    $projectId: String!\n  ) {\n    findProjectCoverDocuments(\n      groupId: $groupId\n      projectId: $projectId\n    ) {\n      id\n      name\n      path\n      previewUrl\n    }\n  }\n'
): typeof documents['\n  query getProjectCoverDocuments(\n    $groupId: String!\n    $projectId: String!\n  ) {\n    findProjectCoverDocuments(\n      groupId: $groupId\n      projectId: $projectId\n    ) {\n      id\n      name\n      path\n      previewUrl\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getDeliveryAddress(\n    $orderNumber: String!\n    $year: Int!\n    $customerNumber: String!\n  ) {\n    getDeliveryAddress(\n      orderNumber: $orderNumber\n      year: $year\n      customerNumber: $customerNumber\n    ) {\n      firstName\n      lastName\n      streetAndNumber\n      companyOrAdditionalInfo\n      zip\n      city\n      countryCode\n    }\n  }\n'
): typeof documents['\n  query getDeliveryAddress(\n    $orderNumber: String!\n    $year: Int!\n    $customerNumber: String!\n  ) {\n    getDeliveryAddress(\n      orderNumber: $orderNumber\n      year: $year\n      customerNumber: $customerNumber\n    ) {\n      firstName\n      lastName\n      streetAndNumber\n      companyOrAdditionalInfo\n      zip\n      city\n      countryCode\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getYearbookParams(\n    $orderNumber: String!\n    $year: Int!\n    $customerNumber: String!\n  ) {\n    getYearbookParams(\n      orderNumber: $orderNumber\n      year: $year\n      customerNumber: $customerNumber\n    ) {\n      pages {\n        total\n      }\n      cover\n      format\n      grammage\n    }\n  }\n'
): typeof documents['\n  query getYearbookParams(\n    $orderNumber: String!\n    $year: Int!\n    $customerNumber: String!\n  ) {\n    getYearbookParams(\n      orderNumber: $orderNumber\n      year: $year\n      customerNumber: $customerNumber\n    ) {\n      pages {\n        total\n      }\n      cover\n      format\n      grammage\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getProductionAttrs(\n    $orderNumber: String!\n    $year: Int!\n    $customerNumber: String!\n  ) {\n    getProductionAttributes(\n      orderNumber: $orderNumber\n      year: $year\n      customerNumber: $customerNumber\n    ) {\n      orderYear\n      proofDataInProductionDate\n      approvalDate\n      latestPrintDataUploadDate\n      finalDataUploadDate\n      noApproval\n      plannedApprovalDate\n      waitingForApprovalDate\n      proofDataUploadDate\n      orderDeliveryDate\n    }\n  }\n'
): typeof documents['\n  query getProductionAttrs(\n    $orderNumber: String!\n    $year: Int!\n    $customerNumber: String!\n  ) {\n    getProductionAttributes(\n      orderNumber: $orderNumber\n      year: $year\n      customerNumber: $customerNumber\n    ) {\n      orderYear\n      proofDataInProductionDate\n      approvalDate\n      latestPrintDataUploadDate\n      finalDataUploadDate\n      noApproval\n      plannedApprovalDate\n      waitingForApprovalDate\n      proofDataUploadDate\n      orderDeliveryDate\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getProjectExport($id: String!) {\n    getProjectExport(id: $id) {\n      id\n      groupId\n      projectId\n      status\n      startedAt\n      finishedAt\n      outputProfile\n      error\n      pages {\n        pageId\n        status\n        startedAt\n        finishedAt\n        pdfUrl\n        error {\n          code\n          message\n        }\n      }\n      author {\n        id\n        fullName\n      }\n    }\n  }\n'
): typeof documents['\n  query getProjectExport($id: String!) {\n    getProjectExport(id: $id) {\n      id\n      groupId\n      projectId\n      status\n      startedAt\n      finishedAt\n      outputProfile\n      error\n      pages {\n        pageId\n        status\n        startedAt\n        finishedAt\n        pdfUrl\n        error {\n          code\n          message\n        }\n      }\n      author {\n        id\n        fullName\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getProjectExports($groupId: String!, $projectId: String!) {\n    getProjectExports(groupId: $groupId, projectId: $projectId) {\n      id\n      groupId\n      projectId\n      status\n      startedAt\n      finishedAt\n      outputProfile\n      error\n      pages {\n        pageId\n        status\n        startedAt\n        finishedAt\n        pdfUrl\n        error {\n          code\n          message\n        }\n      }\n      author {\n        id\n        fullName\n      }\n    }\n  }\n'
): typeof documents['\n  query getProjectExports($groupId: String!, $projectId: String!) {\n    getProjectExports(groupId: $groupId, projectId: $projectId) {\n      id\n      groupId\n      projectId\n      status\n      startedAt\n      finishedAt\n      outputProfile\n      error\n      pages {\n        pageId\n        status\n        startedAt\n        finishedAt\n        pdfUrl\n        error {\n          code\n          message\n        }\n      }\n      author {\n        id\n        fullName\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getBigFontPreviewSvg($fontId: String!) {\n    getBigFontPreviewSvg(id: $fontId)\n  }\n'
): typeof documents['\n  query getBigFontPreviewSvg($fontId: String!) {\n    getBigFontPreviewSvg(id: $fontId)\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getAllFonts {\n    getAllFonts {\n      id\n      name\n      family\n      style\n      svgBig\n      svgSmall\n    }\n  }\n'
): typeof documents['\n  query getAllFonts {\n    getAllFonts {\n      id\n      name\n      family\n      style\n      svgBig\n      svgSmall\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getMigrationsQueue {\n    getMigrationsQueue {\n      groupId\n      projectId\n      migrationId\n    }\n  }\n'
): typeof documents['\n  query getMigrationsQueue {\n    getMigrationsQueue {\n      groupId\n      projectId\n      migrationId\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getMigrationData(\n    $migrationId: String!\n  ) {\n    getMigrationData(\n      migrationId: $migrationId\n    )\n  }\n'
): typeof documents['\n  query getMigrationData(\n    $migrationId: String!\n  ) {\n    getMigrationData(\n      migrationId: $migrationId\n    )\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getProductionExports($groupId: String!, $projectId: String!) {\n    getProductionExports(groupId: $groupId, projectId: $projectId) {\n      id\n      author {\n        fullName\n      }\n      type\n      status\n      startedAt\n      finishedAt\n      pages\n      groupId\n    }\n  }\n'
): typeof documents['\n  query getProductionExports($groupId: String!, $projectId: String!) {\n    getProductionExports(groupId: $groupId, projectId: $projectId) {\n      id\n      author {\n        fullName\n      }\n      type\n      status\n      startedAt\n      finishedAt\n      pages\n      groupId\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment LastUpdateFields on ProjectUpdateFlagEntity {\n    avatarUrl\n    name\n    updatedAt\n  }\n'
): typeof documents['\n  fragment LastUpdateFields on ProjectUpdateFlagEntity {\n    avatarUrl\n    name\n    updatedAt\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PagePreviewsFields on ProjectDocument {\n    id\n    name\n    path\n    previewUrl\n  }\n'
): typeof documents['\n  fragment PagePreviewsFields on ProjectDocument {\n    id\n    name\n    path\n    previewUrl\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getProjects($groupId: String!) {\n    projects(groupId: $groupId) {\n      id\n      name\n      path\n      pagePreviews {\n        ...PagePreviewsFields\n      }\n      lastUpdate {\n        ...LastUpdateFields\n      }\n    }\n  }\n'
): typeof documents['\n  query getProjects($groupId: String!) {\n    projects(groupId: $groupId) {\n      id\n      name\n      path\n      pagePreviews {\n        ...PagePreviewsFields\n      }\n      lastUpdate {\n        ...LastUpdateFields\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getPageData(\n    $groupId: String!\n    $projectId: String!\n    $documentId: String!\n  ) {\n    getPageData(\n      groupId: $groupId\n      projectId: $projectId\n      documentId: $documentId\n    ) {\n      index\n      templatePath\n      previewUrl\n    }\n  }\n'
): typeof documents['\n  query getPageData(\n    $groupId: String!\n    $projectId: String!\n    $documentId: String!\n  ) {\n    getPageData(\n      groupId: $groupId\n      projectId: $projectId\n      documentId: $documentId\n    ) {\n      index\n      templatePath\n      previewUrl\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment TemplateDocumentFields on TemplateDocumentEntity {\n    id\n    name\n    type\n  }\n'
): typeof documents['\n  fragment TemplateDocumentFields on TemplateDocumentEntity {\n    id\n    name\n    type\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getProjectTemplateData(\n    $groupId: String!\n    $projectId: String!\n  ) {\n    getProjectTemplateData(groupId: $groupId, projectId: $projectId) {\n      currentTemplatePath\n      templateDocuments {\n        ...TemplateDocumentFields\n      }\n    }\n  }\n'
): typeof documents['\n  query getProjectTemplateData(\n    $groupId: String!\n    $projectId: String!\n  ) {\n    getProjectTemplateData(groupId: $groupId, projectId: $projectId) {\n      currentTemplatePath\n      templateDocuments {\n        ...TemplateDocumentFields\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getProjectTemplates($groupId: String!, $projectId: String!) {\n    getProjectTemplates(groupId: $groupId, projectId: $projectId) {\n      path\n      name\n      categoryId\n      previewUrl\n      isCurrent\n    }\n  }\n'
): typeof documents['\n  query getProjectTemplates($groupId: String!, $projectId: String!) {\n    getProjectTemplates(groupId: $groupId, projectId: $projectId) {\n      path\n      name\n      categoryId\n      previewUrl\n      isCurrent\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getProjectToken($groupId: String!, $projectId: String!) {\n    getProjectToken(groupId: $groupId, projectId: $projectId)\n  }\n'
): typeof documents['\n  query getProjectToken($groupId: String!, $projectId: String!) {\n    getProjectToken(groupId: $groupId, projectId: $projectId)\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getProjectMeta($groupId: String!, $projectId: String!) {\n    getProjectMeta(groupId: $groupId, projectId: $projectId) {\n      id\n      name\n      coverType\n      order\n    }\n  }\n'
): typeof documents['\n  query getProjectMeta($groupId: String!, $projectId: String!) {\n    getProjectMeta(groupId: $groupId, projectId: $projectId) {\n      id\n      name\n      coverType\n      order\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getCategories {\n    categories {\n      id\n      name\n      path\n    }\n  }\n'
): typeof documents['\n  query getCategories {\n    categories {\n      id\n      name\n      path\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getTemplates {\n    templates {\n      id\n      name\n      categoryId\n      previewUrl\n      path\n    }\n  }\n'
): typeof documents['\n  query getTemplates {\n    templates {\n      id\n      name\n      categoryId\n      previewUrl\n      path\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getTemplateDocs($categoryId: String!, $templateId: String!) {\n    templateDocuments(\n      categoryId: $categoryId\n      templateId: $templateId\n    ) {\n      id\n      name\n      previewUrl\n      path\n      type\n    }\n  }\n'
): typeof documents['\n  query getTemplateDocs($categoryId: String!, $templateId: String!) {\n    templateDocuments(\n      categoryId: $categoryId\n      templateId: $templateId\n    ) {\n      id\n      name\n      previewUrl\n      path\n      type\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getThemeColors(\n    $projectId: String!\n    $templatePath: String!\n  ) {\n    getThemeColors(\n      projectId: $projectId\n      templatePath: $templatePath\n    ) {\n      key\n      name\n      value {\n        c\n        m\n        y\n        k\n      }\n    }\n  }\n'
): typeof documents['\n  query getThemeColors(\n    $projectId: String!\n    $templatePath: String!\n  ) {\n    getThemeColors(\n      projectId: $projectId\n      templatePath: $templatePath\n    ) {\n      key\n      name\n      value {\n        c\n        m\n        y\n        k\n      }\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getThemePages(\n    $projectId: String!\n    $templatePath: String!\n  ) {\n    getThemePages(\n      projectId: $projectId\n      templatePath: $templatePath\n    ) {\n      type\n      thumbUrl\n    }\n  }\n'
): typeof documents['\n  query getThemePages(\n    $projectId: String!\n    $templatePath: String!\n  ) {\n    getThemePages(\n      projectId: $projectId\n      templatePath: $templatePath\n    ) {\n      type\n      thumbUrl\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getPageBackgroundsLibrary {\n    getPageBackgroundsLibrary {\n      id\n      category\n      thumbPath\n    }\n  }\n'
): typeof documents['\n  query getPageBackgroundsLibrary {\n    getPageBackgroundsLibrary {\n      id\n      category\n      thumbPath\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getLibraryPageBackgroundXML(\n    $id: String!\n    $category: String!\n  ) {\n    getLibraryPageBackgroundXML(id: $id, category: $category)\n  }\n'
): typeof documents['\n  query getLibraryPageBackgroundXML(\n    $id: String!\n    $category: String!\n  ) {\n    getLibraryPageBackgroundXML(id: $id, category: $category)\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getPageBackgroundUploads(\n    $groupId: String!\n    $projectId: String!\n  ) {\n    getPageBackgroundUploads(\n      groupId: $groupId\n      projectId: $projectId\n    ) {\n      id\n      thumbPath\n      type\n    }\n  }\n'
): typeof documents['\n  query getPageBackgroundUploads(\n    $groupId: String!\n    $projectId: String!\n  ) {\n    getPageBackgroundUploads(\n      groupId: $groupId\n      projectId: $projectId\n    ) {\n      id\n      thumbPath\n      type\n    }\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query getPageBackgroundXML($id: String!) {\n    getPageBackgroundXML(id: $id)\n  }\n'
): typeof documents['\n  query getPageBackgroundXML($id: String!) {\n    getPageBackgroundXML(id: $id)\n  }\n']
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  subscription trackExport($id: String!) {\n    trackExport(id: $id) {\n      id\n      groupId\n      projectId\n      status\n      startedAt\n      finishedAt\n      outputProfile\n      error\n      pages {\n        pageId\n        status\n        startedAt\n        finishedAt\n        pdfUrl\n        error {\n          code\n          message\n        }\n      }\n      author {\n        id\n        fullName\n      }\n    }\n  }\n'
): typeof documents['\n  subscription trackExport($id: String!) {\n    trackExport(id: $id) {\n      id\n      groupId\n      projectId\n      status\n      startedAt\n      finishedAt\n      outputProfile\n      error\n      pages {\n        pageId\n        status\n        startedAt\n        finishedAt\n        pdfUrl\n        error {\n          code\n          message\n        }\n      }\n      author {\n        id\n        fullName\n      }\n    }\n  }\n']

export function gql(source: string) {
  return (documents as any)[source] ?? {}
}

export type DocumentType<
  TDocumentNode extends DocumentNode<any, any>
> = TDocumentNode extends DocumentNode<infer TType, any>
  ? TType
  : never
