import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  Operation,
  split
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { relayStylePagination } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'
import {
  FragmentDefinitionNode,
  OperationDefinitionNode
} from 'graphql'
import { createClient } from 'graphql-ws'

import { isProd } from 'shared/helpers/env'
import { redirectToGradooLogin } from 'shared/utils/auth'

import { isJWTExpired } from './utils/jwt'
import { signInToLayoutCreator } from './utils/login'
import {
  getValidGradooTokens,
  refreshLayoutCreatorToken
} from './utils/refresh'

const headers = {
  'keep-alive': 'true'
}

const layoutCreatorLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  headers
})

const layoutCreatorAuthLink = setContext(async (_, { headers }) => {
  try {
    const gradooTokens = await getValidGradooTokens()
    const lcToken = localStorage.getItem('lcToken')
    const lcRefreshToken = localStorage.getItem('lcRefreshToken')
    const adminToken = localStorage.getItem('adminToken')

    const generateResult = (token: string) => ({
      headers: {
        ...headers,
        authorization: 'Bearer ' + (token ? token : ''),
        admin: adminToken
      }
    })

    if (!gradooTokens) {
      await redirectToGradooLogin()
    }

    if (!lcToken || !lcRefreshToken) {
      const { token } = await signInToLayoutCreator()
      return generateResult(token)
    }

    const isAccessTokenExpired = isJWTExpired(lcToken)

    if (isAccessTokenExpired) {
      const isRefreshTokenExpired = isJWTExpired(lcRefreshToken)

      if (isRefreshTokenExpired) {
        const { token } = await signInToLayoutCreator()
        return generateResult(token)
      }

      const token = await refreshLayoutCreatorToken()
      return generateResult(token)
    }

    return generateResult(lcToken)
  } catch (e) {
    if (!isProd()) {
      console.error(e)
    }
    redirectToGradooLogin()
  }
})

const gradooUri = process.env.REACT_APP_GRADOO_GRAPHQL_URL as string

export const gradooAuthLink = setContext(async (_, { headers }) => {
  try {
    const tokens = await getValidGradooTokens()

    if (!tokens) {
      throw new Error()
    }

    const { token } = tokens

    return {
      headers: {
        ...headers,
        Authorization: token ? `JWT ${token}` : ''
      }
    }
  } catch (e) {
    if (!isProd()) {
      console.error(e)
    }
    redirectToGradooLogin()
  }
})

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        collagesPhotos: relayStylePagination(['collagesAlbum']),
        quotesInstances: relayStylePagination([
          'moduleInstance',
          'orderBy'
        ]),
        rankingsQuestions: relayStylePagination(['moduleInstance'])
      }
    }
  }
})

const gradooLink = createUploadLink({
  uri: gradooUri
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_GRAPHQL_WS_URL!,
    on: {
      connected: () => console.log('GraphQLWsLink connected'),
      closed: () => console.log('GraphQLWsLink closed'),
      error: error => console.error('GraphQLWsLink error', error)
    }
  })
)

const httpLink = ApolloLink.split(
  operation => operation.getContext().client === 'gradoo',
  ApolloLink.from([
    gradooAuthLink,
    gradooLink as unknown as ApolloLink
  ]),
  ApolloLink.from([layoutCreatorAuthLink, layoutCreatorLink])
)

const terminatingLink: ApolloLink = split(
  ({ query }: Operation) => {
    const mainDefinition:
      | OperationDefinitionNode
      | FragmentDefinitionNode = getMainDefinition(query)
    return (
      mainDefinition.kind === 'OperationDefinition' &&
      mainDefinition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  cache,
  link: terminatingLink
})

export default client
