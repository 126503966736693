import { PublisherInterface } from '@chili-publish/publisher-interface'
import { FormikContextType, useFormikContext } from 'formik'
import { Dispatch, SetStateAction, useEffect } from 'react'

import ChiliColors from 'shared/components/Tools/services/ChiliColors'
import ChiliShapes from 'shared/components/Tools/services/ChiliShapes'
import {
  ChangeFrameProps,
  Frame,
  FrameSizeFields
} from 'shared/components/Tools/types'
import CMYK from 'shared/entities/CMYK'
import RGB from 'shared/entities/RGB'
import { ColorTypes } from 'shared/types/global'
import Button from 'shared/ui/Button_2'
import Flex from 'shared/ui/Flex'

import ChangeColorPanel from '../ChangeColorPanel'
import FrameColors from '../FrameColors'
import { SecondLevelPanels } from '../FrameControls'
import FrameSize from '../FrameSize'
import ChangeShapeTypePanel from './components/ChangeShapeTypePanel'
import SelectedShape from './components/SelectedShape'

const shapesApi = ChiliShapes.getInstance()
const colorsApi = ChiliColors.getInstance()

type ShapeControlProps = {
  publisherConnector: PublisherInterface
  frame: Frame
  changeFrameProps: ChangeFrameProps
  secondLevelPanel: SecondLevelPanels | null
  setSecondLevelPanel: Dispatch<
    SetStateAction<SecondLevelPanels | null>
  >
}

export const SHAPE_SIZE_FIELDS: FrameSizeFields[] = [
  FrameSizeFields.height,
  FrameSizeFields.width
]

const ShapeControls: React.FC<ShapeControlProps> = ({
  publisherConnector,
  frame,
  changeFrameProps,
  secondLevelPanel,
  setSecondLevelPanel
}) => {
  const {
    values: formValues,
    setFieldValue
  }: FormikContextType<any> = useFormikContext()

  useEffect(() => {
    if (formValues?.color !== undefined) {
      setFieldValue('palletteColor', formValues?.color)
    }
  }, [formValues.color])

  const updateShapeColor = async () => {
    const currentColor = await colorsApi.getColorByPath(
      frame.fillColor
    )

    let cmyk: CMYK = currentColor?.color as CMYK

    if (currentColor?.type === ColorTypes.rgb) {
      cmyk = (currentColor.color as RGB).toCMYK()
    }

    setFieldValue('color', cmyk)
  }

  const changeShapeColor = async (newColor: CMYK) => {
    const colorSelector = frame.fillColor

    await colorsApi.changeColor(colorSelector, newColor)
  }

  const deleteSelectedFrame = () => {
    publisherConnector.executeFunction(
      'document.selectedFrame',
      'Delete'
    )
  }

  useEffect(() => {
    updateShapeColor()
  }, [frame])

  useEffect(() => {
    if (!formValues.palletteColor) {
      return
    }

    const timeout = setTimeout(() => {
      changeShapeColor(formValues.palletteColor)
    }, 300)

    return () => {
      clearTimeout(timeout)
    }
  }, [formValues.palletteColor])

  const handleFrameColorClicked = () => {
    setTimeout(() => {
      setSecondLevelPanel(SecondLevelPanels.color)
    }, 300)
  }

  return (
    <Flex
      direction="column"
      height={'100%'}
      justifyContent="space-between"
    >
      <Flex direction="column">
        <FrameSize
          frame={frame}
          frameApi={shapesApi}
          changeFrameProps={changeFrameProps}
        />

        <SelectedShape onClick={setSecondLevelPanel} />
        {secondLevelPanel === SecondLevelPanels.shapeType && (
          <ChangeShapeTypePanel
            onBackButtonClick={() => setSecondLevelPanel(null)}
          />
        )}

        <Flex marginTop={48} columnGap={10}>
          <div>
            <FrameColors
              onClick={handleFrameColorClicked}
              labelId="Panel.field.label.shapeColor"
            />

            {secondLevelPanel === SecondLevelPanels.color && (
              <ChangeColorPanel
                onBackButtonClick={() => setSecondLevelPanel(null)}
              />
            )}
          </div>
        </Flex>
      </Flex>

      <Flex direction="column" rowGap={6} marginTop={16}>
        <Button
          textId="Panel.button.deleteShape"
          onPress={deleteSelectedFrame}
          intent="destructive"
          minWidth={242}
          size="sm"
        />
      </Flex>
    </Flex>
  )
}

export default ShapeControls
