import { useQuery } from '@apollo/client'
import { DragOverlay } from '@dnd-kit/core'
import React, { useContext, useMemo, useState } from 'react'

import { MODULE_INSTANCE_USER_SUMMARY_RESULT } from 'data/gradoo/queries/modules'
import {
  PROFILE_PAGE_PHOTO_CATEGORIES,
  PROFILE_PAGE_QUESTIONS
} from 'data/gradoo/queries/profile'
import { EditorContext } from 'screens/YearbookEditor'
import {
  Draggable,
  useContentDnd
} from 'screens/YearbookEditor/providers/ContentDndProvider'
import { Ids } from 'screens/YearbookEditor/providers/ContentDndProvider/types'
import { UserSummaryFragment } from 'shared/types/gradoo/graphql'
import Flex from 'shared/ui/Flex'
import SearchInput from 'shared/ui/SearchInput'

import { PanelProps, Panels } from '../../types'
import ProfileItem from './ProfileItem'
import Skeleton from './Skeleton'

type ProfilePagePanelProps = PanelProps & {
  moduleInstanceId: string
}

const ProfilePagePanel: React.FC<ProfilePagePanelProps> = ({
  moduleInstanceId,
  push
}) => {
  const { isWorkspaceRendered } = useContext(EditorContext)
  const [search, setSearch] = useState('')

  const { draggingContent } = useContentDnd()

  const {
    data: moduleInstanceResult,
    loading: moduleInstanceResultLoading
  } = useQuery(MODULE_INSTANCE_USER_SUMMARY_RESULT, {
    skip: !moduleInstanceId,
    variables: {
      id: moduleInstanceId as string
    },
    context: { client: 'gradoo' }
  })

  const usersSummary = useMemo(() => {
    return (
      (moduleInstanceResult?.moduleInstance?.profilePageSetup
        ?.usersSummary as UserSummaryFragment[]) || []
    )
  }, [
    moduleInstanceResult?.moduleInstance?.profilePageSetup
      ?.usersSummary
  ])

  const filteredUsersSummary = useMemo(() => {
    return usersSummary.filter(
      userSummary =>
        userSummary.userGroup.user.firstName
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase()) ||
        userSummary.userGroup.user.lastName
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase())
    )
  }, [usersSummary, search])

  const {
    data: photoCategoriesData,
    loading: photoCategoriesLoading
  } = useQuery(PROFILE_PAGE_PHOTO_CATEGORIES, {
    skip: !moduleInstanceId,
    variables: {
      moduleInstance: moduleInstanceId as string
    },
    context: { client: 'gradoo' }
  })

  const totalImages =
    photoCategoriesData?.profilePagePhotoCategories?.edges.length || 0

  const { data: questionsData, loading: questionsLoading } = useQuery(
    PROFILE_PAGE_QUESTIONS,
    {
      skip: !moduleInstanceId,
      variables: {
        moduleInstance: moduleInstanceId as string
      },
      context: { client: 'gradoo' }
    }
  )

  const totalQuestions =
    questionsData?.profilePageQuestions?.edges.length || 0

  const loading =
    !isWorkspaceRendered ||
    moduleInstanceResultLoading ||
    questionsLoading ||
    photoCategoriesLoading

  return (
    <Flex direction="column">
      <SearchInput
        value={search}
        onChange={setSearch}
        marginBottom={26}
      />
      <Flex direction="column" rowGap={16}>
        {loading ? (
          <Skeleton />
        ) : (
          filteredUsersSummary.map(userSummary => {
            if (!userSummary) {
              return null
            }

            return (
              <Draggable
                key={userSummary.userGroup.id}
                id={userSummary.userGroup.id}
                type={Ids.profile}
                onDragStart={() =>
                  draggingContent.setProfile(userSummary)
                }
                onDragEnd={() => draggingContent.setProfile(null)}
              >
                <ProfileItem
                  key={userSummary.userGroup.id}
                  withArrow
                  withBorder
                  userSummary={userSummary}
                  totalImages={totalImages}
                  totalQuestions={totalQuestions}
                  onButtonClick={() =>
                    push({
                      panel: Panels.profileDetails,
                      title: `${userSummary.userGroup.user.firstName} ${userSummary.userGroup.user.lastName}`,
                      props: {
                        moduleInstanceId,
                        userGroupId: userSummary.userGroup.id
                      }
                    })
                  }
                />
              </Draggable>
            )
          })
        )}
      </Flex>

      <DragOverlay>
        <ProfileItem
          userSummary={draggingContent.profile}
          totalImages={totalImages}
          totalQuestions={totalQuestions}
          onButtonClick={() =>
            push({
              panel: Panels.profileDetails,
              title: `${draggingContent.profile.userGroup.user.firstName} ${draggingContent.profile.userGroup.user.lastName}`,
              props: {
                moduleInstanceId,
                userGroupId: draggingContent.profile.userGroup.id
              }
            })
          }
        />
      </DragOverlay>
    </Flex>
  )
}

export default ProfilePagePanel
