import { useQuery } from '@apollo/client'
import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'

import {
  GET_PROJECT_DOCUMENTS,
  GET_PROJECT_DOCUMENTS_PREVIEWS
} from 'data/layoutcreator/queries/documents'
import { GET_PROJECT_META } from 'data/layoutcreator/queries/projects'
import { CoverTypes } from 'screens/Home/components/NewProjectModal/components/CoverType'
import { getProjectSyncHash } from 'screens/YearbookPages'
import DndPages from 'screens/YearbookPages/components/DndPages'
import PagePreviewModal from 'screens/YearbookPages/components/PagePreviewModal'
import PagesMigrationModal from 'screens/YearbookPages/components/PagesMigrationModal'
import { usePagesMigration } from 'screens/YearbookPages/components/hooks/usePagesMigration'
import {
  useLostConnectionListener,
  useMutation,
  useOthersMapped,
  useStorage,
  useUpdateMyPresence
} from 'shared/context/project-room'
import { LiveUser } from 'shared/types/global'
import { ProjectDocument } from 'shared/types/layoutcreator/graphql'

type PageListProps = {
  groupId: string
  projectId: string
}

const PageList: React.FC<PageListProps> = ({
  groupId,
  projectId
}) => {
  const { data: projectMetaData } = useQuery(GET_PROJECT_META, {
    variables: {
      groupId,
      projectId
    }
  })

  const updateMyPresence = useUpdateMyPresence()

  const othersMapped = useOthersMapped(other => ({
    info: other.presence.info,
    editingPageId: other.presence.editingPageId
  }))

  const syncHash = useStorage(root => root.syncHash)

  const setSyncHash = useMutation(({ storage }, syncHash: string) => {
    storage.set('syncHash', syncHash)
  }, [])

  const didMountSync = useRef(false)
  const didMountProjects = useRef(false)

  useLostConnectionListener(event => {
    switch (event) {
      case 'lost':
        toast.loading('Still trying to reconnect...')
        break

      case 'restored':
        toast.dismiss()
        toast.success('Successfully reconnected again!')
        break

      case 'failed':
        toast.dismiss()
        toast.error('Could not restore the connection')
        break
    }
  })

  const editingLiveUsersMap: { [key: string]: LiveUser } =
    othersMapped
      .filter(other => !!other[1].editingPageId)
      .reduce(
        (obj, other) => ({
          ...obj,
          [other[1].editingPageId as string]: {
            ...other[1].info,
            index: other[0]
          }
        }),
        {}
      )

  const projectVars = {
    groupId,
    projectId
  }

  const [items, setItems] = useState<ProjectDocument[]>([])
  const {
    loading,
    error,
    data,
    refetch: refetchProjects
  } = useQuery(GET_PROJECT_DOCUMENTS, {
    variables: projectVars
  })

  const [isMigrationModalOpen, setIsMigrationModalOpen] =
    useState(false)
  const {
    isLoading: isMigrationLoading,
    isError: isMigrationError,
    isSuccess: isMigrationSuccess,
    loadingPercent: migrationLoadingPercent,
    isOutdated,
    migrate
  } = usePagesMigration({
    documentsData: data,
    refetch: async () => {
      await refetchProjects()
    }
  })

  useEffect(() => {
    if (isOutdated) {
      setIsMigrationModalOpen(true)
    }
  }, [isOutdated])

  const { data: previewsData } = useQuery(
    GET_PROJECT_DOCUMENTS_PREVIEWS,
    {
      variables: projectVars
    }
  )

  useEffect(() => {
    if (data?.projectDocuments) {
      if (!didMountProjects.current) {
        //to prevent any setSyncHash if the projects is not loaded yet
        didMountProjects.current = true
      }

      setItems(data.projectDocuments)
    }
  }, [data])

  const itemsHash = getProjectSyncHash(items.map(item => item.id))

  //itemHash is string which join all project ids (for example 'id1|id2|id3')
  //if itemsHash has changed means the user has added/removed or changed project order
  //so we trigger to update the syncHash on liveblocks
  useEffect(() => {
    if (
      !itemsHash ||
      !didMountSync.current ||
      syncHash === null ||
      !didMountProjects.current
    ) {
      return
    }
    if (syncHash !== itemsHash) {
      setSyncHash(itemsHash)
    }
  }, [itemsHash])

  //if syncHash is updated and is different from current user itemHash we trigger to refetch the projects
  useEffect(() => {
    if (!didMountSync.current) {
      didMountSync.current = true
      return
    }
    if (syncHash === null || !groupId || !itemsHash) {
      return
    }

    if (syncHash !== itemsHash) {
      refetchProjects()
    }
  }, [syncHash])

  const onDraggingProject = (draggingPreviewUrl: string | null) => {
    if (!didMountSync.current) {
      return
    }
    updateMyPresence({ draggingPreviewUrl })
  }

  return (
    <>
      {loading && <p>Loading...</p>}
      {error && <p>Error :(</p>}
      <DndPages
        items={items}
        customPreviews={
          previewsData?.getProjectDocumentsPreviews || []
        }
        editingLiveUsersMap={editingLiveUsersMap}
        setItems={setItems}
        onDraggingProject={onDraggingProject}
        groupId={groupId}
        projectId={projectId}
        coverType={
          projectMetaData?.getProjectMeta
            .coverType as CoverTypes | null
        }
      />

      <PagePreviewModal
        projectId={projectVars.projectId}
        groupId={projectVars.groupId}
      />

      <PagesMigrationModal
        isOpen={isMigrationModalOpen}
        closeModal={() => setIsMigrationModalOpen(false)}
        isError={isMigrationError}
        isSuccess={isMigrationSuccess}
        isLoading={isMigrationLoading}
        onStartUpdateClick={migrate}
        loadingPercent={migrationLoadingPercent}
      />
    </>
  )
}

export default PageList
