import styled from 'styled-components'

import { ViewTypes } from '../../../../enum'

export const MetaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  font-size: 12px;
  user-select: none;
`

export const PageNumber = styled.span<{ view: string }>`
  margin-left: ${({ view }) => (view === ViewTypes.grid ? '4px' : 0)};
  margin-right: ${({ view }) =>
    view === ViewTypes.list ? '16px' : 0};
  color: ${({ theme }) => theme.color.base.c9};
`

export const PageType = styled.span`
  margin-right: 4px;
  color: ${({ theme }) => theme.color.base.c6};
`
