import { useMutation } from '@apollo/client'
import { PublisherInterface } from '@chili-publish/publisher-interface'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import ChiliDocument from 'shared/components/Tools/services/ChiliDocument'
import { SAVE_PAGE_PREVIEW } from 'data/layoutcreator/mutations/documents'
import { SAVE_DOCUMENT } from 'data/layoutcreator/mutations/projects'
import { GET_PROJECT_DOCUMENTS_PREVIEWS } from 'data/layoutcreator/queries/documents'
import { fetchImageFile } from 'shared/helpers/file'
import { getBase64ImageUrl } from 'shared/helpers/url'
import useFetchImageBlob from 'shared/components/PageList/components/hooks/useFetchImageBlob'
import { ImageFormats } from 'shared/types/global'
import { usePreviewsCache } from './usePreviewsCache'

const documentApi = ChiliDocument.getInstance()

export const useEditorSave = ({
  publisherConnector,
  backPath,
  groupId,
  projectId,
  pageId
}: {
  publisherConnector: PublisherInterface | null
  backPath: string
  groupId: string
  projectId: string
  pageId: string
}) => {
  const { formatMessage } = useIntl()
  const [isSaveDocLoading, setIsSaveDocLoading] = useState(false)
  const navigate = useNavigate()
  const { cachePreview } = usePreviewsCache()

  const fetchImageBlob = useFetchImageBlob()

  const [savePagePreview] = useMutation(SAVE_PAGE_PREVIEW, {
    refetchQueries: [
      {
        query: GET_PROJECT_DOCUMENTS_PREVIEWS,
        variables: {
          groupId,
          projectId
        }
      }
    ]
  })

  const [saveDocument] = useMutation(SAVE_DOCUMENT)

  const unselectFrames = async () =>
    publisherConnector?.executeFunction(
      'document.selectedFrames',
      'Clear'
    )

  const handleSave = async () => {
    await saveDoc()
    await unselectFrames()
    await savePreview()
  }

  const savePreview = async () => {
    const base64 = await documentApi.getSnapshot()

    if (base64) {
      cachePreview(pageId, base64)
    }

    const url = getBase64ImageUrl(base64, ImageFormats.png)

    if (url) {
      await savePagePreview({
        variables: {
          groupId,
          projectId,
          pageId,
          image: await fetchImageFile(url)
        }
      })
    }
    navigate(backPath)
  }

  const saveDoc = async () => {
    const isDirty = await publisherConnector?.getDirtyState()
    if (!isDirty) {
      return false
    }

    const savingDocToast = toast.loading(
      formatMessage({ id: 'Toasts.saving.document' })
    )
    const tempXml = (await publisherConnector?.executeFunction(
      'document',
      'GetTempXML'
    )) as string

    if (pageId) {
      setIsSaveDocLoading(true)
      const resp = await saveDocument({
        variables: {
          projectId,
          documentId: pageId,
          xml: tempXml
        }
      })
      setIsSaveDocLoading(false)
      toast.remove(savingDocToast)
      return resp.data ? resp.data.saveDocument : false
    }
    toast.remove(savingDocToast)
    return false
  }

  return {
    handleSave,
    isLoading: isSaveDocLoading
  }
}
