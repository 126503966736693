import { useQuery } from '@apollo/client'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import Spinner from 'shared/ui/Spinner'
import useAuth from 'data/gradoo/hooks/useAuth'
import { GET_YEARBOOK_PARAMS } from 'data/layoutcreator/queries/erp'

import { formatDownloadUrl } from './utils/format-download-url'

const Link = styled.a`
  font-size: 12px;
  line-height: 16px;
  text-decoration: underline;
`

const DownloadTemplateLink = ({
  orderNumber
}: {
  orderNumber: string
}) => {
  const { formatMessage } = useIntl()

  const {
    authUserGroupYear,
    authUserInstituteInternalId,
    loading: isAuthLoading
  } = useAuth()

  const {
    data,
    loading: areYearbookParamsLoading,
    error
  } = useQuery(GET_YEARBOOK_PARAMS, {
    variables: {
      orderNumber,
      year: authUserGroupYear as number,
      customerNumber: authUserInstituteInternalId as string
    },
    skip: !authUserGroupYear || !authUserInstituteInternalId
  })

  if (areYearbookParamsLoading || isAuthLoading) {
    return <Spinner size={16} />
  }

  if (error || !data?.getYearbookParams) {
    return (
      <Link href="mailto:support@gradoo.com">
        {formatMessage({ id: 'Project.print.contact.for.template' })}
      </Link>
    )
  }

  const url = formatDownloadUrl({ ...data?.getYearbookParams })

  return (
    <Link href={url} target="_blank">
      {formatMessage(
        {
          id: 'Project.print.data.download.template'
        },
        {
          pages: data.getYearbookParams.pages.total
        }
      )}
    </Link>
  )
}

export default DownloadTemplateLink
