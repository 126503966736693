import chroma from 'chroma-js'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import editSrc from 'shared/assets/images/edit.svg'
import viewSrc from 'shared/assets/images/view.svg'
import Flex from 'shared/ui/Flex'
import Text from 'shared/ui/Text'
import {
  isPagePreviewModalOpenVar,
  pagePreviewNumberVar,
  pagePreviewPageIdVar,
  pagePreviewTypeVar,
  pagePreviewUrlVar
} from 'data/store/page-preview-modal'
import Tool from 'shared/components/PageList/components/DndPages/components/Page/components/PageTools/components/Tool'
import { ProjectDocument } from 'shared/types/layoutcreator/graphql'

type ToolType = {
  id: ToolsEnum
  icon: string
}

enum ToolsEnum {
  edit = 'edit',
  view = 'view'
}

export const TOOLS: ToolType[] = [
  { id: ToolsEnum.edit, icon: editSrc },
  { id: ToolsEnum.view, icon: viewSrc }
]

const CoverImage = styled.img`
  display: block;
  width: 100%;
`

const Overlay = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  direction: 'column',
  rowGap: 8
})`
  opacity: 0;
  position: absolute;
  inset: 0;
  width: 100%;
  background: ${({ theme }) =>
    chroma(theme.color.base.c7).alpha(0.9).css()};
  color: ${({ theme }) => theme.color.base.c7};
  font-weight: 600;
  font-size: 10px;
`

const CoverContainer = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:hover ${Overlay} {
    opacity: 1;
  }
`

type CoverPageProps = {
  document: ProjectDocument
}
export default function CoverPage({ document }: CoverPageProps) {
  const navigate = useNavigate()

  const actions: Record<ToolsEnum, (doc: ProjectDocument) => void> = {
    [ToolsEnum.edit]: doc => navigate(`editor/${doc.id}`),
    [ToolsEnum.view]: doc => {
      isPagePreviewModalOpenVar(true)
      pagePreviewUrlVar(doc.previewUrl)
      pagePreviewPageIdVar(doc.id)
      pagePreviewNumberVar(0)
      pagePreviewTypeVar(
        doc.name.includes('inside') ? 'Cover.inside' : 'Cover.outside'
      )
    }
  }

  return (
    <Flex direction="column" key={document.id} width="100%">
      <CoverContainer>
        <CoverImage src={document.previewUrl} alt={document.name} />
        <Overlay>
          <Flex columnGap={16}>
            {TOOLS.map(tool => (
              <Tool
                data-testid={`CoverPageTool:${document.id}:${tool.id}`}
                key={tool.id}
                onClick={() => actions[tool.id](document)}
              >
                <img
                  src={tool.icon}
                  alt={tool.id}
                  style={{ width: 64 }}
                />
              </Tool>
            ))}
          </Flex>
        </Overlay>
      </CoverContainer>
      <Text
        tag="p"
        align="center"
        id={
          document.name.includes('inside')
            ? 'Cover.inside'
            : 'Cover.outside'
        }
        color={theme => theme.color.base.c7}
        weight={600}
        size={12}
        marginTop={24}
      />
    </Flex>
  )
}
