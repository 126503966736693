import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { SMALL_SCREEN } from 'shared/styles/constants'
import { HEADER_HEIGHT } from 'shared/styles/constants'
import CustomScrollbars, {
  FixedHeader,
  FixedHeaderWrapper
} from 'shared/ui/SimpleBar'

import usePanelNavigation, {
  PathItem
} from '../../hooks/usePanelNavigation'
import { PanelTypes } from '../PanelContainer'
import PanelFirstLevelTitle from '../PanelFirstLevelTitle'
import PanelPagination from '../PanelPagination'
import PanelSecondLevelTitle from '../PanelSecondLevelTitle'
import CollagePanel from './components/CollagePanel'
import CollagesListPanel from './components/CollagesListPanel'
import ContentSetsPanel from './components/ContentSetsPanel'
import CustomPagePanel from './components/CustomPagePanel'
import CustomPagesPanel from './components/CustomPagesPanel'
import PrewordPanel from './components/PrewordPanel'
import ProfileAnswerQuestionPanel from './components/ProfileAnswerQuestionPanel'
import ProfileDetailsPanel from './components/ProfileDetailsPanel'
import ProfilePagePanel from './components/ProfilePagePanel'
import QuotesPanel from './components/QuotesPanel'
import RankingsPanel from './components/RankingsPanel'
import ReportPanel from './components/ReportPanel'
import ReportsPanel from './components/ReportsPanel'
import {
  HeaderTypes,
  PanelProps,
  Panels,
  PushWithTitle
} from './types'

const Header = styled.div``

const SmallScreenContainer = styled(OverlayScrollbarsComponent)`
  min-width: 260px;
  height: calc(100vh - ${HEADER_HEIGHT}px - 36px);
  max-height: calc(100vh - ${HEADER_HEIGHT}px - 36px);
  padding: 0px 16px 24px 16px;
`

const PANELS_MAP: Record<
  Panels,
  {
    Component: React.FC<PanelProps & any>
    titleId?: string
    headerType?: HeaderTypes
  }
> = {
  [Panels.contentSets]: {
    Component: ContentSetsPanel,
    titleId: 'Panel.title.fillWithContent'
  },
  [Panels.collagesList]: {
    Component: CollagesListPanel,
    titleId: 'Panel.title.collages'
  },
  [Panels.collage]: {
    Component: CollagePanel
  },
  [Panels.quotes]: {
    Component: QuotesPanel,
    titleId: 'Panel.title.quotes'
  },
  [Panels.rankings]: {
    Component: RankingsPanel,
    titleId: 'Panel.title.rankings'
  },
  [Panels.preword]: {
    Component: PrewordPanel,
    titleId: 'Panel.title.preword'
  },
  [Panels.profilePage]: {
    Component: ProfilePagePanel,
    titleId: 'Panel.title.profilePage'
  },
  [Panels.profileDetails]: {
    Component: ProfileDetailsPanel
  },
  [Panels.profileAnswerQuestion]: {
    Component: ProfileAnswerQuestionPanel,
    titleId: 'Panel.title.profileAnswerQuestion',
    headerType: HeaderTypes.secondLevelTitle
  },
  [Panels.reports]: {
    Component: ReportsPanel,
    titleId: 'Panel.title.reports'
  },
  [Panels.report]: {
    Component: ReportPanel
  },
  [Panels.customPages]: {
    Component: CustomPagesPanel,
    titleId: 'Panel.title.customPage'
  },
  [Panels.customPage]: {
    Component: CustomPagePanel
  }
}

type RightPanelProps = {
  gradooToolRef?: React.RefObject<HTMLDivElement>
}

const RightPanel: React.FC<RightPanelProps> = ({ gradooToolRef }) => {
  const { formatMessage } = useIntl()

  const panelRef = useRef<HTMLDivElement>(null)
  const [isSmallScreen, setIsSmallScreen] = useState(
    window.innerWidth < SMALL_SCREEN
  )

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setIsSmallScreen(window.innerWidth < SMALL_SCREEN)
    })
    if (gradooToolRef?.current) {
      resizeObserver.observe(gradooToolRef.current)
    }
    return () => {
      resizeObserver.disconnect()
    }
  }, [gradooToolRef])

  const { path, push, current, goToPrev, goToIndex } =
    usePanelNavigation<Panels>([
      {
        panel: Panels.contentSets,
        title: formatMessage({
          id: PANELS_MAP[Panels.contentSets].titleId
        }),
        props: {}
      }
    ])

  const pushWithTitle: PushWithTitle = ({
    panel,
    title: customTitle,
    props
  }) => {
    const title =
      customTitle ||
      formatMessage({ id: PANELS_MAP[panel].titleId }) ||
      ''

    push({
      panel,
      title,
      props: props || {}
    })
  }

  const currentPanelDetails = PANELS_MAP[current.panel as Panels]
  const PanelComponent = currentPanelDetails.Component

  const renderHeader = (
    path: PathItem<Panels>[],
    current: PathItem<Panels>,
    headerType?: HeaderTypes
  ): JSX.Element => {
    if (headerType) {
      switch (headerType) {
        case HeaderTypes.firstLevelTitle:
          return (
            <PanelFirstLevelTitle>
              {current.title}
            </PanelFirstLevelTitle>
          )
        case HeaderTypes.secondLevelTitle:
          return (
            <PanelSecondLevelTitle
              titleId={
                PANELS_MAP[current.panel as Panels].titleId || ''
              }
              onClick={goToPrev}
              margin={false}
            />
          )
        case HeaderTypes.pagination:
          return (
            <PanelPagination
              path={path as PathItem<Panels>[]}
              onClick={goToIndex}
            />
          )
      }
    }

    if (path.length === 1) {
      return renderHeader(path, current, HeaderTypes.firstLevelTitle)
    }

    if (path.length === 2) {
      return renderHeader(path, current, HeaderTypes.secondLevelTitle)
    }

    if (path.length > 2) {
      return renderHeader(path, current, HeaderTypes.pagination)
    }

    return <></>
  }

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < SMALL_SCREEN)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (isSmallScreen) {
    return (
      <SmallScreenContainer>
        <FixedHeaderWrapper>
          <FixedHeader type={PanelTypes.right}>
            {renderHeader(
              path as PathItem<Panels>[],
              current as PathItem<Panels>,
              currentPanelDetails.headerType
            )}
          </FixedHeader>
        </FixedHeaderWrapper>

        <PanelComponent {...current.props} push={pushWithTitle} />
      </SmallScreenContainer>
    )
  }

  return (
    <CustomScrollbars
      panelType={PanelTypes.right}
      ref={panelRef}
      fixedHeader={
        <Header>
          {renderHeader(
            path as PathItem<Panels>[],
            current as PathItem<Panels>,
            currentPanelDetails.headerType
          )}
        </Header>
      }
    >
      {current.panel === Panels.customPage ? (
        <PanelComponent {...current.props} push={pushWithTitle} />
      ) : (
        <PanelComponent {...current.props} push={pushWithTitle} />
      )}
    </CustomScrollbars>
  )
}

export default RightPanel
