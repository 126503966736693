import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ButtonIntents } from 'shared/ui/Button'
import Flex from 'shared/ui/Flex'
import ModalButton from 'shared/ui/ModalButton'
import IslandModal, {
  IslandModalProps
} from 'shared/components/modals/IslandModal'

const Description = styled.p`
  color: ${({ theme }) => theme.color.base.c5};
  text-align: center;
`

type ConfirmDeleteModalProps = Pick<
  IslandModalProps,
  'isOpen' | 'closeModal'
> & {
  onConfirm: () => void
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  isOpen,
  closeModal,
  onConfirm
}) => {
  const { formatMessage } = useIntl()

  const handleConfirm = async () => {
    closeModal()
    onConfirm()
    return
  }

  const handleCancel = () => {
    closeModal()
  }

  return (
    <IslandModal
      isOpen={isOpen}
      closeModal={closeModal}
      width={345}
      title={formatMessage({
        id: `Project.pages.delete.modal.title`
      })}
    >
      <Description>
        {formatMessage({
          id: 'Project.pages.delete.modal.description'
        })}
      </Description>

      <Flex marginTop={48} columnGap={7}>
        <ModalButton
          labelId="Actions.cancel"
          isFullWidth
          intent={ButtonIntents.neutral}
          onClick={handleCancel}
        />
        <ModalButton
          labelId="Project.pages.delete.modal.confirm"
          intent={ButtonIntents.danger}
          isFullWidth
          onClick={handleConfirm}
        />
      </Flex>
    </IslandModal>
  )
}

export default ConfirmDeleteModal
