import { useQuery } from '@apollo/client'
import InfiniteScroll from 'react-infinite-scroll-component'

import { RANKINGS_QUESTIONS } from 'data/gradoo/queries/rankings'
import { RankingsQuestionFragment } from 'shared/types/gradoo/graphql'
import Flex from 'shared/ui/Flex'

import Skeleton from './Skeleton'
import RankingsItem from './components/RankingsItem'

const PAGE_SIZE = 20
type RankingsPanelProps = {
  moduleInstanceId?: string
}

const RankingsPanel: React.FC<RankingsPanelProps> = ({
  moduleInstanceId
}) => {
  const {
    data: rankingsQuestionsData,
    loading,
    fetchMore
  } = useQuery(RANKINGS_QUESTIONS, {
    skip: !moduleInstanceId,
    variables: {
      moduleInstance: moduleInstanceId,
      first: PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true,
    context: { client: 'gradoo' }
  })

  const rankingsQuestions: RankingsQuestionFragment[] =
    (rankingsQuestionsData?.rankingsQuestions?.edges.map(
      edge => edge?.node
    ) as RankingsQuestionFragment[]) || []

  const hasNextPage =
    rankingsQuestionsData?.rankingsQuestions?.pageInfo.hasNextPage ||
    false
  const endCursor =
    rankingsQuestionsData?.rankingsQuestions?.pageInfo.endCursor

  const onLoadMoreRankings = async () => {
    hasNextPage &&
      (await fetchMore({
        variables: {
          moduleInstance: moduleInstanceId,
          after: endCursor
        }
      }))
  }

  return (
    <Flex direction="column">
      {loading && rankingsQuestions.length === 0 ? (
        <Skeleton />
      ) : (
        <InfiniteScroll
          dataLength={rankingsQuestions.length}
          next={onLoadMoreRankings}
          hasMore={hasNextPage}
          loader={<Skeleton />}
          scrollThreshold={0.8}
          height={window.innerHeight}
        >
          <Flex direction="column" rowGap={22}>
            {rankingsQuestions.map(rankingsQuestion => (
              <RankingsItem
                data-testid={`RankingsItem:${rankingsQuestion.id}`}
                key={rankingsQuestion.id}
                rankingsQuestion={rankingsQuestion}
              />
            ))}
          </Flex>
        </InfiniteScroll>
      )}
    </Flex>
  )
}

export default RankingsPanel
