import { PublisherInterface } from '@chili-publish/publisher-interface'

import { Frame, Path, Point, Shape, ShapeTypes } from '../../types'
import { ChiliFramesRegister } from '../ChiliFramesRegister'
import { ShapesService } from '../types'
import CUSTOM_SHAPES from './customShapes'
import { Coordinates } from './types'

type InitOptions = {
  publisherConnector: PublisherInterface
}

type ShapesMap = Record<ShapeTypes, Shape>

const DEFAULT_SHAPES_INDEXES = {
  [ShapeTypes.rect]: 1,
  [ShapeTypes.triangle]: 2,
  [ShapeTypes.ellipse]: 4
}

const DEFAULT_SHAPES_PATH = 'document.workSpace.defaultShapes'

export default class ChiliShapes
  extends ChiliFramesRegister
  implements ShapesService<InitOptions>
{
  private static instance: ChiliShapes
  private shapesMap!: ShapesMap

  static getInstance(): ChiliShapes {
    if (!ChiliShapes.instance) {
      ChiliShapes.instance = new ChiliShapes()
    }

    return ChiliShapes.instance
  }

  private generateWorkspaceShapePath = (
    id: number | string
  ): string => {
    return `${DEFAULT_SHAPES_PATH}[${id}]`
  }

  private getWorkspaceShape(id: number | string): Promise<Shape> {
    return this.publisherConnector?.getObject(
      this.generateWorkspaceShapePath(id)
    ) as Promise<Shape>
  }

  private async initCustomShapes() {
    const newShape = (await this.publisherConnector?.executeFunction(
      DEFAULT_SHAPES_PATH,
      'Add'
    )) as Shape

    for (const [type, points] of Object.entries(CUSTOM_SHAPES)) {
      await this.addNewLinearPath(newShape.paths, points)
      this.shapesMap[type as ShapeTypes] = newShape
    }
  }

  private async initDefaultShapes(): Promise<void> {
    const result = {} as ShapesMap

    for (const [type, index] of Object.entries(
      DEFAULT_SHAPES_INDEXES
    )) {
      await this.publisherConnector?.setProperty(
        `${this.generateWorkspaceShapePath(index)}.paths.first`,
        'name',
        type
      )
      result[type as ShapeTypes] = await this.getWorkspaceShape(index)
    }

    this.shapesMap = result
  }

  private async addNewLinearPath(
    listPath: string,
    points: Coordinates[]
  ) {
    const newPath = (await this.publisherConnector?.executeFunction(
      listPath,
      'Add'
    )) as Path

    for (const point of points) {
      const chiliProps = {
        x: point.x,
        y: point.y,
        controlRightX: point.x,
        controlRightY: point.y
      }

      const newPoint =
        (await this.publisherConnector?.executeFunction(
          newPath.points,
          'Add'
        )) as Point

      for (const [key, value] of Object.entries(chiliProps)) {
        await this.publisherConnector?.setProperty(
          `${newPath.points}[${newPoint.id}]`,
          key,
          `${value}mm`
        )
      }
    }

    return newPath
  }

  async init({ publisherConnector }: InitOptions) {
    if (!this.isInitialized) {
      this.publisherConnector = publisherConnector
      await this.initDefaultShapes()
      await this.initCustomShapes()
      this.isInitialized = true
    }
  }

  getShapeType(frame: Frame): ShapeTypes | null {
    return (frame.tag as ShapeTypes) || null
  }

  async changeShapeType(
    newType: ShapeTypes,
    path?: string
  ): Promise<void | null> {
    const defaultPath = 'document.selectedFrame'

    await this.publisherConnector?.setProperty(
      defaultPath,
      'tag',
      newType
    )

    await this.publisherConnector?.executeFunction(
      path || defaultPath,
      'SetPaths',
      this.shapesMap[newType].paths
    )
  }

  async applyShapeToSelectedFrame(shape: ShapeTypes): Promise<void> {
    const selectedShape = this.shapesMap[shape]

    this.publisherConnector?.executeFunction(
      'document.selectedFrame',
      'SetPaths',
      selectedShape.paths
    )
  }

  async setColor(path: string, colorPath: string): Promise<void> {
    await this.publisherConnector?.setProperty(
      path,
      'fillColor',
      colorPath
    )
    await this.publisherConnector?.setProperty(
      path,
      'borderColor',
      colorPath
    )
  }
}
