import styled from 'styled-components'

import { CoverTypes } from 'screens/Home/components/NewProjectModal/components/CoverType'
import Flex from 'shared/ui/Flex'
import Text from 'shared/ui/Text'

import { ReactComponent as LockIcon } from '../../assets/lock.svg'
import { InnerCoverPageTypes } from '../../enum'
import PageContainer, { PagePreview } from '../PageContainer'
import { ReactComponent as BookIcon } from './assets/book.svg'
import { useIntl } from 'react-intl'

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 22px;
`
type CoverPageProps = {
  type: InnerCoverPageTypes
  coverType: CoverTypes | null
  isEven?: boolean
}

const CoverPage: React.FC<CoverPageProps> = ({
  type,
  coverType,
  isEven = false
}) => {
  const { formatMessage } = useIntl()

  const getText = (coverType: CoverTypes | null) => {
    if (!coverType) {
      return ''
    }

    switch (coverType) {
      case CoverTypes.hard:
        return formatMessage({
          id: 'Pages.cover.description.hard'
        })
      case CoverTypes.soft:
        return formatMessage({
          id: 'Pages.cover.description.soft'
        })
      default:
        return ''
    }
  }

  return (
    <PageContainer
      place="cover-front"
      view="grid"
      isDragging={false}
      isDisabled={true}
      isEven={isEven}
    >
      <PagePreview place="cover-front" previewUrl={null}>
        <Content>
          <BookIcon />
          <Text
            size={10}
            text={getText(coverType)}
            align="center"
            color={theme => theme.color.base.c7}
            lineHeight={'14px'}
          />
        </Content>
      </PagePreview>

      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginTop={4}
      >
        <Text
          size={12}
          weight={600}
          color={theme => theme.color.base.c7}
          id={`Pages.cover.label.${type}`}
        />
        <LockIcon />
      </Flex>
    </PageContainer>
  )
}

export default CoverPage
