/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any
  /** The `Upload` scalar type represents a file upload. */
  Upload: any
}

export type AdminProject = {
  __typename?: 'AdminProject'
  deleted: Scalars['Boolean']
  id?: Maybe<Scalars['String']>
  isMigrationRunning: Scalars['Boolean']
  name: Scalars['String']
  pagePreviews: Array<ProjectDocument>
}

export type AdminProjectDetails = {
  __typename?: 'AdminProjectDetails'
  groupId: Scalars['String']
  migrations: Array<ImagesMigration>
  name: Scalars['String']
  pagesCount: Scalars['Float']
}

export enum AdsPlacement {
  AdsPlaced = 'ads_placed',
  PleaseAddAds = 'please_add_ads'
}

export type CmykColor = {
  __typename?: 'CMYKColor'
  c: Scalars['Float']
  k: Scalars['Float']
  m: Scalars['Float']
  y: Scalars['Float']
}

export type CmykColorInput = {
  c: Scalars['Float']
  k: Scalars['Float']
  m: Scalars['Float']
  y: Scalars['Float']
}

export type CategoryEntity = {
  __typename?: 'CategoryEntity'
  id: Scalars['String']
  name: Scalars['String']
  path: Scalars['String']
}

export type ChiliImage = {
  __typename?: 'ChiliImage'
  height: Scalars['Float']
  id: Scalars['String']
  name: Scalars['String']
  path: Scalars['String']
  resolution: Scalars['Float']
  width: Scalars['Float']
  xml: Scalars['String']
}

export enum Coloring {
  F = 'F',
  G = 'G',
  Sw = 'SW'
}

export type CustomAttributeValueInput = {
  booleanValue?: InputMaybe<Scalars['Boolean']>
  dateValue?: InputMaybe<Scalars['Float']>
  selectedValueId?: InputMaybe<Scalars['String']>
}

export type DeliveryAddressEntity = {
  __typename?: 'DeliveryAddressEntity'
  city: Scalars['String']
  companyOrAdditionalInfo?: Maybe<Scalars['String']>
  countryCode: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  streetAndNumber: Scalars['String']
  zip: Scalars['String']
}

export type DeliveryAddressInput = {
  city: Scalars['String']
  companyOrAdditionalInfo?: InputMaybe<Scalars['String']>
  countryCode: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  phoneNumber: Scalars['String']
  streetAndNumber: Scalars['String']
  zip: Scalars['String']
}

export type ErpCustomerEntity = {
  __typename?: 'ErpCustomerEntity'
  company: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
}

export type ExportDocument = {
  __typename?: 'ExportDocument'
  documentId: Scalars['String']
  error?: Maybe<Scalars['String']>
  isFinished: Scalars['Boolean']
  isStarted: Scalars['Boolean']
  isSucceeded: Scalars['Boolean']
  taskId: Scalars['String']
  url?: Maybe<Scalars['String']>
}

export type ExportProject = {
  __typename?: 'ExportProject'
  author?: Maybe<ExportProjectAuthor>
  error?: Maybe<Scalars['String']>
  finishedAt?: Maybe<Scalars['DateTime']>
  groupId: Scalars['String']
  id: Scalars['String']
  outputProfile: Scalars['String']
  pages: Array<ExportProjectPage>
  projectId: Scalars['String']
  retryFailedPages?: Maybe<Scalars['Float']>
  startedAt?: Maybe<Scalars['DateTime']>
  status: Scalars['String']
}

export type ExportProjectAuthor = {
  __typename?: 'ExportProjectAuthor'
  fullName: Scalars['String']
  id: Scalars['String']
}

export type ExportProjectPage = {
  __typename?: 'ExportProjectPage'
  error?: Maybe<ExportProjectPageError>
  finishedAt?: Maybe<Scalars['DateTime']>
  pageId: Scalars['String']
  pdfUrl?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['DateTime']>
  status: Scalars['String']
}

export type ExportProjectPageError = {
  __typename?: 'ExportProjectPageError'
  code?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
}

export type FixBrokenPagesResult = {
  __typename?: 'FixBrokenPagesResult'
  fixedCount: Scalars['Float']
  isOk: Scalars['Boolean']
}

export type FlagProjectUpdateInput = {
  avatarUrl: Scalars['String']
  groupId: Scalars['String']
  name: Scalars['String']
  projectId: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type Font = {
  __typename?: 'Font'
  family: Scalars['String']
  id: Scalars['String']
  name: Scalars['String']
  style: Scalars['String']
  svgBig?: Maybe<Scalars['String']>
  svgSmall?: Maybe<Scalars['String']>
}

export enum Formats {
  A4Landscape = 'a4_landscape',
  A4Portrait = 'a4_portrait',
  A5Landscape = 'a5_landscape',
  A5Portrait = 'a5_portrait',
  SquareBig = 'square_big',
  SquareSmall = 'square_small'
}

export type Image = {
  __typename?: 'Image'
  height: Scalars['Float']
  id: Scalars['String']
  imageQueryParams?: Maybe<Scalars['String']>
  resolution: Scalars['Float']
  size: Scalars['Float']
  width: Scalars['Float']
}

export type ImagesMigration = {
  __typename?: 'ImagesMigration'
  author?: Maybe<Scalars['String']>
  documents: Array<ImagesMigrationDocument>
  finishedAt?: Maybe<Scalars['DateTime']>
  groupId: Scalars['String']
  id: Scalars['String']
  projectId: Scalars['String']
  startedAt: Scalars['DateTime']
  status: Scalars['String']
  type: Scalars['String']
}

export type ImagesMigrationDocument = {
  __typename?: 'ImagesMigrationDocument'
  error?: Maybe<Scalars['String']>
  finishedAt?: Maybe<Scalars['DateTime']>
  id: Scalars['String']
  name: Scalars['String']
  order: Scalars['Float']
  startedAt?: Maybe<Scalars['DateTime']>
  status: Scalars['String']
  variables: Array<ImagesMigrationVariable>
}

export type ImagesMigrationVariable = {
  __typename?: 'ImagesMigrationVariable'
  error?: Maybe<Scalars['String']>
  freshExternalUrl?: Maybe<Scalars['String']>
  id: Scalars['String']
  name: Scalars['String']
  newValue?: Maybe<Scalars['String']>
  prevValue: Scalars['String']
  status: Scalars['String']
  type: Scalars['String']
}

export type Kpi = {
  __typename?: 'Kpi'
  activeGroups: Scalars['Float']
  pagesCreated: Scalars['Float']
  pagesExported: Scalars['Float']
  projectsCreated: Scalars['Float']
  projectsCreatedWithMinPagesCount: Scalars['Float']
}

export type LinkOrderResult = {
  __typename?: 'LinkOrderResult'
  errorType?: Maybe<Scalars['String']>
  successful: Scalars['Boolean']
}

export type MigrationJob = {
  __typename?: 'MigrationJob'
  groupId: Scalars['String']
  migrationId: Scalars['String']
  projectId: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  addNewAsset: Scalars['String']
  addNewChiliImage: ChiliImage
  addNewImage: Image
  addNewPage: Scalars['Boolean']
  adminLogin: Scalars['String']
  changeProjectCurrentTemplate: Scalars['Boolean']
  changeProjectMeta: Scalars['Boolean']
  changeProjectName: Scalars['Boolean']
  checkIsPageCountSystemOutdated: Scalars['Boolean']
  clearDAPs: Scalars['Boolean']
  copyCoverTemplateToCustomerProject: Scalars['Boolean']
  createNewProject: Scalars['Boolean']
  createProjectUpdateFlag: Scalars['Boolean']
  deleteChiliImage: Scalars['Boolean']
  deleteExport: Scalars['Boolean']
  deleteImage: Scalars['Boolean']
  deleteProject: Scalars['Boolean']
  duplicatePage: Scalars['Boolean']
  emptyExportQueue: Scalars['Boolean']
  emptyMigrationsQueue: Scalars['Boolean']
  fixBrokenPages: FixBrokenPagesResult
  fixVariables: Scalars['Boolean']
  linkOrder: LinkOrderResult
  migrateToNewPageSystem: Scalars['Boolean']
  recoverDeletedProject: Scalars['Boolean']
  refreshToken: Scalars['String']
  removeMigrationFromQueue: Scalars['Boolean']
  removePage: Scalars['Boolean']
  removeUploadsPageBackground: Scalars['Boolean']
  replaceChiliImage: ChiliImage
  replaceImage: Image
  resetThemePageBackground: Scalars['Boolean']
  reuploadOldImage: ReuploadedImage
  saveDocument: Scalars['Boolean']
  savePagePreview: Scalars['Boolean']
  sendToProduction: Scalars['Boolean']
  setPagesOrder: Scalars['Boolean']
  setThemeColor: Scalars['Boolean']
  setThemePageLibraryBackground: Scalars['Boolean']
  setThemePageUploadsBackground: Scalars['Boolean']
  signIn: SignInResult
  startExport: ExportProject
  startExportDocument: StartExportDocument
  startImageMigration: Scalars['Boolean']
  startMigration: Scalars['Boolean']
  startMigrationsForRandomProjects: Scalars['Boolean']
  unlinkOrder: Scalars['Boolean']
  updateCustomAttribute: Scalars['Boolean']
  uploadPageBackground: PageUploadsBackground
}

export type MutationAddNewAssetArgs = {
  file: Scalars['Upload']
  id: Scalars['String']
  name: Scalars['String']
  path: Scalars['String']
}

export type MutationAddNewChiliImageArgs = {
  file: Scalars['Upload']
  groupId: Scalars['String']
  projectId: Scalars['String']
}

export type MutationAddNewImageArgs = {
  file: Scalars['Upload']
  groupId: Scalars['String']
  projectId: Scalars['String']
  store?: InputMaybe<Scalars['Boolean']>
}

export type MutationAddNewPageArgs = {
  groupId: Scalars['String']
  projectId: Scalars['String']
  type: Scalars['String']
}

export type MutationAdminLoginArgs = {
  login: Scalars['String']
  password: Scalars['String']
}

export type MutationChangeProjectCurrentTemplateArgs = {
  groupId: Scalars['String']
  projectId: Scalars['String']
  templatePath: Scalars['String']
}

export type MutationChangeProjectMetaArgs = {
  adjustChili?: InputMaybe<Scalars['Boolean']>
  groupId: Scalars['String']
  input: ProjectMetaInput
  projectId: Scalars['String']
}

export type MutationChangeProjectNameArgs = {
  newName: Scalars['String']
  projectId: Scalars['String']
}

export type MutationCheckIsPageCountSystemOutdatedArgs = {
  groupId: Scalars['String']
  projectId: Scalars['String']
}

export type MutationClearDaPsArgs = {
  documentId: Scalars['String']
}

export type MutationCopyCoverTemplateToCustomerProjectArgs = {
  groupId: Scalars['String']
  projectName: Scalars['String']
}

export type MutationCreateNewProjectArgs = {
  coverType: Scalars['String']
  groupId: Scalars['String']
  projectName: Scalars['String']
  templatePath: Scalars['String']
}

export type MutationCreateProjectUpdateFlagArgs = {
  flagProjectUpdateInput: FlagProjectUpdateInput
}

export type MutationDeleteChiliImageArgs = {
  imageId: Scalars['String']
}

export type MutationDeleteExportArgs = {
  id: Scalars['String']
}

export type MutationDeleteImageArgs = {
  groupId: Scalars['String']
  imageId: Scalars['String']
  projectId: Scalars['String']
}

export type MutationDeleteProjectArgs = {
  groupId: Scalars['String']
  projectId: Scalars['String']
  userId: Scalars['String']
}

export type MutationDuplicatePageArgs = {
  groupId: Scalars['String']
  itemId: Scalars['String']
  pageName: Scalars['String']
  projectId: Scalars['String']
}

export type MutationFixBrokenPagesArgs = {
  groupId: Scalars['String']
  projectId: Scalars['String']
}

export type MutationFixVariablesArgs = {
  groupId: Scalars['String']
  projectId: Scalars['String']
}

export type MutationLinkOrderArgs = {
  customerNumber: Scalars['String']
  groupId: Scalars['String']
  orderNumber: Scalars['String']
  projectId: Scalars['String']
  year: Scalars['Int']
}

export type MutationMigrateToNewPageSystemArgs = {
  groupId: Scalars['String']
  projectId: Scalars['String']
}

export type MutationRecoverDeletedProjectArgs = {
  groupId: Scalars['String']
  projectId: Scalars['String']
}

export type MutationRemoveMigrationFromQueueArgs = {
  migrationId: Scalars['String']
}

export type MutationRemovePageArgs = {
  itemId: Scalars['String']
  projectId: Scalars['String']
}

export type MutationRemoveUploadsPageBackgroundArgs = {
  backgroundId: Scalars['String']
  groupId: Scalars['String']
  projectId: Scalars['String']
  type: Scalars['String']
}

export type MutationReplaceChiliImageArgs = {
  file: Scalars['Upload']
  groupId: Scalars['String']
  oldImageId?: InputMaybe<Scalars['String']>
  projectId: Scalars['String']
}

export type MutationReplaceImageArgs = {
  file: Scalars['Upload']
  groupId: Scalars['String']
  oldImageId?: InputMaybe<Scalars['String']>
  projectId: Scalars['String']
  store?: InputMaybe<Scalars['Boolean']>
}

export type MutationResetThemePageBackgroundArgs = {
  groupId: Scalars['String']
  pageType: Scalars['String']
  projectId: Scalars['String']
  templatePath: Scalars['String']
}

export type MutationReuploadOldImageArgs = {
  DAPId: Scalars['String']
  DAPValue: Scalars['String']
  groupId: Scalars['String']
  projectId: Scalars['String']
}

export type MutationSaveDocumentArgs = {
  documentId: Scalars['String']
  xml: Scalars['String']
}

export type MutationSavePagePreviewArgs = {
  groupId: Scalars['String']
  image: Scalars['Upload']
  pageId: Scalars['String']
  projectId: Scalars['String']
}

export type MutationSendToProductionArgs = {
  cover: Scalars['Upload']
  productionData: ProductionDataInput
}

export type MutationSetPagesOrderArgs = {
  groupId: Scalars['String']
  pages: Array<PageOrderInput>
  projectId: Scalars['String']
}

export type MutationSetThemeColorArgs = {
  groupId: Scalars['String']
  key: Scalars['String']
  projectId: Scalars['String']
  templatePath: Scalars['String']
  value: CmykColorInput
}

export type MutationSetThemePageLibraryBackgroundArgs = {
  backgroundCategory: Scalars['String']
  backgroundId: Scalars['String']
  groupId: Scalars['String']
  pageType: Scalars['String']
  projectId: Scalars['String']
  templatePath: Scalars['String']
}

export type MutationSetThemePageUploadsBackgroundArgs = {
  backgroundId?: InputMaybe<Scalars['String']>
  groupId: Scalars['String']
  pageType: Scalars['String']
  projectId: Scalars['String']
  templatePath: Scalars['String']
}

export type MutationStartExportArgs = {
  groupId: Scalars['String']
  outputProfile: Scalars['String']
  pageIds?: InputMaybe<Array<Scalars['String']>>
  projectId: Scalars['String']
  retryFailedPages?: InputMaybe<Scalars['Float']>
  userId: Scalars['String']
}

export type MutationStartExportDocumentArgs = {
  documentId: Scalars['String']
  groupId: Scalars['String']
  isCoverPage?: InputMaybe<Scalars['Boolean']>
  outputProfile?: InputMaybe<Scalars['String']>
  projectId: Scalars['String']
}

export type MutationStartImageMigrationArgs = {
  author?: InputMaybe<Scalars['String']>
  groupId: Scalars['String']
  projectId: Scalars['String']
  type?: InputMaybe<Scalars['String']>
}

export type MutationStartMigrationArgs = {
  projectId: Scalars['String']
}

export type MutationStartMigrationsForRandomProjectsArgs = {
  count: Scalars['Int']
}

export type MutationUnlinkOrderArgs = {
  customerNumber: Scalars['String']
  groupId: Scalars['String']
  orderNumber: Scalars['String']
  projectId: Scalars['String']
  year: Scalars['Int']
}

export type MutationUpdateCustomAttributeArgs = {
  attributeKey: Scalars['String']
  customerNumber: Scalars['String']
  orderNumber: Scalars['String']
  value?: InputMaybe<CustomAttributeValueInput>
  year: Scalars['Int']
}

export type MutationUploadPageBackgroundArgs = {
  file: Scalars['Upload']
  groupId: Scalars['String']
  projectId: Scalars['String']
}

export type PageLibraryBackground = {
  __typename?: 'PageLibraryBackground'
  category: Scalars['String']
  id: Scalars['String']
  thumbPath: Scalars['String']
}

export type PageOrderInput = {
  id: Scalars['String']
  index: Scalars['Float']
}

export type PageUploadsBackground = {
  __typename?: 'PageUploadsBackground'
  id: Scalars['String']
  thumbPath: Scalars['String']
  type: Scalars['String']
}

export type PagesEntity = {
  __typename?: 'PagesEntity'
  blackAndWhite: Scalars['Int']
  color: Scalars['Int']
  total: Scalars['Int']
}

export type ProductionAttrsEntity = {
  __typename?: 'ProductionAttrsEntity'
  approvalDate?: Maybe<Scalars['Float']>
  finalDataUploadDate?: Maybe<Scalars['Float']>
  latestPrintDataUploadDate?: Maybe<Scalars['Float']>
  noApproval?: Maybe<Scalars['Boolean']>
  orderDeliveryDate?: Maybe<Scalars['Float']>
  orderYear?: Maybe<Scalars['Float']>
  plannedApprovalDate?: Maybe<Scalars['Float']>
  proofDataInProductionDate?: Maybe<Scalars['Float']>
  proofDataUploadDate?: Maybe<Scalars['Float']>
  waitingForApprovalDate?: Maybe<Scalars['Float']>
}

export type ProductionDataInput = {
  adsPlacement: AdsPlacement
  bnwPages?: Scalars['String']
  customerNumber: Scalars['String']
  deliveryAddress: DeliveryAddressInput
  groupId: Scalars['String']
  orderNumber: Scalars['String']
  outputProfile: Scalars['String']
  pageIds?: InputMaybe<Array<Scalars['String']>>
  projectId: Scalars['String']
  uploadType: UploadTypes
  uploader: UploaderInput
  userId: Scalars['String']
  year: Scalars['Int']
}

export type ProductionExportEntity = {
  __typename?: 'ProductionExportEntity'
  author: ExportProjectAuthor
  finishedAt?: Maybe<Scalars['DateTime']>
  groupId: Scalars['String']
  id: Scalars['String']
  pages: Scalars['Int']
  projectId: Scalars['String']
  startedAt: Scalars['DateTime']
  status: ProductionExportStatuses
  type: ProductionExportType
}

export enum ProductionExportStatuses {
  Completed = 'completed',
  Failed = 'failed',
  InProgress = 'inProgress'
}

export enum ProductionExportType {
  Final = 'final',
  Preview = 'preview',
  Proof = 'proof'
}

export type Project = {
  __typename?: 'Project'
  id: Scalars['String']
  lastUpdate?: Maybe<ProjectUpdateFlagEntity>
  name: Scalars['String']
  pagePreviews: Array<ProjectDocument>
  path: Scalars['String']
}

export type ProjectDocument = {
  __typename?: 'ProjectDocument'
  id: Scalars['String']
  index?: Maybe<Scalars['Float']>
  name: Scalars['String']
  path: Scalars['String']
  previewUrl: Scalars['String']
  type?: Maybe<Scalars['String']>
}

export type ProjectDocumentPreview = {
  __typename?: 'ProjectDocumentPreview'
  id: Scalars['String']
  path?: Maybe<Scalars['String']>
}

export type ProjectMeta = {
  __typename?: 'ProjectMeta'
  coverType: Scalars['String']
  id: Scalars['String']
  name: Scalars['String']
  order?: Maybe<Scalars['String']>
}

export type ProjectMetaInput = {
  coverType?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export type ProjectPageData = {
  __typename?: 'ProjectPageData'
  index: Scalars['Float']
  previewUrl: Scalars['String']
  templatePath: Scalars['String']
  type: Scalars['String']
}

export type ProjectTemplateDataEntity = {
  __typename?: 'ProjectTemplateDataEntity'
  currentTemplatePath: Scalars['String']
  templateDocuments: Array<TemplateDocumentEntity>
}

export type ProjectTemplateEntity = {
  __typename?: 'ProjectTemplateEntity'
  categoryId: Scalars['String']
  id: Scalars['String']
  isCurrent: Scalars['Boolean']
  name: Scalars['String']
  path: Scalars['String']
  previewUrl: Scalars['String']
}

export type ProjectThemeColor = {
  __typename?: 'ProjectThemeColor'
  key: Scalars['String']
  name: Scalars['String']
  value: CmykColor
}

export type ProjectUpdateFlagEntity = {
  __typename?: 'ProjectUpdateFlagEntity'
  avatarUrl: Scalars['String']
  groupId: Scalars['String']
  name: Scalars['String']
  projectId: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type Query = {
  __typename?: 'Query'
  categories: Array<CategoryEntity>
  checkAuth: Scalars['Boolean']
  downloadSvgPreview: Scalars['String']
  findProjectCoverDocuments: Array<ProjectDocument>
  getAdminProjects: Array<AdminProject>
  getAllFonts: Array<Font>
  getBigFontPreviewSvg: Scalars['String']
  getBool: Scalars['Boolean']
  getDbSnapshot: Scalars['String']
  getDeliveryAddress: DeliveryAddressEntity
  getExportDocument: ExportDocument
  getImagesMigrationData: ImagesMigration
  getKpi: Kpi
  getLibraryPageBackgroundXML: Scalars['String']
  getMigrationData?: Maybe<Scalars['String']>
  getMigrationsQueue: Array<MigrationJob>
  getPageBackgroundUploads: Array<PageUploadsBackground>
  getPageBackgroundXML: Scalars['String']
  getPageBackgroundsLibrary: Array<PageLibraryBackground>
  getPageData: ProjectPageData
  getProductionAttributes: ProductionAttrsEntity
  getProductionExports: Array<ProductionExportEntity>
  getProjectDetails: AdminProjectDetails
  getProjectDocumentsPreviews: Array<ProjectDocumentPreview>
  getProjectExport: ExportProject
  getProjectExports: Array<ExportProject>
  getProjectMeta: ProjectMeta
  getProjectTemplateData: ProjectTemplateDataEntity
  getProjectTemplates: Array<ProjectTemplateEntity>
  getProjectToken?: Maybe<Scalars['String']>
  getProjectUpdateFlag: ProjectUpdateFlagEntity
  getProjectUpdateFlagsForGroup: Array<ProjectUpdateFlagEntity>
  getThemeColors: Array<ProjectThemeColor>
  getThemePages: Array<ThemePage>
  getYearbookParams: YearbookParamsEntity
  projectDocuments: Array<ProjectDocument>
  projects: Array<Project>
  templateDocuments: Array<TemplateDocumentEntity>
  templates: Array<TemplateEntity>
}

export type QueryDownloadSvgPreviewArgs = {
  height?: InputMaybe<Scalars['Int']>
  id: Scalars['String']
  width?: InputMaybe<Scalars['Int']>
}

export type QueryFindProjectCoverDocumentsArgs = {
  groupId: Scalars['String']
  projectId: Scalars['String']
}

export type QueryGetAdminProjectsArgs = {
  groupId: Scalars['String']
}

export type QueryGetBigFontPreviewSvgArgs = {
  id: Scalars['String']
}

export type QueryGetDbSnapshotArgs = {
  groupId: Scalars['String']
}

export type QueryGetDeliveryAddressArgs = {
  customerNumber: Scalars['String']
  orderNumber: Scalars['String']
  year: Scalars['Int']
}

export type QueryGetExportDocumentArgs = {
  taskId: Scalars['String']
}

export type QueryGetImagesMigrationDataArgs = {
  id: Scalars['String']
}

export type QueryGetKpiArgs = {
  minPagesCount: Scalars['Int']
}

export type QueryGetLibraryPageBackgroundXmlArgs = {
  category: Scalars['String']
  id: Scalars['String']
}

export type QueryGetMigrationDataArgs = {
  migrationId: Scalars['String']
}

export type QueryGetPageBackgroundUploadsArgs = {
  groupId: Scalars['String']
  projectId: Scalars['String']
}

export type QueryGetPageBackgroundXmlArgs = {
  id: Scalars['String']
}

export type QueryGetPageDataArgs = {
  documentId: Scalars['String']
  groupId: Scalars['String']
  projectId: Scalars['String']
}

export type QueryGetProductionAttributesArgs = {
  customerNumber: Scalars['String']
  orderNumber: Scalars['String']
  year: Scalars['Int']
}

export type QueryGetProductionExportsArgs = {
  groupId: Scalars['String']
  projectId: Scalars['String']
}

export type QueryGetProjectDetailsArgs = {
  projectId: Scalars['String']
}

export type QueryGetProjectDocumentsPreviewsArgs = {
  groupId: Scalars['String']
  projectId: Scalars['String']
}

export type QueryGetProjectExportArgs = {
  id: Scalars['String']
}

export type QueryGetProjectExportsArgs = {
  groupId: Scalars['String']
  projectId: Scalars['String']
}

export type QueryGetProjectMetaArgs = {
  groupId: Scalars['String']
  projectId: Scalars['String']
}

export type QueryGetProjectTemplateDataArgs = {
  groupId: Scalars['String']
  projectId: Scalars['String']
}

export type QueryGetProjectTemplatesArgs = {
  groupId: Scalars['String']
  projectId: Scalars['String']
}

export type QueryGetProjectTokenArgs = {
  groupId: Scalars['String']
  projectId: Scalars['String']
}

export type QueryGetProjectUpdateFlagArgs = {
  projectId: Scalars['String']
}

export type QueryGetProjectUpdateFlagsForGroupArgs = {
  groupId: Scalars['String']
}

export type QueryGetThemeColorsArgs = {
  projectId: Scalars['String']
  templatePath: Scalars['String']
}

export type QueryGetThemePagesArgs = {
  projectId: Scalars['String']
  templatePath: Scalars['String']
}

export type QueryGetYearbookParamsArgs = {
  customerNumber: Scalars['String']
  orderNumber: Scalars['String']
  year: Scalars['Int']
}

export type QueryProjectDocumentsArgs = {
  groupId: Scalars['String']
  projectId: Scalars['String']
}

export type QueryProjectsArgs = {
  groupId: Scalars['String']
}

export type QueryTemplateDocumentsArgs = {
  categoryId: Scalars['String']
  templateId: Scalars['String']
}

export type ReuploadedImage = {
  __typename?: 'ReuploadedImage'
  isFound: Scalars['Boolean']
  source: Scalars['String']
  xml: Scalars['String']
}

export type SignInResult = {
  __typename?: 'SignInResult'
  accessToken?: Maybe<Scalars['String']>
  refreshToken?: Maybe<Scalars['String']>
}

export type StartExportDocument = {
  __typename?: 'StartExportDocument'
  documentId: Scalars['String']
  isFinished: Scalars['Boolean']
  isStarted: Scalars['Boolean']
  isSucceeded: Scalars['Boolean']
  isVariableSet?: Maybe<Scalars['Boolean']>
  taskId: Scalars['String']
  url?: Maybe<Scalars['String']>
}

export type Subscription = {
  __typename?: 'Subscription'
  trackExport: ExportProject
}

export type SubscriptionTrackExportArgs = {
  id: Scalars['String']
}

export type TemplateDocumentEntity = {
  __typename?: 'TemplateDocumentEntity'
  id: Scalars['String']
  name: Scalars['String']
  path: Scalars['String']
  previewUrl: Scalars['String']
  type: Scalars['String']
}

export type TemplateEntity = {
  __typename?: 'TemplateEntity'
  categoryId: Scalars['String']
  id: Scalars['String']
  name: Scalars['String']
  path: Scalars['String']
  previewUrl: Scalars['String']
}

export type ThemePage = {
  __typename?: 'ThemePage'
  thumbUrl: Scalars['String']
  type: Scalars['String']
}

export enum UploadTypes {
  Final = 'final',
  Proof = 'proof'
}

export type UploaderInput = {
  email: Scalars['String']
  name: Scalars['String']
  phoneNumber: Scalars['String']
}

export type YearbookParamsEntity = {
  __typename?: 'YearbookParamsEntity'
  coloring: Coloring
  cover: Scalars['String']
  customer: ErpCustomerEntity
  format: Formats
  grammage: Scalars['String']
  pages: PagesEntity
}

export type AdminLoginMutationVariables = Exact<{
  login: Scalars['String']
  password: Scalars['String']
}>

export type AdminLoginMutation = {
  __typename?: 'Mutation'
  adminLogin: string
}

export type StartImageMigrationMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
  author?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}>

export type StartImageMigrationMutation = {
  __typename?: 'Mutation'
  startImageMigration: boolean
}

export type StartExportDocumentMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
  documentId: Scalars['String']
  outputProfile?: InputMaybe<Scalars['String']>
  isCoverPage?: InputMaybe<Scalars['Boolean']>
}>

export type StartExportDocumentMutation = {
  __typename?: 'Mutation'
  startExportDocument: {
    __typename?: 'StartExportDocument'
    documentId: string
    taskId: string
    isStarted: boolean
    isFinished: boolean
    isSucceeded: boolean
    isVariableSet?: boolean | null
    url?: string | null
  }
}

export type FixVariablesMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
}>

export type FixVariablesMutation = {
  __typename?: 'Mutation'
  fixVariables: boolean
}

export type AddNewAssetMutationVariables = Exact<{
  id: Scalars['String']
  path: Scalars['String']
  name: Scalars['String']
  file: Scalars['Upload']
}>

export type AddNewAssetMutation = {
  __typename?: 'Mutation'
  addNewAsset: string
}

export type RecoverDeletedProjectMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
}>

export type RecoverDeletedProjectMutation = {
  __typename?: 'Mutation'
  recoverDeletedProject: boolean
}

export type EmptyExportQueueMutationVariables = Exact<{
  [key: string]: never
}>

export type EmptyExportQueueMutation = {
  __typename?: 'Mutation'
  emptyExportQueue: boolean
}

export type DuplicatePageMutationVariables = Exact<{
  pageName: Scalars['String']
  itemId: Scalars['String']
  groupId: Scalars['String']
  projectId: Scalars['String']
}>

export type DuplicatePageMutation = {
  __typename?: 'Mutation'
  duplicatePage: boolean
}

export type SavePagePreviewMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
  pageId: Scalars['String']
  image: Scalars['Upload']
}>

export type SavePagePreviewMutation = {
  __typename?: 'Mutation'
  savePagePreview: boolean
}

export type StartExportMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
  pageIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  outputProfile: Scalars['String']
  userId: Scalars['String']
  retryFailedPages?: InputMaybe<Scalars['Float']>
}>

export type StartExportMutation = {
  __typename?: 'Mutation'
  startExport: {
    __typename?: 'ExportProject'
    id: string
    groupId: string
    projectId: string
    status: string
    startedAt?: any | null
    finishedAt?: any | null
    outputProfile: string
    error?: string | null
    pages: Array<{
      __typename?: 'ExportProjectPage'
      pageId: string
      status: string
      startedAt?: any | null
      finishedAt?: any | null
      pdfUrl?: string | null
    }>
    author?: {
      __typename?: 'ExportProjectAuthor'
      id: string
      fullName: string
    } | null
  }
}

export type DeleteExportMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteExportMutation = {
  __typename?: 'Mutation'
  deleteExport: boolean
}

export type AddNewChiliImageMutationVariables = Exact<{
  image: Scalars['Upload']
  groupId: Scalars['String']
  projectId: Scalars['String']
}>

export type AddNewChiliImageMutation = {
  __typename?: 'Mutation'
  addNewChiliImage: {
    __typename?: 'ChiliImage'
    id: string
    path: string
    name: string
    resolution: number
    height: number
    width: number
    xml: string
  }
}

export type AddNewImageMutationVariables = Exact<{
  image: Scalars['Upload']
  groupId: Scalars['String']
  projectId: Scalars['String']
  store?: InputMaybe<Scalars['Boolean']>
}>

export type AddNewImageMutation = {
  __typename?: 'Mutation'
  addNewImage: {
    __typename?: 'Image'
    id: string
    resolution: number
    height: number
    width: number
    imageQueryParams?: string | null
    size: number
  }
}

export type ReplaceChiliImageMutationVariables = Exact<{
  image: Scalars['Upload']
  groupId: Scalars['String']
  projectId: Scalars['String']
  oldImageId?: InputMaybe<Scalars['String']>
}>

export type ReplaceChiliImageMutation = {
  __typename?: 'Mutation'
  replaceChiliImage: {
    __typename?: 'ChiliImage'
    id: string
    path: string
    name: string
    resolution: number
    height: number
    width: number
    xml: string
  }
}

export type ReplaceImageMutationVariables = Exact<{
  image: Scalars['Upload']
  groupId: Scalars['String']
  projectId: Scalars['String']
  oldImageId?: InputMaybe<Scalars['String']>
  store?: InputMaybe<Scalars['Boolean']>
}>

export type ReplaceImageMutation = {
  __typename?: 'Mutation'
  replaceImage: {
    __typename?: 'Image'
    id: string
    resolution: number
    height: number
    width: number
    imageQueryParams?: string | null
    size: number
  }
}

export type DeleteChiliImageMutationVariables = Exact<{
  imageId: Scalars['String']
}>

export type DeleteChiliImageMutation = {
  __typename?: 'Mutation'
  deleteChiliImage: boolean
}

export type DeleteImageMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
  imageId: Scalars['String']
}>

export type DeleteImageMutation = {
  __typename?: 'Mutation'
  deleteImage: boolean
}

export type ReuploadOldImageMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
  DAPId: Scalars['String']
  DAPValue: Scalars['String']
}>

export type ReuploadOldImageMutation = {
  __typename?: 'Mutation'
  reuploadOldImage: {
    __typename?: 'ReuploadedImage'
    source: string
    isFound: boolean
    xml: string
  }
}

export type StartMigrationsForRandomProjectsMutationVariables =
  Exact<{
    count: Scalars['Int']
  }>

export type StartMigrationsForRandomProjectsMutation = {
  __typename?: 'Mutation'
  startMigrationsForRandomProjects: boolean
}

export type StartMigrationMutationVariables = Exact<{
  projectId: Scalars['String']
}>

export type StartMigrationMutation = {
  __typename?: 'Mutation'
  startMigration: boolean
}

export type RemoveMigrationFromQueueMutationVariables = Exact<{
  migrationId: Scalars['String']
}>

export type RemoveMigrationFromQueueMutation = {
  __typename?: 'Mutation'
  removeMigrationFromQueue: boolean
}

export type EmptyMigrationsQueueMutationVariables = Exact<{
  [key: string]: never
}>

export type EmptyMigrationsQueueMutation = {
  __typename?: 'Mutation'
  emptyMigrationsQueue: boolean
}

export type SendToProductionMutationVariables = Exact<{
  productionData: ProductionDataInput
  cover: Scalars['Upload']
}>

export type SendToProductionMutation = {
  __typename?: 'Mutation'
  sendToProduction: boolean
}

export type UpdateCustomAttributeMutationVariables = Exact<{
  orderNumber: Scalars['String']
  year: Scalars['Int']
  customerNumber: Scalars['String']
  attributeKey: Scalars['String']
  value?: InputMaybe<CustomAttributeValueInput>
}>

export type UpdateCustomAttributeMutation = {
  __typename?: 'Mutation'
  updateCustomAttribute: boolean
}

export type CreateNewProjectMutationVariables = Exact<{
  templatePath: Scalars['String']
  groupId: Scalars['String']
  projectName: Scalars['String']
  coverType: Scalars['String']
}>

export type CreateNewProjectMutation = {
  __typename?: 'Mutation'
  createNewProject: boolean
}

export type CopyCoverTemplateToCustomerProjectMutationVariables =
  Exact<{
    projectName: Scalars['String']
    groupId: Scalars['String']
  }>

export type CopyCoverTemplateToCustomerProjectMutation = {
  __typename?: 'Mutation'
  copyCoverTemplateToCustomerProject: boolean
}

export type AddNewPageMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
  type: Scalars['String']
}>

export type AddNewPageMutation = {
  __typename?: 'Mutation'
  addNewPage: boolean
}

export type RemovePageMutationVariables = Exact<{
  projectId: Scalars['String']
  itemId: Scalars['String']
}>

export type RemovePageMutation = {
  __typename?: 'Mutation'
  removePage: boolean
}

export type SaveDocumentMutationVariables = Exact<{
  documentId: Scalars['String']
  xml: Scalars['String']
}>

export type SaveDocumentMutation = {
  __typename?: 'Mutation'
  saveDocument: boolean
}

export type ChangeProjectCurrentTemplateMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
  templatePath: Scalars['String']
}>

export type ChangeProjectCurrentTemplateMutation = {
  __typename?: 'Mutation'
  changeProjectCurrentTemplate: boolean
}

export type FixBrokenPagesMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
}>

export type FixBrokenPagesMutation = {
  __typename?: 'Mutation'
  fixBrokenPages: {
    __typename?: 'FixBrokenPagesResult'
    isOk: boolean
    fixedCount: number
  }
}

export type SetPagesOrderMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
  pages: Array<PageOrderInput> | PageOrderInput
}>

export type SetPagesOrderMutation = {
  __typename?: 'Mutation'
  setPagesOrder: boolean
}

export type ChangeProjectNameMutationVariables = Exact<{
  projectId: Scalars['String']
  newName: Scalars['String']
}>

export type ChangeProjectNameMutation = {
  __typename?: 'Mutation'
  changeProjectName: boolean
}

export type ChangeProjectMetaMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
  input: ProjectMetaInput
  adjustChili?: InputMaybe<Scalars['Boolean']>
}>

export type ChangeProjectMetaMutation = {
  __typename?: 'Mutation'
  changeProjectMeta: boolean
}

export type LinkOrderMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
  orderNumber: Scalars['String']
  year: Scalars['Int']
  customerNumber: Scalars['String']
}>

export type LinkOrderMutation = {
  __typename?: 'Mutation'
  linkOrder: {
    __typename?: 'LinkOrderResult'
    successful: boolean
    errorType?: string | null
  }
}

export type UnlinkOrderMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
  orderNumber: Scalars['String']
  year: Scalars['Int']
  customerNumber: Scalars['String']
}>

export type UnlinkOrderMutation = {
  __typename?: 'Mutation'
  unlinkOrder: boolean
}

export type DeleteProjectMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
  userId: Scalars['String']
}>

export type DeleteProjectMutation = {
  __typename?: 'Mutation'
  deleteProject: boolean
}

export type MigrateToNewPageSystemMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
}>

export type MigrateToNewPageSystemMutation = {
  __typename?: 'Mutation'
  migrateToNewPageSystem: boolean
}

export type CheckIsPageCountSystemOutdatedMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
}>

export type CheckIsPageCountSystemOutdatedMutation = {
  __typename?: 'Mutation'
  checkIsPageCountSystemOutdated: boolean
}

export type SetThemeColorMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
  key: Scalars['String']
  value: CmykColorInput
  templatePath: Scalars['String']
}>

export type SetThemeColorMutation = {
  __typename?: 'Mutation'
  setThemeColor: boolean
}

export type SetThemePageLibraryBackgroundMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
  templatePath: Scalars['String']
  pageType: Scalars['String']
  backgroundId: Scalars['String']
  backgroundCategory: Scalars['String']
}>

export type SetThemePageLibraryBackgroundMutation = {
  __typename?: 'Mutation'
  setThemePageLibraryBackground: boolean
}

export type SetThemePageUploadsBackgroundMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
  pageType: Scalars['String']
  templatePath: Scalars['String']
  backgroundId: Scalars['String']
}>

export type SetThemePageUploadsBackgroundMutation = {
  __typename?: 'Mutation'
  setThemePageUploadsBackground: boolean
}

export type ResetThemePageBackgroundMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
  pageType: Scalars['String']
  templatePath: Scalars['String']
}>

export type ResetThemePageBackgroundMutation = {
  __typename?: 'Mutation'
  resetThemePageBackground: boolean
}

export type UploadPageBackgroundMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
  file: Scalars['Upload']
}>

export type UploadPageBackgroundMutation = {
  __typename?: 'Mutation'
  uploadPageBackground: {
    __typename?: 'PageUploadsBackground'
    id: string
    thumbPath: string
  }
}

export type RemoveUploadsPageBackgroundMutationVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
  backgroundId: Scalars['String']
  type: Scalars['String']
}>

export type RemoveUploadsPageBackgroundMutation = {
  __typename?: 'Mutation'
  removeUploadsPageBackground: boolean
}

export type CheckAuthQueryVariables = Exact<{ [key: string]: never }>

export type CheckAuthQuery = {
  __typename?: 'Query'
  checkAuth: boolean
}

export type GetAdminProjectsQueryVariables = Exact<{
  groupId: Scalars['String']
}>

export type GetAdminProjectsQuery = {
  __typename?: 'Query'
  getAdminProjects: Array<{
    __typename?: 'AdminProject'
    id?: string | null
    name: string
    isMigrationRunning: boolean
    deleted: boolean
    pagePreviews: Array<
      { __typename?: 'ProjectDocument' } & {
        ' $fragmentRefs'?: {
          PagePreviewsFieldsFragment: PagePreviewsFieldsFragment
        }
      }
    >
  }>
}

export type GetDbSnapshotQueryVariables = Exact<{
  groupId: Scalars['String']
}>

export type GetDbSnapshotQuery = {
  __typename?: 'Query'
  getDbSnapshot: string
}

export type GetProjectDetailsQueryVariables = Exact<{
  projectId: Scalars['String']
}>

export type GetProjectDetailsQuery = {
  __typename?: 'Query'
  getProjectDetails: {
    __typename?: 'AdminProjectDetails'
    name: string
    groupId: string
    pagesCount: number
    migrations: Array<{
      __typename?: 'ImagesMigration'
      status: string
      type: string
      id: string
      startedAt: any
      author?: string | null
    }>
  }
}

export type GetImagesMigrationDataQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetImagesMigrationDataQuery = {
  __typename?: 'Query'
  getImagesMigrationData: {
    __typename?: 'ImagesMigration'
    type: string
    status: string
    id: string
    startedAt: any
    finishedAt?: any | null
    author?: string | null
    documents: Array<{
      __typename?: 'ImagesMigrationDocument'
      id: string
      order: number
      name: string
      startedAt?: any | null
      finishedAt?: any | null
      status: string
      error?: string | null
      variables: Array<{
        __typename?: 'ImagesMigrationVariable'
        type: string
        id: string
        name: string
        prevValue: string
        newValue?: string | null
        freshExternalUrl?: string | null
        status: string
        error?: string | null
      }>
    }>
  }
}

export type GetExportDocumentQueryVariables = Exact<{
  taskId: Scalars['String']
}>

export type GetExportDocumentQuery = {
  __typename?: 'Query'
  getExportDocument: {
    __typename?: 'ExportDocument'
    documentId: string
    taskId: string
    isStarted: boolean
    isFinished: boolean
    isSucceeded: boolean
    url?: string | null
  }
}

export type GetKpiQueryVariables = Exact<{
  minPagesCount: Scalars['Int']
}>

export type GetKpiQuery = {
  __typename?: 'Query'
  getKpi: {
    __typename?: 'Kpi'
    pagesCreated: number
    pagesExported: number
    projectsCreated: number
    projectsCreatedWithMinPagesCount: number
    activeGroups: number
  }
}

export type GetDocumentsQueryVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
}>

export type GetDocumentsQuery = {
  __typename?: 'Query'
  projectDocuments: Array<{
    __typename?: 'ProjectDocument'
    id: string
    path: string
    previewUrl: string
    name: string
    type?: string | null
    index?: number | null
  }>
}

export type GetProjectDocumentsPreviewsQueryVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
}>

export type GetProjectDocumentsPreviewsQuery = {
  __typename?: 'Query'
  getProjectDocumentsPreviews: Array<{
    __typename?: 'ProjectDocumentPreview'
    id: string
    path?: string | null
  }>
}

export type GetProjectCoverDocumentsQueryVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
}>

export type GetProjectCoverDocumentsQuery = {
  __typename?: 'Query'
  findProjectCoverDocuments: Array<{
    __typename?: 'ProjectDocument'
    id: string
    name: string
    path: string
    previewUrl: string
  }>
}

export type GetDeliveryAddressQueryVariables = Exact<{
  orderNumber: Scalars['String']
  year: Scalars['Int']
  customerNumber: Scalars['String']
}>

export type GetDeliveryAddressQuery = {
  __typename?: 'Query'
  getDeliveryAddress: {
    __typename?: 'DeliveryAddressEntity'
    firstName: string
    lastName: string
    streetAndNumber: string
    companyOrAdditionalInfo?: string | null
    zip: string
    city: string
    countryCode: string
  }
}

export type GetYearbookParamsQueryVariables = Exact<{
  orderNumber: Scalars['String']
  year: Scalars['Int']
  customerNumber: Scalars['String']
}>

export type GetYearbookParamsQuery = {
  __typename?: 'Query'
  getYearbookParams: {
    __typename?: 'YearbookParamsEntity'
    cover: string
    format: Formats
    grammage: string
    pages: { __typename?: 'PagesEntity'; total: number }
  }
}

export type GetProductionAttrsQueryVariables = Exact<{
  orderNumber: Scalars['String']
  year: Scalars['Int']
  customerNumber: Scalars['String']
}>

export type GetProductionAttrsQuery = {
  __typename?: 'Query'
  getProductionAttributes: {
    __typename?: 'ProductionAttrsEntity'
    orderYear?: number | null
    proofDataInProductionDate?: number | null
    approvalDate?: number | null
    latestPrintDataUploadDate?: number | null
    finalDataUploadDate?: number | null
    noApproval?: boolean | null
    plannedApprovalDate?: number | null
    waitingForApprovalDate?: number | null
    proofDataUploadDate?: number | null
    orderDeliveryDate?: number | null
  }
}

export type GetProjectExportQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetProjectExportQuery = {
  __typename?: 'Query'
  getProjectExport: {
    __typename?: 'ExportProject'
    id: string
    groupId: string
    projectId: string
    status: string
    startedAt?: any | null
    finishedAt?: any | null
    outputProfile: string
    error?: string | null
    pages: Array<{
      __typename?: 'ExportProjectPage'
      pageId: string
      status: string
      startedAt?: any | null
      finishedAt?: any | null
      pdfUrl?: string | null
      error?: {
        __typename?: 'ExportProjectPageError'
        code?: string | null
        message?: string | null
      } | null
    }>
    author?: {
      __typename?: 'ExportProjectAuthor'
      id: string
      fullName: string
    } | null
  }
}

export type GetProjectExportsQueryVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
}>

export type GetProjectExportsQuery = {
  __typename?: 'Query'
  getProjectExports: Array<{
    __typename?: 'ExportProject'
    id: string
    groupId: string
    projectId: string
    status: string
    startedAt?: any | null
    finishedAt?: any | null
    outputProfile: string
    error?: string | null
    pages: Array<{
      __typename?: 'ExportProjectPage'
      pageId: string
      status: string
      startedAt?: any | null
      finishedAt?: any | null
      pdfUrl?: string | null
      error?: {
        __typename?: 'ExportProjectPageError'
        code?: string | null
        message?: string | null
      } | null
    }>
    author?: {
      __typename?: 'ExportProjectAuthor'
      id: string
      fullName: string
    } | null
  }>
}

export type GetBigFontPreviewSvgQueryVariables = Exact<{
  fontId: Scalars['String']
}>

export type GetBigFontPreviewSvgQuery = {
  __typename?: 'Query'
  getBigFontPreviewSvg: string
}

export type GetAllFontsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetAllFontsQuery = {
  __typename?: 'Query'
  getAllFonts: Array<{
    __typename?: 'Font'
    id: string
    name: string
    family: string
    style: string
    svgBig?: string | null
    svgSmall?: string | null
  }>
}

export type GetMigrationsQueueQueryVariables = Exact<{
  [key: string]: never
}>

export type GetMigrationsQueueQuery = {
  __typename?: 'Query'
  getMigrationsQueue: Array<{
    __typename?: 'MigrationJob'
    groupId: string
    projectId: string
    migrationId: string
  }>
}

export type GetMigrationDataQueryVariables = Exact<{
  migrationId: Scalars['String']
}>

export type GetMigrationDataQuery = {
  __typename?: 'Query'
  getMigrationData?: string | null
}

export type GetProductionExportsQueryVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
}>

export type GetProductionExportsQuery = {
  __typename?: 'Query'
  getProductionExports: Array<{
    __typename?: 'ProductionExportEntity'
    id: string
    type: ProductionExportType
    status: ProductionExportStatuses
    startedAt: any
    finishedAt?: any | null
    pages: number
    groupId: string
    author: { __typename?: 'ExportProjectAuthor'; fullName: string }
  }>
}

export type LastUpdateFieldsFragment = {
  __typename?: 'ProjectUpdateFlagEntity'
  avatarUrl: string
  name: string
  updatedAt: any
} & { ' $fragmentName'?: 'LastUpdateFieldsFragment' }

export type PagePreviewsFieldsFragment = {
  __typename?: 'ProjectDocument'
  id: string
  name: string
  path: string
  previewUrl: string
} & { ' $fragmentName'?: 'PagePreviewsFieldsFragment' }

export type GetProjectsQueryVariables = Exact<{
  groupId: Scalars['String']
}>

export type GetProjectsQuery = {
  __typename?: 'Query'
  projects: Array<{
    __typename?: 'Project'
    id: string
    name: string
    path: string
    pagePreviews: Array<
      { __typename?: 'ProjectDocument' } & {
        ' $fragmentRefs'?: {
          PagePreviewsFieldsFragment: PagePreviewsFieldsFragment
        }
      }
    >
    lastUpdate?:
      | ({ __typename?: 'ProjectUpdateFlagEntity' } & {
          ' $fragmentRefs'?: {
            LastUpdateFieldsFragment: LastUpdateFieldsFragment
          }
        })
      | null
  }>
}

export type GetPageDataQueryVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
  documentId: Scalars['String']
}>

export type GetPageDataQuery = {
  __typename?: 'Query'
  getPageData: {
    __typename?: 'ProjectPageData'
    index: number
    templatePath: string
    previewUrl: string
  }
}

export type TemplateDocumentFieldsFragment = {
  __typename?: 'TemplateDocumentEntity'
  id: string
  name: string
  type: string
} & { ' $fragmentName'?: 'TemplateDocumentFieldsFragment' }

export type GetProjectTemplateDataQueryVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
}>

export type GetProjectTemplateDataQuery = {
  __typename?: 'Query'
  getProjectTemplateData: {
    __typename?: 'ProjectTemplateDataEntity'
    currentTemplatePath: string
    templateDocuments: Array<
      { __typename?: 'TemplateDocumentEntity' } & {
        ' $fragmentRefs'?: {
          TemplateDocumentFieldsFragment: TemplateDocumentFieldsFragment
        }
      }
    >
  }
}

export type GetProjectTemplatesQueryVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
}>

export type GetProjectTemplatesQuery = {
  __typename?: 'Query'
  getProjectTemplates: Array<{
    __typename?: 'ProjectTemplateEntity'
    path: string
    name: string
    categoryId: string
    previewUrl: string
    isCurrent: boolean
  }>
}

export type GetProjectTokenQueryVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
}>

export type GetProjectTokenQuery = {
  __typename?: 'Query'
  getProjectToken?: string | null
}

export type GetProjectMetaQueryVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
}>

export type GetProjectMetaQuery = {
  __typename?: 'Query'
  getProjectMeta: {
    __typename?: 'ProjectMeta'
    id: string
    name: string
    coverType: string
    order?: string | null
  }
}

export type GetCategoriesQueryVariables = Exact<{
  [key: string]: never
}>

export type GetCategoriesQuery = {
  __typename?: 'Query'
  categories: Array<{
    __typename?: 'CategoryEntity'
    id: string
    name: string
    path: string
  }>
}

export type GetTemplatesQueryVariables = Exact<{
  [key: string]: never
}>

export type GetTemplatesQuery = {
  __typename?: 'Query'
  templates: Array<{
    __typename?: 'TemplateEntity'
    id: string
    name: string
    categoryId: string
    previewUrl: string
    path: string
  }>
}

export type GetTemplateDocsQueryVariables = Exact<{
  categoryId: Scalars['String']
  templateId: Scalars['String']
}>

export type GetTemplateDocsQuery = {
  __typename?: 'Query'
  templateDocuments: Array<{
    __typename?: 'TemplateDocumentEntity'
    id: string
    name: string
    previewUrl: string
    path: string
    type: string
  }>
}

export type GetThemeColorsQueryVariables = Exact<{
  projectId: Scalars['String']
  templatePath: Scalars['String']
}>

export type GetThemeColorsQuery = {
  __typename?: 'Query'
  getThemeColors: Array<{
    __typename?: 'ProjectThemeColor'
    key: string
    name: string
    value: {
      __typename?: 'CMYKColor'
      c: number
      m: number
      y: number
      k: number
    }
  }>
}

export type GetThemePagesQueryVariables = Exact<{
  projectId: Scalars['String']
  templatePath: Scalars['String']
}>

export type GetThemePagesQuery = {
  __typename?: 'Query'
  getThemePages: Array<{
    __typename?: 'ThemePage'
    type: string
    thumbUrl: string
  }>
}

export type GetPageBackgroundsLibraryQueryVariables = Exact<{
  [key: string]: never
}>

export type GetPageBackgroundsLibraryQuery = {
  __typename?: 'Query'
  getPageBackgroundsLibrary: Array<{
    __typename?: 'PageLibraryBackground'
    id: string
    category: string
    thumbPath: string
  }>
}

export type GetLibraryPageBackgroundXmlQueryVariables = Exact<{
  id: Scalars['String']
  category: Scalars['String']
}>

export type GetLibraryPageBackgroundXmlQuery = {
  __typename?: 'Query'
  getLibraryPageBackgroundXML: string
}

export type GetPageBackgroundUploadsQueryVariables = Exact<{
  groupId: Scalars['String']
  projectId: Scalars['String']
}>

export type GetPageBackgroundUploadsQuery = {
  __typename?: 'Query'
  getPageBackgroundUploads: Array<{
    __typename?: 'PageUploadsBackground'
    id: string
    thumbPath: string
    type: string
  }>
}

export type GetPageBackgroundXmlQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetPageBackgroundXmlQuery = {
  __typename?: 'Query'
  getPageBackgroundXML: string
}

export type TrackExportSubscriptionVariables = Exact<{
  id: Scalars['String']
}>

export type TrackExportSubscription = {
  __typename?: 'Subscription'
  trackExport: {
    __typename?: 'ExportProject'
    id: string
    groupId: string
    projectId: string
    status: string
    startedAt?: any | null
    finishedAt?: any | null
    outputProfile: string
    error?: string | null
    pages: Array<{
      __typename?: 'ExportProjectPage'
      pageId: string
      status: string
      startedAt?: any | null
      finishedAt?: any | null
      pdfUrl?: string | null
      error?: {
        __typename?: 'ExportProjectPageError'
        code?: string | null
        message?: string | null
      } | null
    }>
    author?: {
      __typename?: 'ExportProjectAuthor'
      id: string
      fullName: string
    } | null
  }
}

export const LastUpdateFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastUpdateFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectUpdateFlagEntity' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatarUrl' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedAt' }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<LastUpdateFieldsFragment, unknown>
export const PagePreviewsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PagePreviewsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectDocument' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'path' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<PagePreviewsFieldsFragment, unknown>
export const TemplateDocumentFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateDocumentFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TemplateDocumentEntity' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<TemplateDocumentFieldsFragment, unknown>
export const AdminLoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'adminLogin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'login' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminLogin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'login' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'login' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminLoginMutation,
  AdminLoginMutationVariables
>
export const StartImageMigrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'startImageMigration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'author' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'String' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'type' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'String' }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startImageMigration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'author' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'author' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  StartImageMigrationMutation,
  StartImageMigrationMutationVariables
>
export const StartExportDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'startExportDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'documentId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'outputProfile' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'String' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isCoverPage' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Boolean' }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startExportDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'documentId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'outputProfile' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'outputProfile' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isCoverPage' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'isCoverPage' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documentId' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskId' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isStarted' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isFinished' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSucceeded' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isVariableSet' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  StartExportDocumentMutation,
  StartExportDocumentMutationVariables
>
export const FixVariablesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'fixVariables' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fixVariables' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  FixVariablesMutation,
  FixVariablesMutationVariables
>
export const AddNewAssetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addNewAsset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'id' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'path' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'name' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'file' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Upload' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addNewAsset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'path' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'path' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'file' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AddNewAssetMutation,
  AddNewAssetMutationVariables
>
export const RecoverDeletedProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'recoverDeletedProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recoverDeletedProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  RecoverDeletedProjectMutation,
  RecoverDeletedProjectMutationVariables
>
export const EmptyExportQueueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'emptyExportQueue' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emptyExportQueue' }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  EmptyExportQueueMutation,
  EmptyExportQueueMutationVariables
>
export const DuplicatePageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'duplicatePage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pageName' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duplicatePage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pageName' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'itemId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'itemId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  DuplicatePageMutation,
  DuplicatePageMutationVariables
>
export const SavePagePreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'savePagePreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pageId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'image' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Upload' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'savePagePreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pageId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'image' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'image' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  SavePagePreviewMutation,
  SavePagePreviewMutationVariables
>
export const StartExportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'startExport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pageIds' }
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' }
              }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'outputProfile' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'retryFailedPages' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Float' }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startExport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pageIds' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'outputProfile' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'outputProfile' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'retryFailedPages' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'retryFailedPages' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupId' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projectId' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'status' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'startedAt' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finishedAt' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'outputProfile' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'error' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageId' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'finishedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pdfUrl' }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  StartExportMutation,
  StartExportMutationVariables
>
export const DeleteExportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteExport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'id' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteExport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  DeleteExportMutation,
  DeleteExportMutationVariables
>
export const AddNewChiliImageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addNewChiliImage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'image' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Upload' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addNewChiliImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'image' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resolution' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'height' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'width' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'xml' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AddNewChiliImageMutation,
  AddNewChiliImageMutationVariables
>
export const AddNewImageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addNewImage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'image' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Upload' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'store' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Boolean' }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addNewImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'image' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'store' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'store' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resolution' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'height' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'width' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'imageQueryParams' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'size' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AddNewImageMutation,
  AddNewImageMutationVariables
>
export const ReplaceChiliImageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'replaceChiliImage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'image' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Upload' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'oldImageId' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'String' }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replaceChiliImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'image' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'oldImageId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'oldImageId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resolution' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'height' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'width' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'xml' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ReplaceChiliImageMutation,
  ReplaceChiliImageMutationVariables
>
export const ReplaceImageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'replaceImage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'image' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Upload' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'oldImageId' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'String' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'store' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Boolean' }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replaceImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'image' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'oldImageId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'oldImageId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'store' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'store' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resolution' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'height' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'width' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'imageQueryParams' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'size' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ReplaceImageMutation,
  ReplaceImageMutationVariables
>
export const DeleteChiliImageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteChiliImage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'imageId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteChiliImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'imageId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'imageId' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  DeleteChiliImageMutation,
  DeleteChiliImageMutationVariables
>
export const DeleteImageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteImage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'imageId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'imageId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'imageId' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  DeleteImageMutation,
  DeleteImageMutationVariables
>
export const ReuploadOldImageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'reuploadOldImage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'DAPId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'DAPValue' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reuploadOldImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'DAPId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'DAPId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'DAPValue' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'DAPValue' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'source' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isFound' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'xml' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ReuploadOldImageMutation,
  ReuploadOldImageMutationVariables
>
export const StartMigrationsForRandomProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'startMigrationsForRandomProjects'
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'count' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Int' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'startMigrationsForRandomProjects'
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'count' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'count' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  StartMigrationsForRandomProjectsMutation,
  StartMigrationsForRandomProjectsMutationVariables
>
export const StartMigrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'startMigration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startMigration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  StartMigrationMutation,
  StartMigrationMutationVariables
>
export const RemoveMigrationFromQueueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeMigrationFromQueue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'migrationId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeMigrationFromQueue' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'migrationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'migrationId' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  RemoveMigrationFromQueueMutation,
  RemoveMigrationFromQueueMutationVariables
>
export const EmptyMigrationsQueueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'emptyMigrationsQueue' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emptyMigrationsQueue' }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  EmptyMigrationsQueueMutation,
  EmptyMigrationsQueueMutationVariables
>
export const SendToProductionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'sendToProduction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productionData' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProductionDataInput' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cover' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Upload' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendToProduction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cover' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cover' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productionData' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productionData' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  SendToProductionMutation,
  SendToProductionMutationVariables
>
export const UpdateCustomAttributeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCustomAttribute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderNumber' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'year' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Int' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerNumber' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'attributeKey' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'value' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CustomAttributeValueInput' }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCustomAttribute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderNumber' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'year' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'year' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerNumber' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'attributeKey' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'attributeKey' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'value' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'value' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  UpdateCustomAttributeMutation,
  UpdateCustomAttributeMutationVariables
>
export const CreateNewProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createNewProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'templatePath' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectName' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'coverType' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createNewProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templatePath' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'templatePath' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectName' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'coverType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'coverType' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CreateNewProjectMutation,
  CreateNewProjectMutationVariables
>
export const CopyCoverTemplateToCustomerProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'copyCoverTemplateToCustomerProject'
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectName' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'copyCoverTemplateToCustomerProject'
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectName' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CopyCoverTemplateToCustomerProjectMutation,
  CopyCoverTemplateToCustomerProjectMutationVariables
>
export const AddNewPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addNewPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'type' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addNewPage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AddNewPageMutation,
  AddNewPageMutationVariables
>
export const RemovePageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removePage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removePage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'itemId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'itemId' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  RemovePageMutation,
  RemovePageMutationVariables
>
export const SaveDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'saveDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'documentId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'xml' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'documentId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'xml' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'xml' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  SaveDocumentMutation,
  SaveDocumentMutationVariables
>
export const ChangeProjectCurrentTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'changeProjectCurrentTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'templatePath' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'changeProjectCurrentTemplate'
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templatePath' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'templatePath' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ChangeProjectCurrentTemplateMutation,
  ChangeProjectCurrentTemplateMutationVariables
>
export const FixBrokenPagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'fixBrokenPages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fixBrokenPages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isOk' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fixedCount' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  FixBrokenPagesMutation,
  FixBrokenPagesMutationVariables
>
export const SetPagesOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setPagesOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pages' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'PageOrderInput' }
                }
              }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setPagesOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pages' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pages' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  SetPagesOrderMutation,
  SetPagesOrderMutationVariables
>
export const ChangeProjectNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'changeProjectName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newName' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeProjectName' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newName' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ChangeProjectNameMutation,
  ChangeProjectNameMutationVariables
>
export const ChangeProjectMetaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'changeProjectMeta' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProjectMetaInput' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'adjustChili' }
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Boolean' }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeProjectMeta' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'adjustChili' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'adjustChili' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ChangeProjectMetaMutation,
  ChangeProjectMetaMutationVariables
>
export const LinkOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'linkOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderNumber' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'year' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Int' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerNumber' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderNumber' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'year' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'year' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerNumber' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'successful' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorType' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  LinkOrderMutation,
  LinkOrderMutationVariables
>
export const UnlinkOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'unlinkOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderNumber' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'year' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Int' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerNumber' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unlinkOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderNumber' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'year' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'year' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerNumber' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  UnlinkOrderMutation,
  UnlinkOrderMutationVariables
>
export const DeleteProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>
export const MigrateToNewPageSystemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'migrateToNewPageSystem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'migrateToNewPageSystem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  MigrateToNewPageSystemMutation,
  MigrateToNewPageSystemMutationVariables
>
export const CheckIsPageCountSystemOutdatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'checkIsPageCountSystemOutdated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'checkIsPageCountSystemOutdated'
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CheckIsPageCountSystemOutdatedMutation,
  CheckIsPageCountSystemOutdatedMutationVariables
>
export const SetThemeColorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setThemeColor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'key' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'value' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CMYKColorInput' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'templatePath' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setThemeColor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'key' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'value' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'value' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templatePath' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'templatePath' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  SetThemeColorMutation,
  SetThemeColorMutationVariables
>
export const SetThemePageLibraryBackgroundDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setThemePageLibraryBackground' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'templatePath' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pageType' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'backgroundId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'backgroundCategory' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'setThemePageLibraryBackground'
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templatePath' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'templatePath' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pageType' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'backgroundId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'backgroundId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'backgroundCategory' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'backgroundCategory' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  SetThemePageLibraryBackgroundMutation,
  SetThemePageLibraryBackgroundMutationVariables
>
export const SetThemePageUploadsBackgroundDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setThemePageUploadsBackground' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pageType' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'templatePath' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'backgroundId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'setThemePageUploadsBackground'
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pageType' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templatePath' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'templatePath' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'backgroundId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'backgroundId' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  SetThemePageUploadsBackgroundMutation,
  SetThemePageUploadsBackgroundMutationVariables
>
export const ResetThemePageBackgroundDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resetThemePageBackground' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pageType' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'templatePath' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetThemePageBackground' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pageType' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templatePath' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'templatePath' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ResetThemePageBackgroundMutation,
  ResetThemePageBackgroundMutationVariables
>
export const UploadPageBackgroundDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'uploadPageBackground' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'file' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Upload' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadPageBackground' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'file' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thumbPath' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  UploadPageBackgroundMutation,
  UploadPageBackgroundMutationVariables
>
export const RemoveUploadsPageBackgroundDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeUploadsPageBackground' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'backgroundId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'type' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'removeUploadsPageBackground'
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'backgroundId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'backgroundId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  RemoveUploadsPageBackgroundMutation,
  RemoveUploadsPageBackgroundMutationVariables
>
export const CheckAuthDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkAuth' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkAuth' }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CheckAuthQuery, CheckAuthQueryVariables>
export const GetAdminProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAdminProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAdminProjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagePreviews' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'PagePreviewsFields'
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isMigrationRunning' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PagePreviewsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectDocument' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'path' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetAdminProjectsQuery,
  GetAdminProjectsQueryVariables
>
export const GetDbSnapshotDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDbSnapshot' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDbSnapshot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetDbSnapshotQuery,
  GetDbSnapshotQueryVariables
>
export const GetProjectDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupId' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagesCount' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'migrations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'type' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'author' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetProjectDetailsQuery,
  GetProjectDetailsQueryVariables
>
export const GetImagesMigrationDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getImagesMigrationData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'id' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getImagesMigrationData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'status' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'startedAt' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finishedAt' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'order' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'finishedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'error' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variables' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' }
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'prevValue'
                              }
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'newValue'
                              }
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'freshExternalUrl'
                              }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'error' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetImagesMigrationDataQuery,
  GetImagesMigrationDataQueryVariables
>
export const GetExportDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getExportDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getExportDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taskId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taskId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documentId' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taskId' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isStarted' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isFinished' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSucceeded' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetExportDocumentQuery,
  GetExportDocumentQueryVariables
>
export const GetKpiDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getKpi' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'minPagesCount' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Int' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getKpi' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'minPagesCount' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'minPagesCount' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagesCreated' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagesExported' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projectsCreated' }
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'projectsCreatedWithMinPagesCount'
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeGroups' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetKpiQuery, GetKpiQueryVariables>
export const GetDocumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDocuments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previewUrl' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'index' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetDocumentsQuery,
  GetDocumentsQueryVariables
>
export const GetProjectDocumentsPreviewsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectDocumentsPreviews' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'getProjectDocumentsPreviews'
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetProjectDocumentsPreviewsQuery,
  GetProjectDocumentsPreviewsQueryVariables
>
export const GetProjectCoverDocumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectCoverDocuments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'findProjectCoverDocuments'
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previewUrl' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetProjectCoverDocumentsQuery,
  GetProjectCoverDocumentsQueryVariables
>
export const GetDeliveryAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDeliveryAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderNumber' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'year' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Int' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerNumber' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDeliveryAddress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderNumber' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'year' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'year' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerNumber' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'firstName' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastName' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'streetAndNumber' }
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'companyOrAdditionalInfo'
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'zip' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'city' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'countryCode' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetDeliveryAddressQuery,
  GetDeliveryAddressQueryVariables
>
export const GetYearbookParamsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getYearbookParams' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderNumber' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'year' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Int' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerNumber' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getYearbookParams' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderNumber' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'year' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'year' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerNumber' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'total' }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cover' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'format' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grammage' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetYearbookParamsQuery,
  GetYearbookParamsQueryVariables
>
export const GetProductionAttrsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProductionAttrs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderNumber' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'year' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Int' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customerNumber' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProductionAttributes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderNumber' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'year' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'year' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customerNumber' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderYear' }
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'proofDataInProductionDate'
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'approvalDate' }
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'latestPrintDataUploadDate'
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finalDataUploadDate' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'noApproval' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plannedApprovalDate' }
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'waitingForApprovalDate'
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'proofDataUploadDate' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderDeliveryDate' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetProductionAttrsQuery,
  GetProductionAttrsQueryVariables
>
export const GetProjectExportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectExport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'id' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectExport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupId' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projectId' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'status' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'startedAt' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finishedAt' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'outputProfile' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'error' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageId' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'finishedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pdfUrl' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'error' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetProjectExportQuery,
  GetProjectExportQueryVariables
>
export const GetProjectExportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectExports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectExports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupId' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projectId' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'status' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'startedAt' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finishedAt' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'outputProfile' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'error' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageId' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'finishedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pdfUrl' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'error' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetProjectExportsQuery,
  GetProjectExportsQueryVariables
>
export const GetBigFontPreviewSvgDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBigFontPreviewSvg' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fontId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBigFontPreviewSvg' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fontId' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetBigFontPreviewSvgQuery,
  GetBigFontPreviewSvgQueryVariables
>
export const GetAllFontsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllFonts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllFonts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'family' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'style' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'svgBig' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'svgSmall' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetAllFontsQuery,
  GetAllFontsQueryVariables
>
export const GetMigrationsQueueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMigrationsQueue' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMigrationsQueue' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupId' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projectId' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'migrationId' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetMigrationsQueueQuery,
  GetMigrationsQueueQueryVariables
>
export const GetMigrationDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMigrationData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'migrationId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMigrationData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'migrationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'migrationId' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetMigrationDataQuery,
  GetMigrationDataQueryVariables
>
export const GetProductionExportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProductionExports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProductionExports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'status' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'startedAt' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finishedAt' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pages' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupId' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetProductionExportsQuery,
  GetProductionExportsQueryVariables
>
export const GetProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagePreviews' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'PagePreviewsFields'
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastUpdate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'LastUpdateFields'
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PagePreviewsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectDocument' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'path' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastUpdateFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectUpdateFlagEntity' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatarUrl' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedAt' }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetProjectsQuery,
  GetProjectsQueryVariables
>
export const GetPageDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPageData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'documentId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPageData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'documentId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'index' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'templatePath' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previewUrl' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetPageDataQuery,
  GetPageDataQueryVariables
>
export const GetProjectTemplateDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectTemplateData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectTemplateData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentTemplatePath' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'templateDocuments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'TemplateDocumentFields'
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateDocumentFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TemplateDocumentEntity' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetProjectTemplateDataQuery,
  GetProjectTemplateDataQueryVariables
>
export const GetProjectTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categoryId' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previewUrl' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isCurrent' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetProjectTemplatesQuery,
  GetProjectTemplatesQueryVariables
>
export const GetProjectTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetProjectTokenQuery,
  GetProjectTokenQueryVariables
>
export const GetProjectMetaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectMeta' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectMeta' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'coverType' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'order' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetProjectMetaQuery,
  GetProjectMetaQueryVariables
>
export const GetCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCategories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetCategoriesQuery,
  GetCategoriesQueryVariables
>
export const GetTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTemplates' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'templates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categoryId' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previewUrl' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetTemplatesQuery,
  GetTemplatesQueryVariables
>
export const GetTemplateDocsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTemplateDocs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'categoryId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'templateId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'templateDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'categoryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'categoryId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'templateId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previewUrl' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetTemplateDocsQuery,
  GetTemplateDocsQueryVariables
>
export const GetThemeColorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getThemeColors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'templatePath' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getThemeColors' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templatePath' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'templatePath' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'key' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'c' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'm' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'y' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'k' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetThemeColorsQuery,
  GetThemeColorsQueryVariables
>
export const GetThemePagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getThemePages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'templatePath' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getThemePages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templatePath' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'templatePath' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thumbUrl' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetThemePagesQuery,
  GetThemePagesQueryVariables
>
export const GetPageBackgroundsLibraryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPageBackgroundsLibrary' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'getPageBackgroundsLibrary'
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thumbPath' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetPageBackgroundsLibraryQuery,
  GetPageBackgroundsLibraryQueryVariables
>
export const GetLibraryPageBackgroundXmlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLibraryPageBackgroundXML' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'id' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'getLibraryPageBackgroundXML'
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'category' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'category' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetLibraryPageBackgroundXmlQuery,
  GetLibraryPageBackgroundXmlQueryVariables
>
export const GetPageBackgroundUploadsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPageBackgroundUploads' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPageBackgroundUploads' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thumbPath' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'type' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetPageBackgroundUploadsQuery,
  GetPageBackgroundUploadsQueryVariables
>
export const GetPageBackgroundXmlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPageBackgroundXML' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'id' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPageBackgroundXML' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetPageBackgroundXmlQuery,
  GetPageBackgroundXmlQueryVariables
>
export const TrackExportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'trackExport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'id' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trackExport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupId' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projectId' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'status' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'startedAt' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finishedAt' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'outputProfile' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'error' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageId' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'finishedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pdfUrl' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'error' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  TrackExportSubscription,
  TrackExportSubscriptionVariables
>
