import { PublisherInterface } from '@chili-publish/publisher-interface'
import { FormikContextType, useFormikContext } from 'formik'
import {
  ComponentType,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef
} from 'react'
import styled from 'styled-components'

import useFont from 'shared/components/Tools/hooks/useFont'
import ChiliColors from 'shared/components/Tools/services/ChiliColors'
import ChiliText from 'shared/components/Tools/services/ChiliText'
import {
  ChangeTextFormat,
  Font,
  FontStyles,
  Frame,
  TextAlign,
  TextFormat,
  TextStyles
} from 'shared/components/Tools/types'
import CMYK from 'shared/entities/CMYK'
import RGB from 'shared/entities/RGB'
import { ColorTypes } from 'shared/types/global'
import Button from 'shared/ui/Button_2'
import Flex from 'shared/ui/Flex'
import SectionButton, {
  SectionButtonIntent
} from 'shared/ui/SectionButton'

import ChangeColorPanel from '../ChangeColorPanel'
import FormGroup from '../FormGroup'
import FormMultiOptions, {
  Action as SelectAction,
  ActionTypes as SelectActionTypes
} from '../FormMultiOptions'
import FormOptions from '../FormOptions'
import FrameColors from '../FrameColors'
import { SecondLevelPanels } from '../FrameControls'
import ChangeFontPanel from './components/ChangeFontPanel'
import SelectedFont from './components/SelectedFont'
import { ReactComponent as AlignCenterIcon } from './icons/align-center.svg'
import { ReactComponent as AlignLeftIcon } from './icons/align-left.svg'
import { ReactComponent as AlignRightIcon } from './icons/align-right.svg'
import { ReactComponent as TextBoldIcon } from './icons/bold.svg'
import { ReactComponent as TextItalicIcon } from './icons/italic.svg'
import { ReactComponent as JustifyIcon } from './icons/justify.svg'
import { ReactComponent as TextCrossedOutIcon } from './icons/lineThrough.svg'
import { ReactComponent as TextUnderlinedIcon } from './icons/underline.svg'

const colorsApi = ChiliColors.getInstance()
const textApi = ChiliText.getInstance()

const DeleteButtonContainer = styled.div`
  margin-top: auto;
  gap: 50px;
  margin-bottom: 20px;
`

export const TEXT_FORMAT_FIELDS: Array<keyof TextFormat> = [
  'fontSize',
  'lineHeight',
  'trackingRight'
]

const fontSizeOptions = [
  '10',
  '11',
  '12',
  '13',
  '14',
  '16',
  '20',
  '24',
  '32',
  '36',
  '40',
  '48',
  '64',
  '96'
]
const lineHeightOptions = [
  '0.5',
  '0.6',
  '0.7',
  '0.8',
  '0.9',
  '1',
  '1.1',
  '1.2',
  '1.3',
  '1.4',
  '1.5',
  '1.6',
  '1.7',
  '1.8',
  '1.9',
  '2.0'
]

const fontSpreadOptions = [
  '-50',
  '-40',
  '-30',
  '-20',
  '-10',
  '0',
  '10',
  '20',
  '30',
  '40',
  '50'
]

const fieldOptions: { [K in keyof TextFormat]?: string[] } = {
  fontSize: fontSizeOptions,
  lineHeight: lineHeightOptions,
  trackingRight: fontSpreadOptions
  // Add other fields as necessary
}

const TEXT_ALIGNMENT_OPTIONS: Array<{
  value: TextAlign
  Icon: ComponentType
}> = [
  { value: TextAlign.left, Icon: AlignLeftIcon },
  { value: TextAlign.center, Icon: AlignCenterIcon },
  { value: TextAlign.right, Icon: AlignRightIcon },
  { value: TextAlign.justify, Icon: JustifyIcon }
]

const FONT_STYLING_OPTIONS: Array<{
  value: FontStyles
  Icon: ComponentType
}> = [
  { value: FontStyles.bold, Icon: TextBoldIcon },
  { value: FontStyles.italic, Icon: TextItalicIcon }
]

const TEXT_STYLING_OPTIONS: Array<{
  value: TextStyles
  Icon: ComponentType
}> = [
  { value: TextStyles.underline, Icon: TextUnderlinedIcon },
  { value: TextStyles.lineThrough, Icon: TextCrossedOutIcon }
]

type TextProps = {
  frame: Frame
  font: Font | null
  publisherConnector: PublisherInterface
  changeTextFormat: ChangeTextFormat
  textFormat: TextFormat
  secondLevelPanel: SecondLevelPanels | null
  setSecondLevelPanel: Dispatch<
    SetStateAction<SecondLevelPanels | null>
  >
}

const TextControls: React.FC<TextProps> = ({
  frame,
  font,
  publisherConnector,
  changeTextFormat,
  textFormat,
  secondLevelPanel,
  setSecondLevelPanel
}): JSX.Element => {
  const {
    availableStyles,
    setFont,
    changeFontStyle,
    currentFont,
    currentFontFamily,
    fontFamilies,
    loading,
    error
  } = useFont(font, publisherConnector)

  const {
    values: formValues,
    setFieldValue
  }: FormikContextType<any> = useFormikContext()

  const textFlowMap = useRef<Record<string, string>>({})

  useEffect(() => {
    if (formValues?.color !== undefined) {
      setFieldValue('palletteColor', formValues?.color)
    }
  }, [formValues.color])

  const saveTextFlow = (textFlow: string) => {
    textFlowMap.current = {
      ...textFlowMap.current,
      [frame.id]: textFlow
    }
  }

  const getTextFlowFromMap = (frameId: string): string => {
    return textFlowMap.current[frameId]
  }

  const updateTextStyles = () => {
    const field = 'textStyles'
    if (!textFormat) return setFieldValue(field, [])

    const textStyles = TEXT_STYLING_OPTIONS.map(item => item.value)

    const result = textStyles.reduce<TextStyles[]>((acc, style) => {
      if (textFormat[style] === 'true') {
        return [...acc, style]
      }

      return acc
    }, [])

    setFieldValue(field, result)
  }

  const updateFontStyle = () => {
    const field = 'fontStyle'
    if (!currentFont) return setFieldValue(field, null)

    setFieldValue(field, currentFont.style as FontStyles)
  }

  const updateTextColor = async () => {
    const currentColor = await colorsApi.getColorByPath(
      textFormat.color
    )

    let cmyk: CMYK = currentColor?.color as CMYK

    if (currentColor?.type === ColorTypes.rgb) {
      cmyk = (currentColor.color as RGB).toCMYK()
    }
    setFieldValue('color', cmyk)
  }

  const getColorsList = (textFlow: string): string[] => {
    const parser = new DOMParser()

    const xmlDoc = parser.parseFromString(textFlow, 'application/xml')
    const spans = xmlDoc.querySelectorAll('span')

    return Array.from(spans).reduce<string[]>((acc, span) => {
      const color = span.getAttribute('my_color')

      if (!color) {
        return acc
      }

      if (acc.includes(color)) {
        return acc
      }

      return [...acc, color]
    }, [])
  }

  const getRemovedColors = (
    colorsOld: string[],
    colorsNew: string[]
  ): string[] => {
    return colorsOld.filter(color => !colorsNew.includes(color))
  }

  const changeTextColor = async (newColor: CMYK) => {
    if (!newColor) {
      return
    }

    const oldTextFlow =
      getTextFlowFromMap(frame.id) || (await textApi.getTextFlow())

    if (!oldTextFlow) {
      return
    }

    const colorsListOld = getColorsList(oldTextFlow!)

    const color = await colorsApi.addNewColor(newColor)
    const colorPath = colorsApi.generateColorPath(color!.id)

    await changeTextFormat('color', colorPath)
    setFieldValue('color', newColor)

    const newTextFlow = await textApi.getTextFlow()
    if (!newTextFlow) {
      return
    }

    const colorsListNew = getColorsList(newTextFlow!)
    saveTextFlow(newTextFlow!)

    const colorsToRemove = getRemovedColors(
      colorsListOld,
      colorsListNew
    )

    for (const colorId of colorsToRemove) {
      await colorsApi.removeColor(
        colorsApi.generateColorPath(colorId)
      )
    }
  }

  const disabledFontStyles = useMemo(() => {
    if (!availableStyles) return []

    const fontStyles = FONT_STYLING_OPTIONS.map(item => item.value)

    if (availableStyles.length === 1) {
      const availableStyle = availableStyles[0] as FontStyles

      if (fontStyles.includes(availableStyle)) return fontStyles
    }

    return fontStyles.filter(
      style => !availableStyles.includes(style)
    )
  }, [availableStyles])

  useEffect(() => {
    updateTextStyles()
    updateTextColor()
  }, [textFormat])

  useEffect(() => {
    updateFontStyle()
  }, [currentFont])

  useEffect(() => {
    const timeout = setTimeout(() => {
      changeTextColor(formValues.palletteColor)
    }, 300)

    return () => {
      clearTimeout(timeout)
    }
  }, [formValues.palletteColor])

  const deleteSelectedFrame = () => {
    publisherConnector.executeFunction(
      'document.selectedFrame',
      'Delete'
    )
  }

  const handleFrameColorClicked = () => {
    setTimeout(() => {
      setSecondLevelPanel(SecondLevelPanels.color)
    }, 300)
  }
  return (
    <>
      <Flex justifyContent="space-between" marginTop={8}>
        {TEXT_FORMAT_FIELDS.map(field => (
          <FormGroup
            key={field}
            id={field}
            onChange={changeTextFormat}
            width={70}
            options={fieldOptions[field] as string[]} // Pass the appropriate options array based on the field
          />
        ))}
      </Flex>
      <>
        <SelectedFont
          font={currentFont}
          onClick={setSecondLevelPanel}
        />
        {secondLevelPanel === SecondLevelPanels.font && (
          <ChangeFontPanel
            currentFontFamily={currentFontFamily}
            fontFamilies={fontFamilies}
            isLoading={loading}
            isError={!!error}
            setFont={setFont}
            onBackButtonClick={() => setSecondLevelPanel(null)}
          />
        )}
      </>
      <Flex marginTop={32} justifyContent="space-between">
        <FormOptions<TextAlign>
          field="textAlign"
          options={TEXT_ALIGNMENT_OPTIONS}
          onChange={value => {
            changeTextFormat('textAlign', value)
          }}
        />
      </Flex>
      <Flex marginTop={16} justifyContent="space-between">
        <FormOptions<FontStyles | null>
          field="fontStyle"
          options={FONT_STYLING_OPTIONS}
          nullable
          disabled={disabledFontStyles}
          onChange={value => {
            changeFontStyle(value ? value : FontStyles.regular)
          }}
        />
        <FormMultiOptions<TextStyles>
          field="textStyles"
          options={TEXT_STYLING_OPTIONS}
          onChange={(_, action: SelectAction<TextStyles>) => {
            changeTextFormat(
              action.value,
              action.type === SelectActionTypes.select
                ? 'true'
                : 'false'
            )
          }}
        />
      </Flex>

      <Flex marginTop={32}>
        <div>
          <FrameColors
            onClick={handleFrameColorClicked}
            labelId="Panel.field.label.textColor"
          />

          {secondLevelPanel === SecondLevelPanels.color && (
            <ChangeColorPanel
              onBackButtonClick={() => setSecondLevelPanel(null)}
            />
          )}
        </div>
      </Flex>

      <DeleteButtonContainer>
        <Button
          textId="Panel.button.deleteText"
          onPress={deleteSelectedFrame}
          intent="destructive"
          minWidth={235}
        />
      </DeleteButtonContainer>
    </>
  )
}

export default TextControls
