import { GradooTheme } from 'gradoo-theme/lib/types'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { YEARBOOK_PAGES_TABS } from 'data/constants'
import useAuth from 'data/gradoo/hooks/useAuth'
import NewPageModal from 'screens/YearbookPages/components/NewPageModal'
import Head from 'shared/components/Head'
import PageContainer from 'shared/components/PageContainer'
import PageList from 'shared/components/PageList'
import Tools from 'shared/components/PageListTools'
import withRoom from 'shared/hocs/withRoom'
import { useLiveUsers } from 'shared/hooks/useLiveUsers'
import DefaultLayout from 'shared/layout/Default'
import Flex from 'shared/ui/Flex'

import LiveCursorWrapper from './components/LiveCursorWrapper'
import PagePreviewModal from './components/PagePreviewModal'
import { useNewPageSystemMigration } from './components/hooks/useNewPageSystemMigration'
import PagesMigrationModal from './components/PagesMigrationModal'

export const getProjectSyncHash = (ids: string[]) => {
  return ids.join('|')
}

const HEADER_TOOLS_ICON_COLOR = {
  active: (theme: GradooTheme) => theme.color.base.c9,
  default: (theme: GradooTheme) => theme.color.base.c6
}

export const HEADER_TOOLS = [
  {
    to: '/:projectId?view=list',
    icon: 'list-outline',
    colors: HEADER_TOOLS_ICON_COLOR,
    title: 'Page.editor.tools.list'
  },
  {
    to: '/:projectId?view=grid',
    icon: 'grid-filled',
    colors: HEADER_TOOLS_ICON_COLOR,
    title: 'Page.editor.tools.grid'
  }
]

export const YearbookPages = () => {
  const [isNewPageModalOpen, setIsNewPageModalOpen] = useState(false)
  const openNewPageModal = () => setIsNewPageModalOpen(true)
  const closeNewPageModal = () => setIsNewPageModalOpen(false)
  const { authGroupId } = useAuth()
  const { projectId } = useParams()
  const navigate = useNavigate()

  const [isPageMigrationModalOpen, setIsPageMigrationModalOpen] =
    useState(false)

  const {
    isOutdated,
    migrateToNewPageSystemMutation,
    loadingPercent: migrationLoadingPercent,
    isSuccess: isMigrationSuccess,
    isError: isMigrationError,
    isLoading: isMigrationLoading
  } = useNewPageSystemMigration()

  useEffect(() => {
    if (isOutdated) {
      setIsPageMigrationModalOpen(true)
    }
  }, [isOutdated])

  const liveUsers = useLiveUsers()

  return (
    <>
      <DefaultLayout
        liveUsers={liveUsers}
        RightComponent={
          <>
            <Tools
              openModal={openNewPageModal}
              tools={HEADER_TOOLS}
              settingsPath={`/${projectId}/settings`}
            />
            <NewPageModal
              groupId={authGroupId!}
              projectId={projectId!}
              isOpen={isNewPageModalOpen}
              closeModal={closeNewPageModal}
            />
          </>
        }
        onBack={() => navigate('/')}
        tabs={YEARBOOK_PAGES_TABS}
      >
        <LiveCursorWrapper>
          <Head titleId="Page.editor.title" />
          <PageContainer>
            <Flex
              justifyContent="center"
              marginTop={64}
              marginBottom={128}
              paddingLeft={16}
              paddingRight={16}
            >
              <PageList
                projectId={projectId!}
                groupId={authGroupId!}
              />
            </Flex>
          </PageContainer>
        </LiveCursorWrapper>
      </DefaultLayout>
      <PagePreviewModal
        projectId={projectId!}
        groupId={authGroupId!}
      />

      <PagesMigrationModal
        isOpen={isPageMigrationModalOpen}
        closeModal={() => setIsPageMigrationModalOpen(false)}
        loadingPercent={migrationLoadingPercent}
        onStartUpdateClick={migrateToNewPageSystemMutation}
        isLoading={isMigrationLoading}
        isSuccess={isMigrationSuccess}
        isError={isMigrationError}
      />
    </>
  )
}

export default withRoom(YearbookPages)
