import { PublisherInterface } from '@chili-publish/publisher-interface'
import config from 'config'
import { createContext, useEffect, useRef, useState } from 'react'
import {
  useLocation,
  useParams,
  useSearchParams
} from 'react-router-dom'
import styled from 'styled-components'

import useAuth from 'data/gradoo/hooks/useAuth'
import EditorIframe from 'shared/components/EditorIframe'
import PageHeader from 'shared/components/PageHeader'
import Tools from 'shared/components/Tools'
import {
  CursorProperty,
  CursorTypes
} from 'shared/components/Tools/services/types'
import withRoom from 'shared/hocs/withRoom'
import { useChiliApisInit } from 'shared/hooks/useChiliApisInit'
import useDisablePinchZoom from 'shared/hooks/useDisablePinchZoom'
import { useEditorSave } from 'shared/hooks/useEditorSave'
import Button from 'shared/ui/Button_2'
import IcomoonIcon from 'shared/ui/IcomoonIcon'
import Icon from 'shared/ui/Icon'
import EditorLoadingBar from 'shared/ui/ScreenContentLoadingBar'

import Skeleton from './Skeleton'
import PageMigrationModal from './components/PageMigrationModal'
import User from './components/User'
import { useEditorMigration } from './hooks/useEditorMigration'
import ContentDndProvider, {
  EditorSimulation
} from './providers/ContentDndProvider'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
`

const PageContent = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.color.base.c0};
`

const BottomActions = styled.div`
  position: absolute;
  bottom: 24px;
  margin-bottom: 4px;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  gap: 8px;
  min-width: 260px;
`

const ZoomWrapper = styled.div`
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.05);
  padding: 4px 8px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.color.base.c2};
`

const ZoomText = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.base.c6};
  min-width: 30px;
  text-align: center;
`

const MakeFitZoomButton = styled.button`
  color: ${({ theme }) => theme.color.brand_02};
  border: none;
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 2px;
  background: transparent;
  cursor: pointer;
`

const ZoomIconButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  background: transparent;
  cursor: pointer;
  padding: 0px 8px;

  &:hover {
    color: ${({ theme }) => theme.color.brand_02};
  }
`

const MoveIconButton = styled.button<{ isActive: boolean }>`
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  border: 1px solid
    ${({ theme, isActive }) =>
      isActive ? theme.color.brand_02 : theme.color.base.c2};
  border-radius: 100px;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.brand_02 : theme.color.base.c10};
  &:hover {
    color: ${({ theme }) => theme.color.brand_02};
  }
`

const RedoUndoButton = styled.button`
  background: none;
  border: 1px solid ${({ theme }) => theme.color.base.c4};
  cursor: pointer;
  border-radius: 7px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`

type EditorContextValue = {
  isWorkspaceRendered: boolean
}

export const EditorContext = createContext({} as EditorContextValue)

const WORKSPACE_ID = config.chiliWorkspaceId

const ZOOM_STEP = 10
const ZOOM_STEP_PERCENT = ZOOM_STEP / 100
const MAX_ZOOM_STEP_COUNTER = 20
const INITIAL_ZOOM_LABEL = 100
const MIN_ZOOM_STEP_COUNTER = 5

const YearbookEditor = (): JSX.Element => {
  useDisablePinchZoom()
  const { pathname } = useLocation()
  const projectId = decodeURIComponent(pathname.split('/')[1])
  const [searchParams] = useSearchParams()
  const viewSearchParam = searchParams.get('view')

  const { authGroupId, authUserGroup } = useAuth()
  const { pageId: docId } = useParams()

  const [publisherConnector, setPublisherConnector] =
    useState<PublisherInterface | null>(null)
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false)
  const [isWorkspaceRendered, setIsWorkspaceRendered] =
    useState(false)
  const [loadingPercent, setLoadingPercent] = useState(0)
  const [currentZoomLabel, setCurrentZoomLabel] = useState(
    INITIAL_ZOOM_LABEL
  )
  const [currentZoomValue, setCurrentZoomValue] = useState<
    null | number
  >(null)
  const initialZoom = useRef<null | number>(null)
  const [cursorType, setCursorType] = useState<CursorTypes | null>(
    null
  )

  const [isMigrationModalOpen, setIsMigrationModalOpen] =
    useState(false)
  const {
    isOutdated,
    migrate,
    loadingPercent: migrationLoadingPercent,
    isLoading: isMigrationLoading,
    isSuccess: isMigrationSuccess,
    isError: isMigrationError
  } = useEditorMigration({
    publisherConnector,
    isDocumentRendered: isWorkspaceRendered
  })

  useChiliApisInit({
    publisherConnector: publisherConnector!,
    isDocumentLoaded
  })

  const backPath = viewSearchParam
    ? `/${projectId}?view=${viewSearchParam}`
    : `/${projectId}`

  const { handleSave, isLoading: isDocumentSaveLoading } =
    useEditorSave({
      publisherConnector,
      backPath,
      groupId: authGroupId!,
      projectId: projectId!,
      pageId: docId!
    })

  useEffect(() => {
    if (isWorkspaceRendered && publisherConnector) {
      publisherConnector.getProperty('cursor').then(cursor => {
        const cursorData = cursor as CursorProperty
        if (cursor) {
          setCursorType(cursorData?.name || null)
        }
      })

      publisherConnector.getProperty('zoom').then(zoom => {
        initialZoom.current = typeof zoom === 'number' ? zoom : null
        setCurrentZoomValue(initialZoom.current)
      })
    }
  }, [isWorkspaceRendered, publisherConnector, setCursorType])

  const setCanvasMargins = async () => {
    const screenWidth = window.innerWidth
    const screenHeight = window.innerHeight
    const horizontalMargin = (screenWidth - 800) / 2
    let pageCanvasMarginX, pageCanvasMarginY, pageFitMarginY

    // TODO: refactor this
    if (screenWidth >= 1440) {
      pageCanvasMarginX = horizontalMargin + 130
      pageCanvasMarginY = 48
      pageFitMarginY = 53
    } else if (screenWidth >= 1194) {
      pageCanvasMarginX = horizontalMargin + 127
      pageCanvasMarginY = 48
      pageFitMarginY = 55
    } else if (screenWidth >= 1000) {
      pageCanvasMarginX = horizontalMargin + 205
      pageCanvasMarginY = 80
      pageFitMarginY = 160
    } else {
      pageCanvasMarginX = horizontalMargin + 350
      pageCanvasMarginY = 80
      pageFitMarginY = 160
    }

    if (screenHeight >= 950) {
      pageFitMarginY = 90
    }
    if (screenHeight >= 1030) {
      pageFitMarginY = 140
    }
    await publisherConnector?.setProperty(
      'document.viewPreferences',
      'pageCanvasMarginX',
      pageCanvasMarginX
    )
    await publisherConnector?.setProperty(
      'document.viewPreferences',
      'pageCanvasMarginY',
      pageCanvasMarginY
    )
    await publisherConnector?.setProperty(
      'document.viewPreferences',
      'pageFitMarginY',
      pageFitMarginY
    )
  }

  useEffect(() => {
    if (isOutdated) {
      setIsMigrationModalOpen(true)
    }
  }, [isOutdated])

  const onZoomIn = async () => {
    if (!currentZoomValue || !initialZoom.current) {
      return
    }

    const maxZoomValue =
      initialZoom.current +
      initialZoom.current * ZOOM_STEP_PERCENT * MAX_ZOOM_STEP_COUNTER
    const newZoomValue =
      currentZoomValue + initialZoom.current * ZOOM_STEP_PERCENT
    const finalZoomValue = Math.min(newZoomValue, maxZoomValue)

    const maxValueLabel =
      INITIAL_ZOOM_LABEL + MAX_ZOOM_STEP_COUNTER * ZOOM_STEP
    const newZoomLabel = currentZoomLabel + ZOOM_STEP
    const finalZoomLabel = Math.min(newZoomLabel, maxValueLabel)

    setCurrentZoomLabel(finalZoomLabel)
    setCurrentZoomValue(finalZoomValue)

    await publisherConnector?.setProperty(
      'document',
      'zoom',
      finalZoomValue
    )
  }

  const onZoomOut = async () => {
    if (!currentZoomValue || !initialZoom.current) {
      return
    }

    const minZoomValue =
      initialZoom.current -
      initialZoom.current * ZOOM_STEP_PERCENT * MIN_ZOOM_STEP_COUNTER

    const newZoomValue =
      currentZoomValue - initialZoom.current * ZOOM_STEP_PERCENT
    const finalZoomValue = Math.max(newZoomValue, minZoomValue)

    const minValueLabel =
      INITIAL_ZOOM_LABEL - MIN_ZOOM_STEP_COUNTER * ZOOM_STEP
    const newZoomLabel = currentZoomLabel - ZOOM_STEP
    const finalZoomLabel = Math.max(newZoomLabel, minValueLabel)

    setCurrentZoomLabel(finalZoomLabel)
    setCurrentZoomValue(finalZoomValue)
    await publisherConnector?.setProperty(
      'document',
      'zoom',
      finalZoomValue
    )
  }

  const onZoomFit = async () => {
    if (!currentZoomValue || !initialZoom.current) {
      return
    }

    setCurrentZoomLabel(100)
    setCurrentZoomValue(initialZoom.current)
    await publisherConnector?.executeFunction(
      'document.editor',
      'Fit',
      'page'
    )
  }

  const onMoveToggle = async () => {
    if (cursorType === 'pointer') {
      await publisherConnector?.executeFunction(
        'document',
        'SetCursor',
        'hand'
      )
      setCursorType('hand')
    } else {
      await publisherConnector?.executeFunction(
        'document',
        'SetCursor',
        'pointer'
      )
      setCursorType('pointer')
    }
  }

  const onRedo = async () => {
    await publisherConnector?.executeFunction(
      'document.undoManager',
      'Redo',
      1
    )
  }

  const onUndo = async () => {
    await publisherConnector?.executeFunction(
      'document.undoManager',
      'Undo',
      1
    )
  }

  return (
    <EditorContext.Provider value={{ isWorkspaceRendered }}>
      <Container>
        <ContentDndProvider>
          <PageHeader
            titleId={'Editor.title'}
            withBackButton
            onBackButtonClick={handleSave}
            renderBackButton={handleGoBack => (
              <Button
                loading={isDocumentSaveLoading}
                textId="Editor.leaveButton"
                intent="primary-brand-02"
                onPress={handleGoBack}
                icon="left"
                iconName="save"
                minWidth={216}
              />
            )}
            tools={
              <Tools
                publisherConnector={publisherConnector!}
                isDocumentLoaded={isDocumentLoaded}
              />
            }
            user={
              authUserGroup ? (
                <User authUserGroup={authUserGroup} />
              ) : undefined
            }
            rightTools={
              <>
                <RedoUndoButton onClick={onRedo}>
                  <Icon name="redo" size={16} />
                </RedoUndoButton>
                <RedoUndoButton onClick={onUndo}>
                  <Icon name="undo" size={16} />
                </RedoUndoButton>
              </>
            }
          />
          <PageContent>
            <Skeleton isShown={!isWorkspaceRendered} />

            <EditorLoadingBar loadingPercent={loadingPercent} />

            <EditorSimulation />

            <EditorIframe
              pageId={docId!}
              workspaceId={WORKSPACE_ID}
              onLoadingUpdate={setLoadingPercent}
              onBuild={setPublisherConnector}
              onDocumentLoad={() => {
                setIsDocumentLoaded(true)
                setCanvasMargins()
              }}
              onWorkspaceRender={() => setIsWorkspaceRendered(true)}
            />
          </PageContent>
        </ContentDndProvider>
        <BottomActions>
          <MoveIconButton
            isActive={cursorType === 'hand'}
            onClick={onMoveToggle}
          >
            <IcomoonIcon size={24} icon="move" />
          </MoveIconButton>
          {initialZoom.current && (
            <ZoomWrapper>
              <ZoomIconButton onClick={onZoomOut}>
                <IcomoonIcon size={24} icon="zoom-out" />
              </ZoomIconButton>

              <ZoomText>{currentZoomLabel}%</ZoomText>

              <ZoomIconButton onClick={onZoomIn}>
                <IcomoonIcon size={24} icon="zoom-in" />
              </ZoomIconButton>
              {initialZoom.current !== currentZoomValue && (
                <MakeFitZoomButton onClick={onZoomFit}>
                  Make fit
                </MakeFitZoomButton>
              )}
            </ZoomWrapper>
          )}
        </BottomActions>
      </Container>

      <PageMigrationModal
        isOpen={isMigrationModalOpen}
        closeModal={() => setIsMigrationModalOpen(false)}
        isError={isMigrationError}
        isSuccess={isMigrationSuccess}
        isLoading={isMigrationLoading}
        onStartUpdateClick={migrate}
        loadingPercent={migrationLoadingPercent}
      />
    </EditorContext.Provider>
  )
}

export default withRoom(YearbookEditor)
