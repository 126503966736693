import { useEffect } from "react";

const PREVIEW_URLS_CACHE_KEY = 'pagePreviews'

export const usePreviewsCache = () => {
  const previewsMap = localStorage.getItem(PREVIEW_URLS_CACHE_KEY);

  const cachePreview = async (key: string, base64Data: string) => {
    const newMap = {
      ...(previewsMap ? JSON.parse(previewsMap) : {}),
      [key]: Date.now(),
    }

    localStorage.setItem(PREVIEW_URLS_CACHE_KEY, JSON.stringify(newMap))

    const cache = await caches.open(PREVIEW_URLS_CACHE_KEY)

    const response = new Response(base64Data, {
      headers: { 'Content-Type': 'image/png' }
    })
    await cache.put(key, response)
  }

  const getPreviewFromCache = async (key: string, timestamp: number | null) => {
    // if there is updatedAt value in db
    if (timestamp !== null) {
      if (!previewsMap) {
        return null;
      }
  
      const mapTimestamp = JSON.parse(previewsMap)[key]
  
      if (mapTimestamp && mapTimestamp < timestamp) {
        return null;
      }
    }

    const cache = await caches.open(PREVIEW_URLS_CACHE_KEY)
    const response = await cache.match(key)
    return response?.text()
  }

  return { cachePreview, getPreviewFromCache }
}
