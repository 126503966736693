import { useMutation, useQuery } from '@apollo/client'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import { useLocation, useParams } from 'react-router-dom'

import useAuth from 'data/gradoo/hooks/useAuth'
import {
  REMOVE_UPLOADS_PAGE_BACKGROUND,
  UPLOAD_PAGE_BACKGROUND
} from 'data/layoutcreator/mutations/themes'
import { GET_PAGE_BACKGROUND_UPLOADS } from 'data/layoutcreator/queries/themes'

export const useBackgroundUploads = ({
  onSelectedBackgroundChange,
  hideBackground
}: {
  onSelectedBackgroundChange: (
    data: {
      id: string
      type?: 'dap' | 'chili'
    } | null
  ) => void
  hideBackground: boolean
}) => {
  const { formatMessage } = useIntl()
  const { projectId, groupId: paramsGroupId } = useParams()
  const { authGroupId } = useAuth()

  const groupId = paramsGroupId || authGroupId

  const [selectedImageId, setSelectedImageId] = useState<
    string | null
  >(null)

  const { data, loading: isImagesLoading } = useQuery(
    GET_PAGE_BACKGROUND_UPLOADS,
    {
      skip: !groupId,
      variables: { projectId: projectId!, groupId: groupId! }
    }
  )
  const images = data?.getPageBackgroundUploads

  const [uploadPageBackground] = useMutation(UPLOAD_PAGE_BACKGROUND)
  const [removeImage] = useMutation(REMOVE_UPLOADS_PAGE_BACKGROUND)

  const imageInputRef = useRef<HTMLInputElement>(null)

  const handleUploadClick = () => {
    imageInputRef.current!.click()
  }

  const handleFileUpload = async (
    file: File
  ): Promise<string | null> => {
    const toastId = toast.loading(
      formatMessage({ id: 'Toasts.pageBackgrounds.uploads.upload' })
    )

    const response = await uploadPageBackground({
      variables: { groupId: groupId!, projectId: projectId!, file },
      refetchQueries: [
        {
          query: GET_PAGE_BACKGROUND_UPLOADS,
          variables: { projectId, groupId }
        }
      ]
    })

    toast.remove(toastId)

    if (response.data) {
      const newImageId = response.data.uploadPageBackground.id

      return newImageId
    }

    return null
  }

  const handleImageRemove = ({
    backgroundId,
    type
  }: {
    backgroundId: string
    type: string
  }) => {
    toast.promise(
      removeImage({
        variables: {
          groupId: groupId!,
          projectId: projectId!,
          backgroundId,
          type
        },
        refetchQueries: [
          {
            query: GET_PAGE_BACKGROUND_UPLOADS,
            variables: { projectId: projectId!, groupId: groupId! }
          }
        ]
      }),
      {
        loading: formatMessage({
          id: 'Toasts.pageBackgrounds.uploads.remove'
        }),
        success: formatMessage({
          id: 'Toasts.pageBackgrounds.uploads.remove.success'
        }),
        error: formatMessage({
          id: 'Toasts.pageBackgrounds.uploads.remove.error'
        })
      }
    )
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      handleFileUpload(acceptedFiles[0])
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true
  })

  useEffect(() => {
    onSelectedBackgroundChange(
      selectedImageId
        ? {
            id: selectedImageId,
            type: images?.find(image => image.id === selectedImageId)
              ?.type as 'dap' | 'chili'
          }
        : null
    )
  }, [selectedImageId])

  useEffect(() => {
    if (hideBackground) {
      setSelectedImageId(null)
    }
  }, [hideBackground])

  return {
    images,
    isImagesLoading,
    handleUploadClick,
    handleImageRemove,
    handleFileUpload,
    getRootProps,
    getInputProps,
    isDragActive,
    imageInputRef,
    selectedImageId,
    setSelectedImageId
  }
}
