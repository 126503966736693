import chroma from 'chroma-js'
import styled, { css } from 'styled-components'

import { ViewTypes } from '../../../../../../enum'

const Overlay = styled.div<{ view: string }>`
  display: flex;
  column-gap: 8px;
  opacity: ${({ view }) => (view === ViewTypes.list ? 1 : 0)};
  z-index: 2;

  ${({ view }) =>
    view === ViewTypes.grid &&
    css`
      position: absolute;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;
      row-gap: 8px;
      width: 100%;
      height: 100%;
      background-color: ${({ theme }) =>
        chroma(theme.color.base.c9).alpha(0.9).css()};
    `}

  ${({ view }) =>
    view === ViewTypes.list &&
    css`
      & img {
        width: 32px;
        height: 32px;
      }
    `}
`

export default Overlay
