import { useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import ChiliShapes from 'shared/components/Tools/services/ChiliShapes'
import { ShapeTypes } from 'shared/components/Tools/types'
import Flex from 'shared/ui/Flex'

import { SHAPE_TYPES } from '../../ShapeControls/consts'

type ButtonProps = {
  isSelected: boolean
}

const SectionTitle = styled.h3`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
`

const Container = styled(Flex).attrs({
  justifyContent: 'space-between',
  direction: 'column'
})`
  margin-top: 30px;
  margin-bottom: 30px;
  gap: 12px;
`

const Button = styled.button<ButtonProps>`
  background: ${({ isSelected, theme }) =>
    isSelected ? theme.color.base.c1 : theme.color.base.c1};
  padding-top: 4px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.color.brand_02 : theme.color.base.c3};
  outline: none;
  background-color: transparent;
  svg {
    width: 30px;
    height: 30px;
    fill: ${({ theme, isSelected }) =>
      isSelected ? theme.color.brand_02 : theme.color.base.c3};
  }
`

const shapeApi = ChiliShapes.getInstance()

export const ShapeSelector = () => {
  const { formatMessage } = useIntl()
  const [selectedShape, setSelectedShape] =
    useState<ShapeTypes | null>(null)

  const onSelectShape = async (shape: ShapeTypes) => {
    setSelectedShape(shape)
    await shapeApi.applyShapeToSelectedFrame(shape)
  }

  return (
    <Container>
      <SectionTitle>
        {formatMessage({ id: 'Panel.image.shape.title' })}
      </SectionTitle>
      <Flex justifyContent="space-between">
        {Object.entries(SHAPE_TYPES).map(([type, { Image }]) => {
          const shapeType = type as ShapeTypes

          return (
            <Button
              isSelected={selectedShape === shapeType}
              key={type}
              onClick={() => {
                onSelectShape(shapeType)
              }}
            >
              <Image />
            </Button>
          )
        })}
      </Flex>
    </Container>
  )
}
