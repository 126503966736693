import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import useAuth from 'data/gradoo/hooks/useAuth'
import {
  CHECK_IS_PAGE_COUNT_SYSTEM_OUTDATED,
  MIGRATE_TO_NEW_PAGE_SYSTEM
} from 'data/layoutcreator/mutations/projects'

export const useNewPageSystemMigration = () => {
  const [isOutdated, setIsOutdated] = useState<boolean | null>(null)
  const [loadingPercent, setLoadingPercent] = useState<number>(0)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)

  const { authGroupId: groupId } = useAuth()
  const { projectId } = useParams()

  const [migrateToNewPageSystem] = useMutation(
    MIGRATE_TO_NEW_PAGE_SYSTEM
  )
  const [checkIsPageCountSystemOutdated] = useMutation(
    CHECK_IS_PAGE_COUNT_SYSTEM_OUTDATED
  )

  const migrateToNewPageSystemMutation = async () => {
    setLoadingPercent(20)
    const { data, errors } = await migrateToNewPageSystem({
      variables: {
        groupId: groupId!,
        projectId: projectId!
      }
    })

    setLoadingPercent(80)

    if (await checkIsPageCountSystemOutdatedMutation() === true) {
      setLoadingPercent(100)
      setIsSuccess(false)
      setIsError(true)
      return
    }

    setLoadingPercent(100)
    setIsSuccess(!!data)
    setIsError(!!errors)
  }

  const checkIsPageCountSystemOutdatedMutation = async () => {
    const { data } = await checkIsPageCountSystemOutdated({
      variables: {
        groupId: groupId!,
        projectId: projectId!
      }
    })
    setIsOutdated(data?.checkIsPageCountSystemOutdated ?? false)
    return data?.checkIsPageCountSystemOutdated
  }

  useEffect(() => {
    checkIsPageCountSystemOutdatedMutation()
  }, [])

  return {
    isOutdated,
    migrateToNewPageSystemMutation,
    loadingPercent,
    isSuccess,
    isError,
    isLoading: loadingPercent > 0 && loadingPercent < 100
  }
}
